import styled from 'styled-components'

interface ContainerProps {
  disable?: boolean
  disableOpacity?: string
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  opacity: ${props =>
    props.disable ? 0.5 : props.disableOpacity ? props.disableOpacity : 1};
`

interface LabelProps {
  label?: any
}

export const Label = styled.span<LabelProps>`
  font-size: 13px;
  margin-bottom: 8px;
  color: var(--white);
  display: ${props => (props.label ? 'flex' : 'none')};
  gap: 3px;

  > span {
    color: var(--red-alert);
  }
`

interface InputContainerProps {
  isLight?: boolean
  light?: boolean
  isTextArea?: boolean
  height?: string
}

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  background: transparent;
  height: ${props =>
    props.isTextArea ? (props.height ? props.height : '160px') : '40px'};
  border-radius: 5px;
  position: relative;
  justify-content: center;
  align-items: center;
  outline: 1px solid var(--inputs);

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  input[data-autocompleted] {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
`

export const Prefix = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 60px;
  font-size: 13px;
  font-weight: 700;
  background-color: var(--primary);
  border-radius: 4px 0 0 4px;
`

interface InputProps {
  icon?: boolean
  isLight?: boolean
  isPrefix?: string
  borderError?: boolean
}

export const InputElement = styled.input<InputProps>`
  padding: ${props => (props.icon ? '0 20px 0 36px' : '0 20px')};
  padding: ${props => (props.isPrefix ? '0 20px 0 10px' : '')};
  width: 100%;
  height: 100%;
  color: ${props => (props.isLight ? 'var(--background)' : ' var(--white)')};
  background: transparent;
  border: ${props =>
    props.borderError ? '1px solid var(--red-alert)' : '1px solid transparent'};
  border-radius: 4px;
  :focus,
  :active {
    outline: none;
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(72%) sepia(28%) saturate(5730%) hue-rotate(169deg)
      brightness(92%) contrast(91%);
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--placeholder);
    opacity: 1; /* Firefox */
    font-weight: 400;
    font-size: 12px;
  }
`

export const InputTextAreaElement = styled.textarea`
  padding: 8px 16px;
  width: 100%;
  height: 100%;
  max-height: 160px;
  color: var(--white);
  background: transparent;
  resize: none;
  font-size: 13px;

  :focus,
  :active {
    outline: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--placeholder);
    opacity: 1; /* Firefox */
    font-weight: 300;
    font-size: 12px;
  }
`

interface StatusProps {
  status: number
  light?: boolean
}

export const Status = styled.div<StatusProps>`
  display: ${props => (props.status === 0 ? 'none' : 'flex')};
  height: 100%;
  padding: 10px;
  padding-left: 0;
  display: flex;
  align-items: center;
  background: ${props => (props.light ? 'var(--60)' : 'var(--70)')};
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: -10px 0px 10px 5px
    ${props => (props.light ? 'var(--60)' : 'var(--70)')};

  :after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: ${props =>
      props.status === 1 ? 'var(--nit)' : 'var(--red-alert)'};
  }
`

export const Error = styled.section`
  position: absolute;
  margin-top: 0;
  bottom: -16px;
  text-align: right;
  right: 0;
  color: var(--red-alert);
  font-size: 11px;
`

interface HideProps {
  light?: boolean
  withoutIcon?: boolean
}

export const HidePassword = styled.button<HideProps>`
  display: ${props => (props.withoutIcon ? 'none' : 'flex')};
  height: 100%;
  padding: 10px;
  padding-left: 0;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;

  > svg {
    color: rgba(242, 183, 5, 0.25);
  }
`

interface IconContainerProps {
  isSearch?: boolean
}

export const IconContainer = styled.div<IconContainerProps>`
  position: absolute;
  bottom: 10px;
  left: ${props => (props.isSearch ? '' : '0')};
  right: ${props => (props.isSearch ? '0' : '')};
  height: 18px;

  > svg {
    margin-left: ${props => (props.isSearch ? '0' : '10px')};
    margin-right: ${props => (props.isSearch ? '10px' : '')};
    fill: var(--primary);
    font-size: 18px;
  }
`

interface TooltipProps {
  smallTooltip?: boolean
}

export const ToolTipContent = styled.div<TooltipProps>`
  display: flex;
  align-items: center;
  margin-top: ${props => (props.smallTooltip ? '2px' : '')};

  > nav {
    display: flex;
    align-items: center;
    padding: ${props => (props.smallTooltip ? '1px' : '2px')};
    border-radius: 100px;
    background-color: var(--primary);
    height: fit-content;

    > svg {
      color: white;
      font-size: 10px;
    }
  }
`
