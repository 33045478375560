import styled from 'styled-components'

export const TooltipText = styled.div`
  color: #fff;
  text-align: center;
  line-height: 44px;
  border-radius: 3px;
  cursor: pointer;
  z-index: -5000;
`

export const TooltipBox = styled.div`
  z-index: 1000;
  position: absolute;
  top: calc(100% + 10px);
  left: -110px;
  min-width: 150px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  padding: 5px 5px;
  border-radius: 4px;
  transition: color 0.1s, background-color 0.1s, width 0.1s,
    padding 0.2s ease-in-out;
  &:before {
    content: '';
    width: 0;
    height: 0;
    left: 107px;
    top: -5px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(135deg);
    transition: border 0.1s ease-in-out;
    z-index: 1000;
  }
`

export const TooltipCard = styled.div`
  position: relative;
  & ${TooltipText}:hover + ${TooltipBox} {
    z-index: 5000;
    visibility: visible;
    color: #fff;
    background-color: var(--white);
    &:before {
      z-index: 1000;
      border-color: transparent transparent var(--white) var(--white);
    }
  }
`
