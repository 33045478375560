import styled from 'styled-components'

interface ColumnProps {
  isStage2?: boolean
}

export const ColumnStage1 = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  align-items: center;

  > h3 {
    font-weight: 500;
    font-size: 24px;
    color: var(--white);
    padding-top: 8px;

    > b {
      color: var(--primary);
      font-weight: 700;
      padding-left: 2px;
      line-height: 1;

      @media (max-width: 409px) {
        line-height: ${props => (props.isStage2 ? '26px' : '24px')};
      }
    }

    @media (max-width: 1024px) {
      font-size: 20px;
      padding-top: 0;
    }

    @media (max-width: 600px) {
      text-align: center;
    }
  }

  > h4 {
    font-weight: 500;
    font-size: 16px;
    color: var(--white);
    line-height: 19.5px;

    > b {
      color: var(--primary);
      font-weight: 700;

      @media (max-width: 409px) {
        line-height: 20px;
      }
    }

    @media (max-width: 1024px) {
      font-size: 14px;
    }

    @media (max-width: 729px) {
      font-size: 12px;
    }

    @media (max-width: 681px) {
      font-size: 14px;
    }
    @media (max-width: 409px) {
      font-size: 10px;
    }

    @media (max-width: 600px) {
      text-align: center;
    }
  }

  > p {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: var(--white);

    @media (max-width: 1024px) {
      text-align: center;
    }

    @media (max-width: 729px) {
      font-size: 12px;
    }
  }

  @media (max-width: 729px) {
    gap: 8px !important;
  }
`

export const ColumnStage3 = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  align-items: center;

  > div > section {
    bottom: -16px;
  }

  > h3 {
    font-weight: 500;
    font-size: 24px;
    color: var(--white);
    padding-top: 8px;

    > b {
      color: var(--primary);
      font-weight: 700;
      padding-left: 2px;
      line-height: 1;

      @media (max-width: 409px) {
        line-height: ${props => (props.isStage2 ? '26px' : '24px')};
      }
    }

    @media (max-width: 1024px) {
      font-size: 20px;
      padding-top: 0;
    }

    @media (max-width: 600px) {
      text-align: center;
    }
  }

  > h4 {
    font-weight: 500;
    font-size: 16px;
    color: var(--white);
    line-height: 19.5px;

    > b {
      color: var(--primary);
      font-weight: 700;

      @media (max-width: 409px) {
        line-height: 20px;
      }
    }

    @media (max-width: 1024px) {
      font-size: 14px;
    }

    @media (max-width: 729px) {
      font-size: 12px;
    }

    @media (max-width: 681px) {
      font-size: 14px;
    }
    @media (max-width: 409px) {
      font-size: 10px;
    }

    @media (max-width: 600px) {
      text-align: center;
    }
  }

  > p {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: var(--white);

    @media (max-width: 1024px) {
      text-align: center;
    }

    @media (max-width: 729px) {
      font-size: 12px;
    }
  }

  @media (max-width: 729px) {
    gap: 8px !important;
  }
`

export const ButtonContainer = styled.section`
  display: flex;
  flex: 1;
  align-items: flex-end;
  width: 100%;
  justify-content: center;

  > button {
    max-width: 100% !important;
    width: 100%;
    height: 40px;
  }
`
