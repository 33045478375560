import styled from 'styled-components'

interface ContainerProps {
  disable?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  position: relative;
  opacity: ${props => (props.disable ? 0.35 : 1)};
  pointer-events: ${props => (props.disable ? 'none' : 'default')};
  cursor: ${props => (props.disable ? 'default' : '')};

  > span {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;

    color: var(--white);
  }
`

export const Subtitle = styled.div`
  font-weight: 300;
  color: var(--text);
  font-size: 12px;
  max-width: 200px;
  text-align: center;
`

interface ErrorProps {
  seccondary?: boolean
}

export const Error = styled.section<ErrorProps>`
  /* position: absolute;
  margin-top: 0;
  bottom: ${props => (props.seccondary ? '0' : '-16px')};
  top: ${props => (props.seccondary ? '1px' : '0')}; */
  text-align: right;
  right: 0;
  color: var(--red-alert);
  font-size: ${props => (props.seccondary ? '10px' : '11px')};
  height: fit-content;
`

export const FilePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: 0.4s ease;
  height: 100%;
  overflow-y: hidden;

  > section {
    display: flex;
    width: 100%;
    max-height: 100%;
    height: 100%;
    overflow-y: scroll;
    transition: 0.4s ease;
    position: relative;
    ::-webkit-scrollbar {
      display: none;
    }

    > section {
      display: flex;
      flex-direction: column;
      gap: 22px;
      width: 100%;
      transition: 0.4s ease;
      justify-content: center;
      align-items: center;

      > div {
        height: 100%;
        width: 100%;
        border-radius: 8px;
        box-shadow: var(--shadow);
        position: relative;
        > img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          border-radius: 8px;
          z-index: 1;
          position: relative;
        }
        > video {
          border-radius: 8px;
          height: 100%;
          width: 100%;
          z-index: 1;
          position: relative;
        }
        @media (max-width: 1124px) {
          width: 100%;
        }
      }
    }
  }
  > span {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
    > button {
      padding: 10px 24px;
      background: var(--secondary-degrade);
      border-radius: 18px;
      color: white;
      font-size: 12px;
      font-weight: 500;
      width: fit-content;
      cursor: pointer;
    }
  }
`

export const DeleteButton = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 24px;
  height: 24px;
  background: var(--inputs);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  box-shadow: var(--shadow);

  > svg {
    color: var(--text);
  }
  z-index: 2;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background: var(--inputs);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LastImage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  > button {
    position: absolute;
    bottom: 8px;
    right: 8px;
    box-shadow: var(--shadow);
  }

  > video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`
export const OverlayButton = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  > button:nth-child(2) {
    position: absolute;
    left: 14px;
    z-index: 2;
    bottom: 8px;
    /* background-color: var(--white); */
  }

  > button:nth-child(1) {
    position: absolute;
    right: 14px;
    z-index: 2;
    bottom: 24px;
  }
`

interface LoadImageProps {
  loaded?: boolean
}

export const LoadImage = styled.section<LoadImageProps>`
  position: absolute;
  inset: 0;
  background: var(--primary);
  transition: 0.5s ease;
  opacity: ${props => (props.loaded ? 0 : 1)};
  border-radius: 8px;
  pointer-events: none;
`
