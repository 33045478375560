import styled from 'styled-components'

interface CardProps {
  height?: number
  isStage3?: number
}

export const Card = styled.div<CardProps>`
  display: flex;
  width: 100%;
  max-width: ${props => (props.isStage3 === 2 ? '530px' : '980px')};
  height: 100%;
  max-height: ${props =>
    props.height === 0 ? '340' : props.height === 1 ? '522' : '241'}px;
  box-shadow: var(--shadow);
  border-radius: 10px;

  > div:last-child {
    padding: 24px 45px;
    gap: 24px;

    @media (max-width: 1024px) {
      padding: 26px 24px;
    }

    @media (max-width: 729px) {
      justify-content: center;
      border-radius: ${props => (props.height === 2 ? '10px' : '')};
    }
  }

  > div:first-child {
    @media (max-width: 681px) {
      height: 229px;
    }
  }

  @media (max-width: 722px) {
    flex-direction: column;
    max-height: ${props =>
      props.height === 0 ? '474.5' : props.height === 1 ? '537' : '220'}px;
  }
`

export const BackButton = styled.button`
  position: absolute;
  top: 8px;
  left: 26px;
  color: var(--primary);
  display: flex;
  gap: 4px;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    transition: 0.3s ease;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`

interface LeftContentProps {
  bg?: string
  isStage3?: number
}

export const LeftContent = styled.div<LeftContentProps>`
  width: 100%;
  height: 100%;
  background-image: ${props => `url("${props.bg}")`};
  background-size: cover;
  display: ${props => (props.isStage3 === 2 ? 'none' : 'flex')};
  background-repeat: no-repeat;
  border-radius: 10px 0 0 10px;
  padding: 24px 26px;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  > h4 {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    max-width: 256px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: white;
    height: fit-content;

    > h3 {
      color: var(--primary);
      display: flex;
      gap: 4px;

      > p {
        color: white;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;

        @media (max-width: 722px) {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }

    @media (max-width: 722px) {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }

  > img {
    height: 20px;
    object-fit: cover;
    width: 70px;

    @media (max-width: 722px) {
      display: none;
    }
  }

  @media (max-width: 790px) {
    width: 80%;
    background-position: center;
  }

  @media (max-width: 722px) {
    width: 100%;
    background-size: cover;
    border-radius: 10px 10px 0 0;
  }
`
