/* eslint-disable indent */
/* eslint-disable array-callback-return */
import { Tooltip } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { MdOutlineChevronRight } from 'react-icons/md'
import { ReactComponent as Course } from '../../../../assets/icons/ClientsMembersArea/course.svg'
import * as S from './styles'
import { iSection } from '../../../../lib/models'
import TooltipEllipses from '../../../../components/TooltipEllipses'
import EmptySection from '../../../../components/EmptySection'
import { SiGoogleclassroom } from 'react-icons/si'
import { TbCertificate } from 'react-icons/tb'
import { ReactComponent as Check } from '../../../../assets/icons/ClientsMembersArea/check.svg'
import { useCourse } from '../../../../contexts/CourseContext'

interface LeftMenuProps {
  responsiveOpened?: boolean
  isCourseResponsive?: boolean
  contentRef?: React.MutableRefObject<null | Element>
  setCertificatePage?: React.Dispatch<React.SetStateAction<boolean>>
}

const LeftMenuMembersArea: React.FC<LeftMenuProps> = ({
  responsiveOpened,
  isCourseResponsive,
  setCertificatePage,
  contentRef
}) => {
  const [opened, setOpened] = useState<iSection>()
  const [activeSubItem, setActiveSubitem] = useState<any>()
  const nameRef = useRef<HTMLDivElement>(null)
  const {
    classData,
    setCurrentMaterialIndex,
    setCurrentSectionIndex,
    courseProgress
  } = useCourse()

  // no need to fix in this chunk
  const getDuration = (videoId: string) => {
    let time = ''

    const calcMinute = (time: number) => {
      const minutes = (time / 60).toFixed()
      const rest = time % 60

      return `${minutes}:${rest}`
    }

    document.onload = () => {
      const video: any = document.getElementById(videoId)
      video.onloadedmetadata = function () {
        time =
          Number(this.duration.toFixed()) <= 59
            ? `${calcMinute(Number(this.duration.toFixed()))} segundos`
            : `${calcMinute(Number(this.duration.toFixed()))} minutos`
      }
    }

    return time
  }

  useEffect(() => {
    if (
      classData?.sections !== undefined &&
      classData?.sections.length > 0 &&
      !opened
    ) {
      let lastActiveSubitem = classData?.sections[0].materials[0]

      const lastViewed =
        classData.sections.find(
          (section, j) =>
            section.materials.find((material, i) => {
              setCurrentSectionIndex(j)
              setCurrentMaterialIndex(i)
              if ((material.progress?.at(0)?.value || 0) < 90) {
                lastActiveSubitem = material
                return true
              }
            }) !== undefined
        ) || classData.sections[0]

      setActiveSubitem(lastActiveSubitem)
      setOpened(lastViewed)
    }
  }, [JSON.stringify(classData?.sections)])

  return (
    <S.Container opened={responsiveOpened}>
      <S.Content>
        <S.Section>
          {classData?.sections?.map((item, j) => {
            return (
              <S.ItemContainer
                key={j}
                onClick={(e: any) => {
                  e.stopPropagation()
                  if (setCertificatePage) {
                    setCertificatePage(false)
                  }

                  if (opened !== item) setOpened(item)
                }}
              >
                <S.Item
                  opened={opened?.id === item.id}
                  active={opened?.id === item.id}
                  onClick={() => {
                    if (item.materials) {
                      if (opened === item) setOpened(undefined)
                      else setOpened(item)
                    }
                  }}
                  primary_color={classData?.course.primary_color}
                >
                  <section>
                    <Course />
                    <div>
                      {isCourseResponsive ? (
                        <h3>{item.name}</h3>
                      ) : (
                        <section>
                          <section>
                            <section ref={nameRef}>
                              <TooltipEllipses
                                containerRef={nameRef}
                                label={item.name}
                              />
                            </section>
                          </section>
                        </section>
                      )}
                      <p>
                        {item.materials.length} aula
                        {item.materials.length !== 1 ? 's' : ''}
                      </p>
                    </div>
                  </section>

                  {item.materials && item.materials?.length > 0 && (
                    <MdOutlineChevronRight size={16} />
                  )}
                </S.Item>
                {item.materials && (
                  <S.SubItemContainer opened={opened?.id === item.id}>
                    {item.materials.map((item2, k) => {
                      return (
                        <S.SubItem
                          key={k}
                          active={activeSubItem?.id === item2?.id}
                          onClick={() => {
                            if (setCertificatePage) {
                              setCertificatePage(false)
                            }
                            setActiveSubitem(item2)

                            if (setCurrentSectionIndex !== undefined) {
                              setCurrentSectionIndex(j)
                            }
                            if (setCurrentMaterialIndex !== undefined) {
                              setCurrentMaterialIndex(k)
                            }

                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: 'smooth'
                            })
                            if (contentRef?.current) {
                              contentRef.current?.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                              })
                            }
                          }}
                          primary_color={classData?.course.primary_color}
                        >
                          <div>
                            <video
                              controls
                              controlsList="nodownload"
                              src={item2.video}
                              id={`video-${j}-${k}`}
                            />
                            <div>
                              <Tooltip
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                title={item2.name!}
                                placement="top-start"
                                arrow
                              >
                                <span>{item2.name}</span>
                              </Tooltip>
                              <p>{getDuration(`video-${j}-${k}`)}</p>
                            </div>
                          </div>
                          {item2?.progress !== undefined &&
                            item2?.progress[0]?.value > 0 &&
                            item2?.progress[0]?.value === 100 && (
                              <Check className="check" />
                            )}
                        </S.SubItem>
                      )
                    })}
                  </S.SubItemContainer>
                )}
              </S.ItemContainer>
            )
          })}
          <S.Divisor />
        </S.Section>
        {classData?.sections !== undefined &&
          classData?.sections.length === 0 && (
            <section>
              <EmptySection
                primary_color={classData?.course.primary_color}
                icon={
                  <SiGoogleclassroom
                    size={24}
                    color={classData?.course.primary_color || 'var(--primary)'}
                  />
                }
                style={{
                  background: 'var(--naval)',
                  boxShadow: 'none',
                  padding: '24px 12px'
                }}
                text="Ops! Parece que não há aulas disponíveis para este curso no momento."
                title="Sem aulas disponíveis"
              />
            </section>
          )}
      </S.Content>
      {Number(courseProgress) === 100 &&
        classData?.course?.generate_certificate && (
          <S.ItemContainerCertificate
            onClick={() => {
              if (setCertificatePage) {
                setCertificatePage(true)
              }
            }}
          >
            <S.Item
              style={{ justifyContent: 'flex-start', maxWidth: '100%' }}
              primary_color={classData?.course.primary_color}
            >
              <TbCertificate />
              <section>
                <div>
                  <h3>Certificado</h3>
                </div>
              </section>
            </S.Item>
          </S.ItemContainerCertificate>
        )}
    </S.Container>
  )
}

export default LeftMenuMembersArea
