import React from 'react'

import * as S from './styles'
import { useNavigate } from 'react-router-dom'

interface EmptySectionProps {
  text?: string
  title?: string
  icon?: any
  background?: string
  style?: any
  hasButton?: boolean
  buttonLabel?: string
  navigateRoute?: any
  primary_color?: string
}

const EmptySection: React.FC<EmptySectionProps> = ({
  title,
  text,
  icon,
  background,
  style,
  hasButton,
  buttonLabel,
  navigateRoute,
  primary_color
}) => {
  const navigate = useNavigate()

  return (
    <S.Container background={background} style={style}>
      <div>
        {icon}
        <S.Title primary_color={primary_color}>{title}</S.Title>
        <S.Text>{text}</S.Text>
        {hasButton && (
          <button onClick={() => navigate(navigateRoute)}>{buttonLabel}</button>
        )}
      </div>
    </S.Container>
  )
}

export default EmptySection
