/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable spaced-comment */
/* eslint-disable no-trailing-spaces */
/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react'
import FrameMembersArea from '../Frame'
import { ReactComponent as Arrow } from '../../../assets/icons/ClientsMembersArea/arrowLeft.svg'
import { ReactComponent as Check } from '../../../assets/icons/ClientsMembersArea/check-progress.svg'
import { ReactComponent as Download } from '../../../assets/icons/ClientsMembersArea/download.svg'
import { ReactComponent as Play } from '../../../assets/icons/ClientsMembersArea/play.svg'
import { ReactComponent as DownloadDoc } from '../../../assets/icons/DownloadDoc.svg'

import * as S from './styles'
import { Tooltip } from '@material-ui/core'
import Comments from './Comments'
import { useNavigate, useParams } from 'react-router-dom'
import ResponsiveCourseContent from './responsive-course-content'
import { useGeneral } from '../../../contexts/GeneralContext'
import { setProgress } from '../../../lib/api'
import { iClass, iProgress } from '../../../lib/models'
import { useUser } from '../../../contexts/UserContext'
// import Player from './Player'
import EmptySection from '../../../components/EmptySection'
import { FaVideo } from 'react-icons/fa'
import Lottie from 'react-lottie'
import { PDFDownloadLink } from '@react-pdf/renderer'
import Certificate from '../../../helpers/certificatePDF'
import { getContrastColor } from '../../../lib/helpers'
import { useCourse } from '../../../contexts/CourseContext'
// import TooltipEllipses from '../../../components/TooltipEllipses'

const Course: React.FC = () => {
  const [visiblePlayButton, setVisiblePlayButton] = useState(true)
  const { user } = useUser()
  const { id } = useParams()
  const navigate = useNavigate()
  const { alertError, setActive, courseRef } = useGeneral()
  const [loadedImage, setLoadedImage] = useState(false)
  const {
    classData,
    currentMaterialIndex,
    currentSectionIndex,
    getCurrentMaterial,
    setClassData,
    courseProgress,
    handleGetClass,
    holderName
  } = useCourse()
  const [certificatePage, setCertificatePage] = useState(false)

  const getCurrentSection = () => {
    if (classData === undefined || currentSectionIndex === -1) {
      return null
    }
    return classData.sections[currentSectionIndex]
  }
  const updateCurrentMaterial = (
    prevData: iClass | undefined,
    progress: iProgress
  ) => {
    if (
      prevData === undefined ||
      currentSectionIndex === -1 ||
      currentMaterialIndex === -1
    ) {
      return prevData
    }
    const materials = prevData.sections[currentSectionIndex].materials

    const copy = [...materials]
    copy[currentMaterialIndex].progress = [progress]
    prevData.sections[currentSectionIndex].materials = copy
    return prevData
  }

  const handleCreateNewProgress = async () => {
    const obj = { ...getCurrentMaterial() }

    if (obj) {
      const response = await setProgress({
        material_id: obj?.id,
        value: 0
      })

      setClassData((prevData: iClass | undefined) => {
        const updatedData = updateCurrentMaterial(prevData, response)
        return updatedData
      })

      handleGetClass()
    }
  }

  const sendMaterialProgress = async (value: number) => {
    try {
      const obj = { ...getCurrentMaterial() }
      if (obj) {
        const response = await setProgress({
          material_id: obj.id,
          value: value
        })
        setClassData((prevData: iClass | undefined) => {
          const updatedData = updateCurrentMaterial(prevData, response)
          return updatedData
        })
        await handleGetClass()
      }
    } catch (err: any) {
      alertError(err?.response?.data || err?.data || err)
    }
  }

  const handleMarkAsWatched = async () => {
    try {
      const materialData =
        classData?.sections[currentSectionIndex].materials[currentMaterialIndex]
      const obj = { ...materialData }
      if (obj && obj.progress && obj.id !== undefined) {
        const response = await setProgress({ material_id: obj.id, value: 100 })
        setClassData((prevData: iClass | undefined) =>
          updateCurrentMaterial(prevData, response)
        )

        if (courseRef.current) {
          courseRef.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        }
        await handleGetClass(true)
      }
    } catch (err: any) {
      alertError(err?.response?.data || err?.data || err)
    }
  }
  const [textCountDown, setTextCountDown] = useState('')

  const generateCountDown = (): boolean => {
    const currentMaterial = getCurrentMaterial()
    if (currentMaterial?.remaining_time <= 0) {
      setTextCountDown('')
      return true
    }
    const days = Math.floor(
      currentMaterial?.remaining_time / (1000 * 60 * 60 * 24)
    )
    const hours = Math.floor(
      (currentMaterial?.remaining_time % (1000 * 60 * 60 * 24)) /
        (1000 * 60 * 60)
    )
    const minutes = Math.floor(
      (currentMaterial?.remaining_time % (1000 * 60 * 60)) / (1000 * 60)
    )

    let text = `${days}${days > 1 || days === 0 ? ' dias' : ' dia'}, ${hours}${
      hours > 1 || hours === 0 ? ' horas' : ' hora'
    } e ${minutes}${minutes > 1 || minutes === 0 ? ' minutos' : ' minuto'}`

    if (days === 0 && hours === 0 && minutes === 0) {
      text =
        Math.floor((currentMaterial?.remaining_time % (1000 * 60)) / 1000) +
        ' segundos'
    }
    if (isNaN(text) && days && hours && minutes) {
      setTextCountDown(text)
    }

    return false
  }

  useEffect(() => {
    handleGetClass()
    generateCountDown()
  }, [id])
  useEffect(() => {
    if (getCurrentMaterial()?.limit_content_duration) {
      const timer = setInterval(() => generateCountDown(), 500)

      return () => clearInterval(timer)
    }
    setVisiblePlayButton(true)
  }, [currentMaterialIndex])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('../../../assets/animations/robo2.json'),
    renderer: 'canvas'
  }

  const processTextToHtml = (text: string | undefined) => {
    return text
      ? text.split('<br>').map((paragraph, index) => (
          <React.Fragment key={index}>
            {paragraph}
            {index < text.length - 1 && <br />}
          </React.Fragment>
        ))
      : ''
  }

  const downloadEmployeeData = (item: string) => {
    fetch(`${item}`).then(response => {
      response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = item
        a.click()
      })
    })
  }

  const videoPlayer = useRef<HTMLVideoElement>()

  // this is prolly not working
  // it wasnt, now fixed
  const setVideoTime = () => {
    if (getCurrentMaterial()?.progress?.at(0)?.value ?? 0) {
      if (
        getCurrentMaterial()?.progress?.at(0)?.value ??
        (0 >= 10 && getCurrentMaterial()?.progress?.at(0)?.value) ??
        0 < 100
      ) {
        const duration = videoPlayer.current?.duration

        const time = (
          (Number(getCurrentMaterial()?.progress?.at(0)?.value ?? 0) *
            duration!) /
          100
        ).toFixed(2)
        if (videoPlayer.current !== null) {
          videoPlayer.current.currentTime = Number(time)
        }
      } else {
        if (videoPlayer.current !== null) {
          videoPlayer.current.currentTime = Number(0)
        }
      }
    }
  }
  // idrk if this is working well, but ok
  // it is
  useEffect(() => {
    if (videoPlayer.current) {
      videoPlayer.current.pause()
      videoPlayer.current.src = getCurrentMaterial()?.video
      videoPlayer.current.load()
      videoPlayer.current.onloadedmetadata = setVideoTime

      if (videoPlayer.current) {
        videoPlayer.current.onpause = () => {
          if (!videoPlayer.current) return

          const { currentTime, duration } = videoPlayer.current!
          const value = ((currentTime * 100) / duration).toFixed(2)

          if (currentTime === duration) {
            sendMaterialProgress(100)
          } else {
            sendMaterialProgress(Number(value))
          }
          handleGetClass()
        }
      }
    }
  }, [getCurrentMaterial()?.video])

  useEffect(() => {
    if (!visiblePlayButton && videoPlayer.current) {
      videoPlayer.current.play()
    }
  }, [visiblePlayButton])

  return (
    <FrameMembersArea
      isCourse
      hasMenu
      setPage={setActive}
      setCertificatePage={setCertificatePage}
    >
      <S.Container>
        {!certificatePage ? (
          <>
            <S.Banner
              bg={
                getCurrentSection()?.cover ||
                'https://img.freepik.com/vetores-gratis/forma-dinamica-gradiente-escura-abstrata-com-linhas-douradas-composicao-3d-geometrica-minima_333792-86.jpg?w=900&t=st=1699390266~exp=1699390866~hmac=e33ce2bb005aaddd559e73890cdddd23fee100126e693a09b05c7adec95fe97e'
              }
            >
              <S.ClientContent primary_color={classData?.course?.primary_color}>
                <S.Content
                  percentage={Number(courseProgress)}
                  primary_color={classData?.course?.primary_color}
                >
                  {' '}
                  <section>
                    <svg>
                      <circle r="32" cx="32" cy="32" />
                      <circle r="32" cx="32" cy="32" />
                    </svg>
                    <img
                      src={
                        // section 'profile' image
                        getCurrentSection()?.image ||
                        'https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/2048px-Solid_white.svg.png' // needs to be removed (copyright)
                      }
                      onLoad={() => {
                        setLoadedImage(true)
                      }}
                    />
                    <S.LoadImage loaded={loadedImage} />
                  </section>
                  <div>
                    <span>
                      <h3>{classData?.course.name}</h3>
                      {Math.round(courseProgress)}% concluído!
                    </span>
                    {holderName && (
                      <p>
                        Um curso por <b>{holderName || ''}</b>
                      </p>
                    )}
                  </div>
                </S.Content>

                <section>
                  <button
                    onClick={() => {
                      navigate('/')
                    }}
                  >
                    <Arrow />
                    Voltar para <p>Meu Conteúdo</p>
                  </button>
                </section>
              </S.ClientContent>
            </S.Banner>
            <S.Wrapper>
              <ResponsiveCourseContent
                setCertificatePage={setCertificatePage}
              />
            </S.Wrapper>
            <S.Wrapper>
              <S.DataContent>
                <div>
                  <p>Você está assistindo:</p>
                  <span>{getCurrentMaterial()?.name}</span>
                </div>
                {getCurrentMaterial()?.limit_content_duration && (
                  <section>
                    <p>
                      <b>ATENÇÃO!</b> Este conteúdo só está disponível por:
                    </p>
                    <S.CountDown
                      primary_color={classData?.course?.primary_color}
                    >
                      <p>{textCountDown}</p>
                    </S.CountDown>
                  </section>
                )}
              </S.DataContent>
            </S.Wrapper>

            <S.Wrapper>
              {getCurrentMaterial()?.video !== undefined && (
                <S.VideoContainer
                  isPlayer={!visiblePlayButton}
                  primary_color={classData?.course?.primary_color}
                  visible={visiblePlayButton}
                  hex={getContrastColor(classData?.course.primary_color)}
                >
                  {visiblePlayButton && (
                    <>
                      <S.PlayButton
                        primary_color={classData?.course?.primary_color}
                        onClick={() => {
                          setVisiblePlayButton(false)
                          handleCreateNewProgress()
                        }}
                        hex={getContrastColor(classData?.course.primary_color)}
                      >
                        <div>
                          <Play />
                        </div>
                      </S.PlayButton>
                    </>
                  )}
                  {!visiblePlayButton && (
                    <>
                      {getCurrentMaterial()?.progress?.at(0)?.value === 100 ? (
                        <button
                          onClick={() => {
                            handleCreateNewProgress()
                            videoPlayer.current = 'play'
                          }}
                        >
                          Aula concluída!{' '}
                          <div>
                            <Check />
                          </div>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            handleMarkAsWatched()
                          }}
                        >
                          Marcar aula como assistida{' '}
                          <div>
                            <Check />
                          </div>
                        </button>
                      )}
                    </>
                  )}
                  {/* <Player
                    visibleButton={visiblePlayButton}
                    src={getCurrentMaterial()?.video!}
                    updateProgress={sendMaterialProgress}
                    percentage={
                      getCurrentMaterial()?.progress?.at(0)?.value ?? 0
                    }
                  /> */}
                  <S.VideoPlayer
                    ref={videoPlayer}
                    controls={!visiblePlayButton}
                  >
                    <source
                      src={getCurrentMaterial()?.video}
                      type="video/webm"
                    />
                  </S.VideoPlayer>
                  {!visiblePlayButton &&
                    getCurrentMaterial()?.video !== undefined &&
                    classData?.course.water_mark !== null && (
                      <S.MarkWater>
                        {classData.course.water_mark !== '' && (
                          <img src={classData.course.water_mark} />
                        )}
                      </S.MarkWater>
                    )}
                </S.VideoContainer>
              )}

              {getCurrentMaterial()?.video === undefined && (
                <EmptySection
                  icon={
                    <FaVideo
                      size={28}
                      color={
                        classData?.course.primary_color || 'var(--primary)'
                      }
                    />
                  }
                  style={{ maxWidth: 980 }}
                  primary_color={classData?.course.primary_color}
                  background="var(--naval)"
                  text="Ops! Parece que não há vídeos disponíveis para este curso no momento. Volte em breve para conferir as atualizações"
                  title="Sem vídeos disponíveis"
                />
              )}
            </S.Wrapper>
            {getCurrentMaterial()?.video !== undefined && (
              <S.ButtonsContainer
                primary_color={classData?.course?.primary_color}
              >
                {getCurrentMaterial()?.progress?.at(0)?.value === 100 ? (
                  <button onClick={handleCreateNewProgress}>
                    Aula concluída!{' '}
                    <div>
                      <Check />
                    </div>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      handleMarkAsWatched()
                    }}
                  >
                    Marcar aula como assistida{' '}
                    <div>
                      <Check />
                    </div>
                  </button>
                )}
              </S.ButtonsContainer>
            )}
            {getCurrentMaterial()?.description !== undefined && (
              <S.Wrapper>
                <S.Card style={{ alignItems: 'flex-start', gap: 16 }}>
                  <S.Title>Descrição</S.Title>
                  <p style={{ fontSize: 12, color: 'var(--white)' }}>
                    {processTextToHtml(getCurrentMaterial()?.description)}
                  </p>
                </S.Card>
              </S.Wrapper>
            )}
            {currentMaterialIndex !== -1 &&
              (getCurrentMaterial()?.attachments?.length || 0) > 0 && (
                <S.Wrapper>
                  <S.Card>
                    <S.Attachments>
                      <S.Title>Anexos desta aula</S.Title>

                      <S.AttachmentsContent
                        primary_color={classData?.course?.primary_color}
                      >
                        {getCurrentMaterial()?.attachments?.map(
                          (attachment, index) => {
                            return (
                              <div>
                                <div
                                  onClick={() =>
                                    downloadEmployeeData(attachment)
                                  }
                                >
                                  <Tooltip
                                    title={
                                      attachment !== undefined ? attachment : ''
                                    }
                                    placeholder="top-start"
                                    arrow
                                  >
                                    <a
                                      onClick={() =>
                                        downloadEmployeeData(attachment)
                                      }
                                    >
                                      <p>{`Arquivo: ${index + 1}`}</p>
                                      <span
                                        style={{
                                          color: getContrastColor(
                                            classData?.course.primary_color
                                          )
                                        }}
                                      >
                                        {attachment
                                          ? attachment
                                              .split('.')
                                              .at(-1)
                                              ?.toUpperCase()
                                          : ''}
                                      </span>
                                    </a>
                                  </Tooltip>
                                  <Download />
                                </div>
                              </div>
                            )
                          }
                        )}
                      </S.AttachmentsContent>
                    </S.Attachments>
                  </S.Card>
                </S.Wrapper>
              )}

            {currentMaterialIndex !== -1 &&
              classData?.course?.allow_comments && (
                <Comments
                  primary_color={classData?.course?.primary_color}
                  course_id={classData?.course.id}
                  material_id={
                    classData.sections[currentSectionIndex]?.materials[
                      currentMaterialIndex
                    ]?.id
                  }
                  student_id={user.name}
                />
              )}
          </>
        ) : (
          <>
            <S.BackButtonCertificate
              primary_color={classData?.course?.primary_color}
            >
              <button
                onClick={() => {
                  navigate('/')
                }}
              >
                <Arrow />
                <p>
                  Voltar para <span>Meu Conteúdo</span>
                </p>
              </button>
            </S.BackButtonCertificate>
            <S.certificateContainer
              primary_color={classData?.course?.primary_color}
            >
              <S.TitleCertificate
                primary_color={classData?.course?.primary_color}
              >
                Parabéns! Você <span>concluiu</span> este curso!
              </S.TitleCertificate>
              <S.Text primary_color={classData?.course?.primary_color}>
                Te parabenizamos por <span>finalizar</span> mais uma etapa do
                seu <span>aprendizado!</span>
              </S.Text>
              <S.AnimationContainer>
                <Lottie options={defaultOptions} height={160} />
              </S.AnimationContainer>
              <S.TitleCertificate
                primary_color={classData?.course?.primary_color}
              >
                Aproveite e aplique seus novos <span>conhecimentos!</span>
              </S.TitleCertificate>
              <S.Text primary_color={classData?.course?.primary_color}>
                Você já pode baixar seu certificado <span>clicando</span>{' '}
                abaixo!
              </S.Text>
              <PDFDownloadLink
                document={
                  <Certificate
                    student={user?.name || ''}
                    course={classData?.course?.name || ''}
                    holder={holderName || ''}
                  />
                }
                fileName={`Certificado ${classData?.course?.name || ''}.pdf`}
              >
                <S.Button
                  style={{
                    color: getContrastColor(classData?.course?.primary_color)
                  }}
                  primary_color={classData?.course?.primary_color}
                  hex={getContrastColor(classData?.course?.primary_color)}
                >
                  <DownloadDoc /> Baixar meu certificado
                </S.Button>
              </PDFDownloadLink>
            </S.certificateContainer>
          </>
        )}
      </S.Container>
    </FrameMembersArea>
  )
}

export default Course
