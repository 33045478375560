import styled from 'styled-components'

interface ContainerProps {
  opened?: boolean
}

export const Container = styled.div<ContainerProps>`
  grid-area: LM;
  background: var(--naval);
  display: flex;
  overflow: auto;
  transition: 0.3s ease;
  box-shadow: var(--shadow);
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1023px) {
    position: fixed;
    top: 70px;
    width: 275px;
    bottom: 0;
    transform: translateX(${props => (props.opened ? 0 : -275)}px);
    z-index: 2;
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--inputs);
  height: fit-content;

  > div:last-child > section {
    display: none;
  }

  > section {
    > div > div {
      > h4 {
        font-size: 14px;
      }

      > span {
        font-size: 12px;
      }
    }
  }
`

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-decoration: none;
  }
`

export const Divisor = styled.section`
  width: calc(100% - 32px);
  height: 1px;
  background: #34383c;
  margin-top: -1px;
  position: relative;
`

export const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const ItemContainerCertificate = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  /* right: calc(100% - 32px); */
  /* width: calc(100% - 32px); */
`

interface ItemProps {
  opened?: boolean
  active?: boolean
  primary_color?: string
}

export const Item = styled.a<ItemProps>`
  display: flex;
  padding: 16px 32px;
  align-items: center;
  gap: 12px;
  transition: 0.2s ease;
  cursor: pointer;
  background: ${props =>
    props.active ? 'var(--inputs)' : 'var(--background)'};
  justify-content: space-between;
  height: 71px;

  > section {
    display: flex;
    gap: 12px;
    align-items: center;

    > svg {
      height: 17px;
      width: 24px;
      > path {
        height: 17px;
        width: 15px;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      width: 100%;

      > section {
        max-width: 150px;
        width: 100%;
        > section {
          > section {
            width: 100%;

            > div > span {
              bottom: 12px;
              font-size: 10px;
              right: -52px;
              left: -52px;
            }
            > div > h3 {
              flex: 1;
              color: var(--white);
              font-weight: 500;
              font-size: 13px;
              line-height: 15px;
              padding: 0 !important;
            }
          }
        }
      }

      > h3 {
        flex: 1;
        color: var(--white);
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
      }

      > p {
        flex: 1;
        color: var(--primary);
        font-weight: 300;
        font-size: 11px;
        line-height: 12px;
      }
    }
  }

  > svg {
    color: ${props =>
      props.primary_color ? props.primary_color : 'var(--primary)'};
    transition: 0.2s ease;

    &:last-child {
      transform: rotate(${props => (props.opened ? 90 : 0)}deg);
    }
  }

  &:hover {
    background: var(--background);
  }
  > section > svg * {
    stroke: ${p => (p?.primary_color ? p.primary_color : 'var(--primary)')};
  }
  > section > div > p {
    color: ${p => (p?.primary_color ? p.primary_color : 'var(--primary)')};
  }
  > svg * {
    color: ${p => (p?.primary_color ? p.primary_color : 'var(--primary)')};
  }
`

export const SubItemContainer = styled.div<ItemProps>`
  display: flex;
  flex-direction: column;
  max-height: ${props => (props.opened ? '100%' : 0)};
  overflow-x: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
  > svg * {
    stroke: ${p => (p?.primary_color ? p.primary_color : 'var(--primary)')};
  }
`

export const SubItem = styled.a<ItemProps>`
  display: flex;
  padding: 16px;
  padding-left: 32px;
  align-items: center;
  gap: 16px;
  transition: 0.2s ease;
  cursor: pointer;
  background: ${props =>
    props.active ? `${props.primary_color || ''}1A` : 'var(--background)'};
  justify-content: space-between;

  > div {
    display: flex;
    gap: 8px;

    > video {
      width: 80px;
      height: 40px;
      border-radius: 5px;
      object-fit: cover;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 12px;
      > span {
        color: var(--white);
        font-weight: 400;
        font-size: 14px;
        max-width: 107px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      > p {
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
        color: var(--primary);
      }
    }
  }

  .check {
    > path {
      stroke: ${p => (p?.primary_color ? p.primary_color : 'var(--primary)')};
    }

    > circle {
      stroke: ${p => (p?.primary_color ? p.primary_color : 'var(--primary)')};
    }
  }

  .play {
    > path {
      fill: ${p => (p?.primary_color ? p.primary_color : 'var(--primary)')};
    }
  }
`
