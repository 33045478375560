/* eslint-disable indent */
/* eslint-disable prettier/prettier */
// import { Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import { AiOutlineQuestion } from 'react-icons/ai'

import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import { MdSearch } from 'react-icons/md'

import Tooltip from '../ToolTip'

import {
  Container,
  Label,
  InputContainer,
  Prefix,
  InputElement,
  Status,
  Error,
  HidePassword,
  IconContainer,
  InputTextAreaElement,
  ToolTipContent
} from './styles'

export interface ErrorTypes {
  id: number
  message: string
}
interface InputProps {
  label?: string | boolean | any
  placeholder?: string
  value?: string | number | readonly string[] | undefined
  defaultValue?: string
  type?: string
  onChange?: any
  onBlur?: any
  status?: number
  prefix?: string
  error?: string
  textPrefix?: string
  light?: boolean
  isPassword?: boolean
  onKeyUp?: any
  disable?: boolean
  icon?: any
  isPasswordWithoutIcon?: boolean
  isSearch?: any
  isRequired?: boolean | string
  ref?: any
  onKeyPress?: any
  autocomplete?: string
  hasToolTip?: boolean
  titleToolTip?: any
  borderError?: boolean
  maxLength?: number
  height?: string
  onClick?: () => void
  onSubmit?: React.FormEventHandler<HTMLInputElement>
  onKeyDown?: any
}

// Status 1 -> Verificado
// Status 2 -> Erro

const Input: React.FC<InputProps> = props => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <Container>
      <Label label={props.label}>
        {props.label}
        {props.isRequired && <span>*</span>}

        {props.hasToolTip && (
          <Tooltip content={props.titleToolTip}>
            <ToolTipContent>
              <div>
                <AiOutlineQuestion />
              </div>
            </ToolTipContent>
          </Tooltip>
        )}
      </Label>
      <InputContainer light={props.light}>
        {props.prefix && <Prefix>{props.prefix || '+55'}</Prefix>}
        {props.textPrefix && (
          <Prefix style={{ color: 'var(--white)' }}>{props.textPrefix}</Prefix>
        )}
        <InputElement
          type={
            (props.isPassword && !isVisible) ||
            (props.isPasswordWithoutIcon && !isVisible)
              ? 'password'
              : props.type || 'text'
          }
          onKeyDown={props.onKeyDown}
          onSubmit={props.onSubmit}
          placeholder={props.placeholder}
          value={props.value || ''}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onKeyUp={props.onKeyUp}
          disabled={props.disable}
          readOnly={props.disable}
          icon={props.icon}
          ref={props.ref}
          onKeyPress={props.onKeyPress}
          autoComplete={props.autocomplete}
          isPrefix={props.prefix}
          borderError={props.borderError}
          maxLength={props.maxLength}
        />
        {props.status !== undefined && props.status !== 0 && (
          <Status light={props.light} status={props.status} />
        )}
        {props.isPassword && (
          <HidePassword
            onClick={() => setIsVisible(!isVisible)}
            withoutIcon={props.isPasswordWithoutIcon}
          >
            {!isVisible ? (
              <IoMdEyeOff size={18} color="var(--primary)" />
            ) : (
              <IoMdEye size={18} color="var(--primary)" />
            )}
          </HidePassword>
        )}

        <IconContainer
          isSearch={props.isSearch}
          onClick={props.onClick}
          className="icon"
        >
          {props.isSearch && <MdSearch size={16} color="white" />}
          {props.icon}
        </IconContainer>
      </InputContainer>
      {props.error && typeof props.error !== 'number' && (
        <Error>{props.error}</Error>
      )}
    </Container>
  )
}

export default Input

export const InputArea: React.FC<InputProps> = props => {
  return (
    <Container>
      <Label label={props.label}>{props.label}</Label>
      <InputContainer
        light={props.light}
        isTextArea
        className="textAreaConteiner"
        height={props.height}
      >
        {props.prefix && <Prefix>+55</Prefix>}
        <InputTextAreaElement
          placeholder={props.placeholder}
          value={props.value}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onKeyUp={props.onKeyUp}
          disabled={props.disable}
          readOnly={props.disable}
        />
        {props.status !== undefined && props.status !== 0 && (
          <Status light={props.light} status={props.status} />
        )}
        <IconContainer onClick={props.onClick}>{props.icon}</IconContainer>
      </InputContainer>
      {props.error && typeof props.error !== 'number' && (
        <Error>{props.error}</Error>
      )}
    </Container>
  )
}
