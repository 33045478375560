import React, { useState } from 'react'
import { MdLockOutline, MdOutlineMailOutline } from 'react-icons/md'
import { Link } from 'react-router-dom'
import Background from '../../../../assets/images/MembersArea/backgroundMembersArea.png'
import CardBackground from '../../../../assets/images/MembersArea/cardBackground2.png'
import Keed from '../../../../assets/images/logo-blue.svg'
import { MiddleButton } from '../../../../components/Button'
import CheckBox from '../../../../components/CheckBox'
import Input from '../../../../components/Input'
import * as S from './styles'
import { useUser } from '../../../../contexts/UserContext'
import { LoginUser, LoginUserErrors } from '../../../../lib/api'
import { useGeneral } from '../../../../contexts/GeneralContext'
import { handleVerify } from './utils'

const onChangeInputGeneral = (
  data: LoginUser,
  setData: React.Dispatch<React.SetStateAction<LoginUser>>,
  prop: keyof LoginUser,
  value: any,
  verify: (d?: LoginUser) => boolean,
  error: LoginUserErrors
) => {
  const obj = { ...data }
  obj[prop] = value
  setData(obj)
  if (error[prop] !== '') {
    verify(obj)
  }
}

const defaultData = {
  email: '',
  password: ''
}

const LoginMembersArea: React.FC = () => {
  const { login } = useUser()
  const { alertError } = useGeneral()
  const [data, setData] = useState<LoginUser>(defaultData)
  const [error, setError] = useState<LoginUserErrors>({ ...defaultData })
  const [isRemember, setIsRemember] = useState(false)

  const verify = (d?: LoginUser) => {
    const newData = d || data
    return handleVerify(newData, error, setError)
  }

  // does not save data? done fckin moron
  const handleSendData = async () => {
    try {
      // regex blocking generated password
      await login({ ...data, type: 'STUDENT' }, isRemember)
    } catch (err: any) {
      alertError(err?.response?.data || err?.data || err)
    }
  }

  return (
    <S.Container bg={Background}>
      <S.Card>
        <S.LeftContent bg={CardBackground}>
          <h4>
            Seja
            <h3>
              Bem-vindo <p>!</p>
            </h3>
          </h4>
          <img src={Keed} />
        </S.LeftContent>
        <S.RightContent>
          <S.Column style={{ gap: 8 }}>
            <h3>
              Esta é a <b>KeedClass</b>!
            </h3>
            <h4>
              Todos os seus cursos, <b>no mesmo lugar</b>.
            </h4>
          </S.Column>
          <S.Column>
            <Input
              placeholder="Digite o e-mail"
              icon={<MdOutlineMailOutline />}
              value={data.email || ''}
              error={error.email}
              onChange={(v: any) => {
                const value = v.target.value
                onChangeInputGeneral(
                  data,
                  setData,
                  'email',
                  value,
                  verify,
                  error
                )
              }}
              onKeyDown={(e: any) => {
                if (e.key.includes('Enter')) {
                  handleSendData()
                }
              }}
            />
            <Input
              placeholder="Digite a senha"
              icon={<MdLockOutline />}
              isPassword
              onChange={(v: any) => {
                const value = v.target.value
                onChangeInputGeneral(
                  data,
                  setData,
                  'password',
                  value,
                  verify,
                  error
                )
              }}
              value={data.password || ''}
              error={error.password}
              onKeyDown={(e: any) => {
                if (e.key.includes('Enter')) {
                  if (verify()) {
                    handleSendData()
                  }
                }
              }}
            />
            <S.KeepConnected>
              <CheckBox
                text="Mantenha-me conectado."
                active={isRemember}
                action={() => setIsRemember(!isRemember)}
              />
              <button style={{ whiteSpace: 'nowrap' }}>
                <Link
                  to={'/members-area/forgot-password'}
                  style={{ textDecoration: 'none', fontSize: '14px' }}
                >
                  Esqueci a senha
                </Link>
              </button>
            </S.KeepConnected>
          </S.Column>
          <MiddleButton
            text="Entrar"
            onClick={() => {
              if (verify()) {
                handleSendData()
              }
            }}
            style={{ maxWidth: 170, width: '100%' }}
          />
        </S.RightContent>
      </S.Card>
    </S.Container>
  )
}

export default LoginMembersArea
