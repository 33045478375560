import axios from 'axios'
import moment from 'moment'
import errorData from './errors.json'

export const getContrastColor = (hexColor: string) => {
  hexColor = hexColor ? hexColor.replace('#', '') : ''

  var r = parseInt(hexColor.slice(0, 2), 16)
  var g = parseInt(hexColor.slice(2, 4), 16)
  var b = parseInt(hexColor.slice(4, 6), 16)

  var luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  var threshold = 0.5

  return luminance > threshold ? '#212529' : '#FBFFFC'
}

export const dateFormatter = (date: string): string => {
  // retira os caracteres indesejados...
  date = date.replace(/[^\d]/g, '')

  // realizar a formatação...
  return date.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3').substring(0, 10)
}

export const formatDateV2 = (date: string) => {
  const newDate = new Date(date)

  const day =
    `${newDate.getDate()}`.length === 1
      ? `0${newDate.getDate()}`
      : newDate.getDate()

  const month =
    `${newDate.getMonth() + 1}`.length === 1
      ? `0${newDate.getMonth() + 1}`
      : newDate.getMonth() + 1

  return `${day}-${month}-${newDate.getFullYear()}`
}

const moneyFormatter = (value: string): string => {
  const v = Math.round(parseFloat(value) * 100) / 100
  return v.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

const moneyMask = (value: string): any => {
  value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

  const options = { minimumFractionDigits: 2 }
  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(value) / 100
  )
  if (result === undefined || result === 'NaN') {
    return '0,00'
  }
  return result
}

const convertMoneyStringToDecimal = (num: string) => {
  return parseFloat(
    num.replaceAll('.', '').replaceAll(',', '.').replaceAll(' ', '')
  )
}

const getInputWidthWithdraw = (value: string): any => {
  const div = document.createElement('div')
  div.innerText = value
  div.style.fontSize = '40px'
  div.style.width = 'auto'
  div.style.display = 'inline-block'
  div.style.visibility = 'hidden'
  div.style.position = 'fixed'
  div.style.overflow = 'auto'
  document.body.append(div)
  const width = div.clientWidth
  div.remove()
  return width
}

const juros = (valor: number, juros: number, meses: number) => {
  return (valor * (1 + juros) ** meses) / meses
}

const phoneFormatter = (phone: string): string => {
  const cleaned = ('' + phone).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{5})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + ' ' + match[3] + '-' + match[4]
  }

  const match2 = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/)
  if (match2) {
    return (
      '(' + match2[1] + ') ' + match2[2] + ' ' + match2[3] + '-' + match2[4]
    )
  }
  return phone
}
const checkArrayIsSame = (array1: string[], array2: string[]): boolean => {
  const is_same =
    array1.length === array2.length &&
    array1.every(function (element, index) {
      return element === array2[index]
    })

  return is_same
}

const firstToUpper = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1)

const convertPaymetMethod = (method: number): string => {
  switch (method) {
    case 0:
      return 'Cartão'
    case 1:
      return 'Pix'
    case 2:
      return 'Boleto'
    default:
      return ''
  }
}

const intervalFormatter = (method: string, period?: number): string => {
  switch (method) {
    case 'year':
      return 'Anual'
    case 'month':
      if (period === 1) {
        return 'Mensal'
      } else if (period === 2) {
        return 'Bimestral'
      } else if (period === 3) {
        return 'Trimestral'
      } else {
        return 'Semestral'
      }
    case 'week':
      return 'Semana'
    default:
      return 'Diário'
  }
}

const checkEmail = (mail: string): boolean => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true
  }
  return false
}

const checkCPF = (cpf: string): boolean => {
  cpf = cpf.replace(/[^\d]+/g, '')

  if (cpf === '') return false
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false
  }
  // Valida 1o digito
  let add = 0
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf.charAt(i)) * (10 - i)
  }
  let rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) {
    rev = 0
  }
  if (rev !== parseInt(cpf.charAt(9))) {
    return false
  }
  // Valida 2o digito
  add = 0
  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf.charAt(i)) * (11 - i)
  }
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) {
    rev = 0
  }
  if (rev !== parseInt(cpf.charAt(10))) {
    return false
  }
  return true
}

const cpfFormatter = (cpf: string): string => {
  // retira os caracteres indesejados...
  cpf = cpf ? cpf.replace(/\D/g, '') : ''

  // realizar a formatação...
  return cpf
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    .substring(0, 14)
}

function checkCNPJ(cnpj: string): boolean {
  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj === '') return false

  if (cnpj.length !== 14) return false

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) {
    return false
  }

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--
    if (pos < 2) pos = 9
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado !== parseInt(digitos.charAt(0))) return false

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado !== parseInt(digitos.charAt(1))) return false

  return true
}

const cnpjFormatter = (cpf: string): string => {
  // retira os caracteres indesejados...
  cpf = cpf.replace(/\D/g, '')

  // realizar a formatação...
  return cpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

// 0 Nulo
// 1 Recusado
// 2 Aprovado
// 3 Expirado (não renovado)
// 4 Cancelado
// 5 Pendente

const orderStatus = (status: number): string => {
  switch (status) {
    case 1:
      return 'Pedido recusado'
    case 2:
      return 'Pedido aprovao'
    case 3:
      return 'Pedido não renovado'
    case 4:
      return 'Pedido cancelado'
    case 5:
      return 'Pedido pendente'
    default:
      return 'Pedido pendente'
  }
}

const cardFormatter = (number: string): string => {
  // retira os caracteres indesejados...
  number = number.replace(/[^\d]/g, '')

  // realizar a formatação...
  return number
    .replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4')
    .substring(0, 19)
}

const cepFormatter = (cep: string): string => {
  // retira os caracteres indesejados...
  cep = cep.replace(/[^\d]/g, '')

  // realizar a formatação...
  return cep.replace(/(\d{5})(\d{3})/, '$1-$2').substring(0, 9)
}

const checkCard = (num: string): string | boolean => {
  num = num.replace(/[^\d]/g, '')
  const cartoes = [
    { name: 'Visa', regex: /^4[0-9]{12}(?:[0-9]{3})/ },
    {
      name: 'Mastercard',
      regex:
        /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/
    },
    { name: 'Amex', regex: /^3[47][0-9]{13}/ },
    { name: 'DinersClub', regex: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/ },
    { name: 'Discover', regex: /^6(?:011|5[0-9]{2})[0-9]{12}/ },
    { name: 'JCB', regex: /^(?:2131|1800|35\d{3})\d{11}/ },
    {
      name: 'Elo',
      regex:
        /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/
    },
    {
      name: 'Aura',
      regex: /^(5078\d{2})(\d{2})(\d{11})$/
    },
    {
      name: 'Hipercard',
      regex: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/
    },
    {
      name: 'Maestro',
      regex: /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/
    }
  ]
  let bandeira
  cartoes.forEach((cartao, i) => {
    if (num.match(cartoes[i].regex)) {
      bandeira = cartao.name
    }
  })
  return bandeira || false
}

const checkCep = async (cep: string) => {
  const validity = await axios.get(
    `https://viacep.com.br/ws/${cep.replace(/\D+/g, '')}/json/`
  )
  if (validity.data.cep) {
    return true
  } else {
    return false
  }
}

const checkDate = (date: string): boolean => {
  if (date.length < 5) return false
  const actualDate = moment()
  return !!moment(date, 'MM/YY').isAfter(actualDate, 'month')
}

const validityFormatter = (val: string): string => {
  // retira os caracteres indesejados...
  val = val.replace(/[^\d]/g, '')

  // realizar a formatação...
  return val.replace(/(\d{2})(\d{2})/, '$1/$2').substring(0, 5)
}

const nameFormatter = (nameFormatter: string): string => {
  // retira os caracteres indesejados...
  return nameFormatter.replace(
    /[^a-zA-ZÀ-úçÇsA-Za-zZùÙüÜäàáâãëèéêïìíîöòóôõüùúûÄÀÁÂÃËÈÉÊÏÌÍÖÒÓÔÕÜÚÛñÑ'~^ ]/g,
    ''
  )
}

const numberFormatter = (number: string): string => {
  // retira os caracteres indesejados...
  number = number.replace(/[^\d]/g, '')
  return number.replace(/[^\d]/g, '')
}

const expirationDate = (phone: string): string => {
  const cleaned = ('' + phone).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{2})(\d{2})$/)
  if (match) {
    return match[1] + '/' + match[2]
  }

  const match2 = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/)
  if (match2) {
    return match2[1] + '/' + match2[2]
  }
  return phone
}

const checkPhone = (phone: string): boolean => {
  const cleaned = ('' + phone).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/)
  if (match) {
    return true
  }

  const match2 = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{3})$/)
  if (match2) {
    return true
  }
  return false
}

const checkUrl = (value: string) => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  )
}

const getInputWidth = (value: string): any => {
  const div = document.createElement('div')
  div.innerText = value
  div.style.fontSize = '20px'
  div.style.fontWeight = '600'
  div.style.width = 'auto'
  div.style.display = 'inline-block'
  div.style.visibility = 'hidden'
  div.style.position = 'fixed'
  div.style.overflow = 'auto'
  document.body.append(div)
  const width = div.clientWidth
  div.remove()
  return width
}

const normalizer = (text: string): string => {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

const convertToFormData = (obj: any): FormData => {
  const formData = new FormData()

  if (typeof obj !== 'string') {
    obj.forEach((element: any) => {
      formData.append('files', element)
    })
  } else {
    formData.append('files', obj)
  }

  return formData
}

const translateError = (err: string): string => {
  return errorData[err as keyof typeof errorData]
}

export {
  moneyFormatter,
  moneyMask,
  convertMoneyStringToDecimal,
  getInputWidthWithdraw,
  juros,
  phoneFormatter,
  checkArrayIsSame,
  firstToUpper,
  convertPaymetMethod,
  intervalFormatter,
  checkEmail,
  checkCPF,
  cpfFormatter,
  checkCNPJ,
  cnpjFormatter,
  orderStatus,
  cardFormatter,
  cepFormatter,
  checkCard,
  checkCep,
  checkDate,
  validityFormatter,
  nameFormatter,
  numberFormatter,
  expirationDate,
  checkPhone,
  checkUrl,
  getInputWidth,
  normalizer,
  convertToFormData,
  translateError
}
