import React from 'react'
import { GeneralProvider } from './contexts/GeneralContext'
import { UserProvider } from './contexts/UserContext'
import ApplicationRoutes from './Routes'

import './styles/FontImport.css'
import GlobalStyles from './styles/GlobalStyles'
import { CourseProvider } from './contexts/CourseContext'

const App: React.FC = () => {
  return (
    <GeneralProvider>
      <UserProvider>
        <CourseProvider>
          <GlobalStyles />
          <ApplicationRoutes />
        </CourseProvider>
      </UserProvider>
    </GeneralProvider>
  )
}

export default App
