import React, { useMemo } from 'react'

import { Routes, Route, Navigate } from 'react-router-dom'

import Loading from './components/Loading'

import HomeMembersArea from './pages/ClientsMembersArea/Home'
import Course from './pages/ClientsMembersArea/Course'
import ForgotPasswordMembersArea from './pages/ClientsMembersArea/Auth/ForgotPassword'
import { useUser } from './contexts/UserContext'
import Login from './pages/ClientsMembersArea/Auth/Login'
import { CourseProvider } from './contexts/CourseContext'
// import ChangePasswordEmailCode from './pages/ClientsMembersArea/ChangePasswordEmailCode'

interface PrivateRouteProps {
  user: any
  isSignUp?: boolean
  El: any
  loaded: boolean
  invert?: boolean
  admin?: boolean
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  user,
  El,
  loaded,
  invert
}) => {
  const NewEl = useMemo(() => El, [window.location.href])
  if (!loaded) return <Loading />

  if (user) return invert ? <Navigate to="/" replace /> : <NewEl />
  if (!user) {
    return invert ? <NewEl /> : <Navigate to="/members-area/login" replace />
  }
  return invert ? <Navigate to="/" replace /> : <NewEl />
}

const generateProvider = (El: any, Pr: any) => {
  return () => (
    <Pr>
      <El />
    </Pr>
  )
}

const DefaultRoutes: React.FC = () => {
  const { user, isReady } = useUser()

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute
            user={user}
            El={HomeMembersArea}
            loaded={isReady}
            invert={false}
          />
        }
      />
      {/* <Route
        path="/change-password"
        element={
          <PrivateRoute
            user={user}
            El={ChangePasswordEmailCode}
            loaded={isReady}
            invert={true}
          />
        }
      /> */}
      <Route
        path="/members-area/clients/course/:id"
        element={
          <PrivateRoute
            user={user}
            El={generateProvider(Course, CourseProvider)}
            loaded={isReady}
          />
        }
      />
      <Route
        path="/members-area/login"
        element={
          <PrivateRoute user={user} El={Login} loaded={isReady} invert />
        }
      />
      <Route
        path="/members-area/forgot-password"
        element={<ForgotPasswordMembersArea />}
      />
    </Routes>
  )
}

export default DefaultRoutes
