/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { ReactComponent as Send } from '../../../../assets/icons/ClientsMembersArea/send.svg'
import Input from '../../../../components/Input'
import { Title, Wrapper } from '../styles'
import * as S from './styles'
import { iComment } from '../../../../lib/models'
import { createComment, getComments } from '../../../../lib/api'
import { useGeneral } from '../../../../contexts/GeneralContext'
import { getContrastColor } from '../../../../lib/helpers'

interface CommentListProps {
  primary_color?: string
  course_id?: string
  material_id?: string
  student_id: string
  comments?: iComment[]
}

const Comments: React.FC<CommentListProps> = ({
  primary_color,
  material_id,
  student_id,
  course_id
}) => {
  const [inputComment, setInputComment] = useState<string>('')
  const [commentList, setCommentList] = useState<iComment[]>([])
  const { alertError, setIsLoading } = useGeneral()
  // dear god forgive me for my sins
  const [inputAnswer, setInputAnswer] = useState<string[]>([
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ''
  ])

  const updateIndexValue = (index: number, value: string) => {
    setInputAnswer(prevState => {
      const newState = [...prevState]
      newState[index] = value
      return newState
    })
  }

  const getMaterialComments = async () => {
    try {
      const response = await getComments(material_id)
      setCommentList(response)
    } catch (err: any) {
      alertError(err?.response?.data || err?.data || err)
    }
  }

  const handleSubmitCommit = async (comment: Partial<iComment>) => {
    setIsLoading(true)
    try {
      await createComment(comment as iComment)
      setInputComment('')
      getMaterialComments()
    } catch (err: any) {
      alertError(err?.response?.data || err?.data || err)
    }
    setIsLoading(false)
  }

  const handleSubmitAnswer = async (
    answer: Partial<iComment>,
    index: number
  ) => {
    try {
      await createComment(answer as iComment)
      updateIndexValue(index, '')
      getMaterialComments()
    } catch (err: any) {
      alertError(err?.response?.data || err?.data || err)
    }
  }

  useEffect(() => {
    getMaterialComments()
  }, [material_id])

  return (
    <Wrapper>
      <S.Card>
        <S.CardHeader>
          <div>
            <Title>Comentários</Title>
            <p>
              Confira o que seus colegas de curso estão dizendo sobre esta aula!
            </p>
          </div>
        </S.CardHeader>
        <S.InputContainer
          style={{ marginTop: -16 }}
          primary_color={primary_color}
          colorSvg={getContrastColor(primary_color || '')}
        >
          <Input
            icon={<Send />}
            placeholder="Digite seu comentário..."
            value={inputComment}
            onChange={(e: any) => {
              setInputComment(e.target.value)
            }}
            onClick={() =>
              handleSubmitCommit({
                course_id,
                material_id,
                student_id,
                text: inputComment,
                visible: true
              })
            }
            onKeyUp={(e: any) => {
              if (e.key === 'Enter') {
                handleSubmitCommit({
                  course_id,
                  material_id,
                  student_id,
                  text: inputComment,
                  visible: true
                })
              }
            }}
          />
        </S.InputContainer>

        <S.CardComments>
          {commentList.map((item, i) => {
            return (
              <S.Content
                key={i}
                primary_color={primary_color}
                colorSvg={getContrastColor(primary_color || '')}
              >
                <S.CommentContainer>
                  <img
                    src={
                      item.student.profile_img
                        ? item.student.profile_img
                        : 'https://imgur.com/RpO4IrB.png'
                    }
                  />
                  <div>
                    <span>{item.student.name}</span>
                    <p>{item.text}</p>
                  </div>
                </S.CommentContainer>
                {item.received_answers?.map((item2, t) => {
                  // item2.student.profile_img
                  // item2.student.name
                  return (
                    <S.AnswersContainer key={t}>
                      <img
                        src={
                          item2.student.profile_img ||
                          'https://imgur.com/RpO4IrB.png'
                        }
                      />
                      <div>
                        <span>{item2.student.name}</span>
                        <p>{item2.text}</p>
                      </div>
                    </S.AnswersContainer>
                  )
                })}
                <Input
                  icon={<Send />}
                  placeholder="Escreva uma resposta..."
                  value={inputAnswer[i]}
                  onChange={(e: any) => {
                    updateIndexValue(i, e.target.value)
                  }}
                  onClick={() =>
                    handleSubmitAnswer(
                      {
                        comment_id: item.id,
                        course_id,
                        text: inputAnswer[i],
                        student_id,
                        material_id
                      },
                      i
                    )
                  }
                  onKeyUp={(e: any) => {
                    if (e.key.includes('Enter')) {
                      handleSubmitAnswer(
                        {
                          comment_id: item.id,
                          course_id,
                          text: inputAnswer[i],
                          student_id,
                          material_id
                        },
                        i
                      )
                    }
                  }}
                />
              </S.Content>
            )
          })}
        </S.CardComments>
      </S.Card>
    </Wrapper>
  )
}

export default Comments
