import axios from 'axios'
import {
  iClass,
  iComment,
  iCourse,
  iEnrollment,
  IProfile,
  iProgress
} from './models'
import { IUpdateProfile } from '../pages/ClientsMembersArea/Home/ProfilePage/utils'

export const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3001/'

const api = axios.create({
  baseURL
})

export interface LoginUser {
  email?: string
  password?: string
  type?: string
}

export type LoginUserErrors = Partial<LoginUser>

export interface CreateUser {
  name?: string
  email?: string
  password?: string
  confPassword?: string
  phone?: string
  document_number?: string
}
export type CreateUserErrors = Partial<CreateUser>

export interface iSetProgressProps {
  material_id: string
  value: number
}

export interface iUpdatePassword {
  password: string
  new_password: string
  conf_new_password?: string
}

export interface ResetPasswordProps {
  email: string
  code: string
  password: string
  confirmPassword?: string
}

const prefix = '/auth'

export const getUserApi = async (): Promise<any> => {
  const response = await api.get(`${prefix}/users`)
  return response
}

export const getAccessToken = async (token: string): Promise<any> => {
  const response = await axios.post(`${baseURL + prefix}/access-token`, {
    refreshToken: token
  })
  return response
}

export const signUpApi = async (data: LoginUser): Promise<any> => {
  const response = await api.post(`${prefix}/login`, data)

  return response
}

export const getUser = async (): Promise<any> => {
  const response = await api.get(`${prefix}/user`)
  return response
}

export const logoutUser = async (refreshToken: string): Promise<any> => {
  const response = await api.post(`${prefix}/logout`, {
    refreshToken
  })
  return response
}

export const registerUser = async (data: CreateUser): Promise<any> => {
  const response = await api.post(`${prefix}/register`, data)
  return response
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const newPasswordRecovery = async (data: any): Promise<any> => {
  // email
  const response = await api.post(`${prefix}/password/code`, {
    email: data,
    type: 'STUDENT'
  })
  return response
}

export const verifyCodePassword = async (data: any): Promise<any> => {
  // email + code 1
  return await axios.post(
    `${
      !baseURL.includes('localhost') ? baseURL + prefix : baseURL + 'auth'
    }/password/validate`,
    data
  )
}

export const changeRecoveryPassword = async (
  data: ResetPasswordProps
): Promise<any> => {
  // email + code 2
  const response = await api.post(`${prefix}/password/reset`, {
    email: data.email,
    code: data.code,
    password: data.password,
    type: 'STUDENT'
  })
  return response
}

export const getEnrollments = async (): Promise<iEnrollment[]> => {
  const response = await api.get('/members-area/students/enrollments')

  return response.data.body || ([] as iEnrollment[])
}

interface ISearchCourse {
  course_name: string | undefined
}

export const getEnrollmentsByClassName = async ({
  course_name
}: ISearchCourse): Promise<iEnrollment[]> => {
  const response = await api.get(
    `/members-area/students/enrollments?course_name=${course_name}`
  )

  return response.data.body || ([] as iEnrollment[])
}

export const getCourse = async (id: string): Promise<iCourse> => {
  const response = await api.get(`/members-area/courses/${id}`)
  return (response.data.body as iCourse) || ({} as iCourse)
}

export const getClass = async (id: string): Promise<iClass> => {
  const response = await api.get(`/members-area/students/classes/${id}`)
  return (response.data.body as iClass) || ({} as iClass)
}

export const getComments = async (id: iComment['id']): Promise<iComment[]> => {
  const response = await api.get(
    `/members-area/students/comments?material_id=${id}`
  )
  return response.data.body as iComment[]
}

export const createComment = async (data: iComment): Promise<any> => {
  const response = await api.post('/members-area/students/comments', data)
  return response
}

export const setProgress = async (
  data: iSetProgressProps
): Promise<iProgress> => {
  const response = await api.patch('/members-area/students/progress', data)

  return response.data.body as iProgress
}

export const getProfile = async (): Promise<IProfile> => {
  const response = await api.get('/members-area/students/profile')
  return response.data.body as IProfile
}

export const updateProfile = async (
  data: IUpdateProfile
): Promise<IProfile> => {
  const response = await api.patch('/members-area/students/profile', data)
  return response.data.body as IProfile
}

export const updatePassword = async (data: iUpdatePassword): Promise<any> => {
  const response = await api.post(`${prefix}/student/update-password`, {
    ...data,
    type: 'STUDENT'
  })
  return response.data.body
}

export const uploadFiles = async (data: FormData): Promise<any> => {
  const response = await api.post(`${prefix}/upload`, data)
  return response
}

export const getHolderName = async (id: string): Promise<any> => {
  const response = await api.get(`/account/profile/${id}`)
  return response
}

export default api

export const apiInterceptor = (): number =>
  api.interceptors.response.use(
    response => response,
    async error => {
      const { response, config } = error

      if (response?.status !== 401) {
        return Promise.reject(error)
      }

      try {
        const refToken = localStorage.getItem('refreshToken')

        if (refToken) {
          const res = await getAccessToken(refToken)

          if (res?.status === 200) {
            const result = res?.data?.body?.accessToken
            api.defaults.headers.common.Authorization = `Bearer ${result}`

            localStorage.setItem('accessToken', result)

            config.headers = {
              ...config.headers,
              Authorization: `Bearer ${result}`
            }
            return await axios(config)
          } else {
            localStorage.removeItem('refreshToken')

            throw Error('Could not obtain accessToken')
          }
        } else {
          throw Error('Could not found refreshToken')
        }
      } catch (error) {
        localStorage.removeItem('accessToken')
        return Promise.reject(error)
      }
    }
  )
