import { ReactComponent as Content } from '../../../../assets/icons/ClientsMembersArea/content.svg'
import { ReactComponent as Profile } from '../../../../assets/icons/ClientsMembersArea/myProfile.svg'

export const ButtonsMap = [
  {
    label: 'Meu Conteúdo',
    icon: <Content />,
    route: '/',
    id: 0
  },
  {
    label: 'Meu perfil',
    icon: <Profile />,
    route: '/',
    id: 1
  }
]
