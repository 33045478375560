import React from 'react'

import * as S from './styles'
import { useNavigate } from 'react-router-dom'
import { MiddleButton } from '../../../../../components/Button'
import { useGeneral } from '../../../../../contexts/GeneralContext'

const Stage3: React.FC = () => {
  const navigate = useNavigate()
  const { setStage } = useGeneral()
  return (
    <>
      <S.ColumnStage1>
        <h3>
          <b>Parabéns!</b>
        </h3>
        <h4>
          Senha <b>redefinida</b>!
        </h4>
      </S.ColumnStage1>
      <S.ColumnStage1>
        <p style={{ textAlign: 'center', maxWidth: 360 }}>
          Sua senha foi alterada e você já pode realizar login com suas novas
          credenciais pelo botão abaixo:
        </p>
      </S.ColumnStage1>
      <MiddleButton
        onClick={() => {
          setStage(0)
          navigate('/members-area/login')
        }}
        text="Voltar ao Login"
        style={{ maxWidth: 170, width: '100%' }}
      />
    </>
  )
}

export default Stage3
