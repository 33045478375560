import React, { useState } from 'react'
import { MdLockOutline, MdOutlineMailOutline } from 'react-icons/md'
import Input from '../../../../../components/Input'

import * as S from './styles'
import { handleVerifyResetPasswordData } from './utils'
import { useGeneral } from '../../../../../contexts/GeneralContext'
import { MiddleButton } from '../../../../../components/Button'
import { useUser } from '../../../../../contexts/UserContext'
import { SubmitData } from '..'

interface StageProps {
  setStage: React.Dispatch<React.SetStateAction<number>>
  data: SubmitData
  setData: React.Dispatch<React.SetStateAction<SubmitData>>
}

const Stage2: React.FC<StageProps> = ({ data, setData }) => {
  const [error, setError] = useState<SubmitData>({} as SubmitData)

  const { setIsLoading, alertError, showAlert } = useGeneral()
  const { changePassword } = useUser()

  const resetUserPassword = async () => {
    setIsLoading(true)
    try {
      await changePassword(data)
      showAlert('', 'Os dados foram enviados!', 'Senha alterada com Sucesso!')
    } catch (err: any) {
      alertError(err?.response?.data || err?.data || err)
    }
    setIsLoading(false)
  }

  const verify = () => {
    if (
      handleVerifyResetPasswordData(data, error, setError) &&
      data.password === data.confirmation
    ) {
      resetUserPassword()
    }
  }

  return (
    <>
      <S.ColumnStage1 isStage2>
        <h3>
          Esqueceu sua <b>senha</b>?
        </h3>
        <h4>
          Sem problemas, vamos <b>criar uma novinha</b>!
        </h4>
      </S.ColumnStage1>
      <S.ColumnStage1 style={{ gap: 24 }}>
        <p>Escolha sua nova senha preenchendo os campos abaixo:</p>
        <S.ColumnStage3 style={{ gap: 16 }}>
          <Input
            autocomplete="off"
            label="E-mail"
            placeholder="E-mail"
            value={data?.email}
            icon={<MdOutlineMailOutline />}
            onChange={(e: any) =>
              setData(prev => ({ ...prev, email: e.target.value }))
            }
            error={error.email}
            onKeyDown={(e: any) => {
              if (e.key.includes('Enter')) {
                verify()
              }
            }}
          />
          <Input
            autocomplete="off"
            label="Código enviado"
            placeholder="Código enviado ao seu e-mail"
            value={data?.code}
            icon={<MdOutlineMailOutline />}
            onChange={(e: any) =>
              setData(prev => ({ ...prev, code: e.target.value }))
            }
            error={error.code}
            onKeyDown={(e: any) => {
              if (e.key.includes('Enter')) {
                verify()
              }
            }}
          />
          <Input
            autocomplete="off"
            label="Senha"
            placeholder="Senha"
            icon={<MdLockOutline />}
            value={data?.password}
            // isPasswordWithoutIcon={true}
            isPassword
            onChange={(e: any) =>
              setData(prev => ({ ...prev, password: e.target.value }))
            }
            error={error.password}
            onKeyDown={(e: any) => {
              if (e.key.includes('Enter')) {
                verify()
              }
            }}
          />
          <Input
            autocomplete="off"
            label="Confirmação de Senha"
            placeholder="Confirmar Senha"
            icon={<MdLockOutline />}
            value={data?.confirmation}
            isPassword
            // isPasswordWithoutIcon={true}
            onChange={(e: any) =>
              setData(prev => ({
                ...prev,
                confirmation: e.target.value
              }))
            }
            error={error.confirmation}
            onKeyDown={(e: any) => {
              if (e.key.includes('Enter')) {
                verify()
              }
            }}
          />
        </S.ColumnStage3>
      </S.ColumnStage1>
      <S.ButtonContainer>
        <MiddleButton
          onClick={() => {
            verify()
          }}
          text="Salvar"
          style={{ width: '100%' }}
        />
      </S.ButtonContainer>
    </>
  )
}

export default Stage2
