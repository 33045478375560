import React, { useState } from 'react'
import { TabsMap } from './statics'

import * as S from './styles'
interface TabsProps {
  active?: number
  setActive?: React.Dispatch<React.SetStateAction<number | any>> | any
  user: any
}

const Tabs: React.FC<TabsProps> = ({ active, setActive, user }) => {
  // profile variables
  const [loadedImage, setLoadedImage] = useState(false)

  return (
    <S.Container>
      <S.ClientContent>
        <section>
          <img
            src={
              (user?.profile_img as string) ||
              'https://t4.ftcdn.net/jpg/02/15/84/43/360_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg'
            }
            onLoad={() => {
              setLoadedImage(true)
            }}
          />
          <S.LoadImage loaded={loadedImage} />
        </section>
        <div>
          <span>
            É um prazer te ver por aqui!
            <h3>
              {active === 0
                ? 'Vamos continuar seu aprendizado?'
                : 'Que tal se apresentar?'}
            </h3>
          </span>

          {active === 0
            ? 'Confira seus cursos abaixo!'
            : 'Confira seus dados abaixo!'}
        </div>
      </S.ClientContent>

      {TabsMap.map((tab, i) => (
        <S.Content
          key={i}
          active={active === tab.id}
          onClick={() => setActive(tab.id)}
        >
          {tab.icon}
          <div>{tab.label}</div>
        </S.Content>
      ))}
    </S.Container>
  )
}

export default Tabs
