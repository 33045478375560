import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    max-width: 600px;

    > div {
      max-width: calc((100% - 16px) / 2);
      flex: none;

      @media (max-width: 358px) {
        max-width: 100%;
      }
    }

    > div:first-child {
      max-width: 100%;
    }
  }

  @media (max-width: 358px) {
    flex-direction: column;
  }
`

export const ClientContent = styled.div`
  display: flex;
  width: 100%;
  flex: 3;
  gap: 16px;
  align-items: center;
  background: var(--naval);
  padding: 12.5px 16px;
  border-radius: 10px;
  box-shadow: var(--shadow);
  position: relative;

  :after {
    content: '';
    position: absolute;
    border-radius: 10px;
    left: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    background: linear-gradient(
      81.94deg,
      rgba(54, 169, 225, 0.3) 0%,
      rgba(242, 183, 5, 0) 50.56%
    );
  }

  > section {
    border: 3px solid var(--primary);
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 2;
    position: relative;
    width: 56px;
    height: 56px;
    > img {
      height: 40px;
      object-fit: cover;
      border-radius: 100px;
      width: 100%;

      @media (max-width: 600px) {
        height: 30px;
      }
    }
    @media (max-width: 600px) {
      width: 46px;
      height: 46px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 2;
    justify-content: flex-end;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: var(--white);
    transition: 0.3s ease;

    > span {
      display: flex;
      flex-direction: column;
      height: 100%;
      color: var(--white);
      font-size: 10px;

      > h3 {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 411px) {
    flex-direction: column;
    text-align: center;
  }
  @media (max-width: 419px) {
    gap: 8px;
  }
`

interface LoadImageProps {
  loaded?: boolean
}

export const LoadImage = styled.section<LoadImageProps>`
  position: absolute;
  top: 5px;
  bottom: 0;
  right: 0;
  left: 5px;
  background: var(--primary);
  transition: 0.5s ease;
  opacity: ${props => (props.loaded ? 0 : 1)};
  border-radius: 32px;
  pointer-events: none;
  height: 40px;
  width: 40px;

  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`

interface ContentProps {
  active?: boolean
}

export const Content = styled.div<ContentProps>`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-direction: column;
  background-color: ${props =>
    props.active ? 'var(--primary)' : 'var(--naval)'};
  box-shadow: var(--shadow);
  padding: 12px 32px;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.4s ease;

  > div {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${props => (props.active ? 'var(--background)' : 'var(--deselect)')};
  }

  > svg {
    height: 20px;
    > path {
      stroke: ${props => (props.active ? 'white' : '#3c4042')};
    }

    > circle {
      stroke: ${props => (props.active ? 'white' : '#3c4042')};
    }
  }

  @media (max-width: 600px) {
    padding: 12px;
  }

  @media (max-width: 358px) {
    flex-direction: column;
  }
`
