import styled from 'styled-components'

interface ContainerProps {
  disabledOpacity?: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  position: relative;
  opacity: ${props => (props.disabledOpacity ? 0.5 : 1)};
`
export const InputLabel = styled.div`
  font-size: 13px;
  margin-bottom: 8px;
  color: var(--white);
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;

  > span {
    color: var(--red-alert);
  }

  > div > div {
    @media (max-width: 400px) {
      min-width: 150px;
    }
  }
`

export const InputLabelForGetDropdown = styled.div`
  font-size: 13px;
  margin-bottom: 8px;
  color: var(--white);
  display: flex;
  align-items: center;
  position: relative;

  > span {
    margin-left: 4px;
    color: var(--red-alert);
  }
`

interface InputProps {
  isLight?: boolean
  selected?: boolean
  disabled?: boolean
}

export const InputStyled = styled.input<InputProps>`
  min-width: 100%;
  height: 40px;
  padding: 0 20px;
  color: var(--white);
  font-size: 12px;
  font-weight: 400;
  transition: 0.2s ease;
  cursor: pointer ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  outline: 1px solid var(--inputs);
  border-radius: 4px;
  position: relative;
`

export const InputStyledV2 = styled.p<InputProps>`
  min-width: 100%;
  height: 40px;
  padding: 0 20px;
  color: var(--white);
  font-size: 12px;
  font-weight: 400;
  transition: 0.2s ease;
  cursor: pointer ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  outline: 1px solid var(--inputs);
  border-radius: 4px;
  position: relative;

  overflow-y: hidden;
`

export const Box = styled.div`
  min-width: 100%;
  height: 34px;
  padding: 0 20px;
  color: var(--white);
  font-size: 12px;
  font-weight: 300;
  transition: 0.2s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* background: var(--inputs); */
  border-radius: 4px;
  position: relative;
  gap: 8px;
`

export const Wrapper = styled.section`
  display: flex;
  gap: 8px;
  width: 40px;
  align-items: center;
  padding-right: 20px;
`
export const BoxV2 = styled.div`
  width: 100%;
  min-height: 34px;
  padding: 0 20px;
  color: var(--white);
  font-size: 12px;
  font-weight: 300;
  transition: 0.2s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* background: var(--inputs); */
  border-radius: 4px;
  position: relative;
  gap: 8px;
  flex-wrap: wrap;
`

export const ChipButton = styled.button`
  margin: 5px 0;
  font-size: 14px;
  padding: 5px 7px;
  border-radius: 4px;
  outline: 1px solid var(--background);
  color: var(--white);
  font-weight: 600;
  display: flex;
  gap: 5px;
  cursor: pointer;
  align-items: center;

  &:hover {
    transition: 0.3s ease;
    background: var(--background);
  }
`

export const ArrowSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 20px;
  cursor: pointer;
  color: var(--primary);
  background-color: transparent;

  > svg {
    font-size: 12px;
  }
`

interface ArrowSelectV2Props {
  isActive?: boolean
}
export const ArrowSelectV2 = styled.section<ArrowSelectV2Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  cursor: pointer;
  color: var(--primary);
  background-color: transparent;

  > svg {
    font-size: 12px;
  }
`

export const ClearSelect = styled.button`
  display: flex;
  align-items: center;
  /* right: 45px; */
  justify-content: center;
  z-index: 10;
  margin-left: -8px;
  color: var(--primary);
  background-color: transparent;
  cursor: pointer;

  > svg {
    font-size: 12px;
  }
`

export const WrapperButtons = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row-reverse;
  padding-right: 26px;
`
interface InputsContainerProps {
  isActive?: boolean
}

export const InputsContainer = styled.div<InputsContainerProps>`
  display: flex;
  position: relative;
  border-radius: 4px;
  margin-bottom: 0;
  cursor: pointer;
  transition: 0.2s ease;

  &:before {
    content: '';
    border-radius: 4px;
    height: 100%;
    width: 40px;
    background: linear-gradient(270deg, var(--70) 0%, rgba(14, 14, 18, 0) 100%);
    z-index: 1;
    display: flex;
    right: 30px;
    position: absolute;
    pointer-events: none;
  }
`
export const InputsContainerV2 = styled.div<InputsContainerProps>`
  display: flex;
  border-radius: 4px;
  transition: 0.2s ease;
  gap: 16px;
  max-width: 100%;
  outline: 1px solid var(--inputs);

  > div {
    padding: 0 0 0 20px;
  }
`

interface DropdownProps {
  active?: boolean
  isLight?: boolean
}

export const DropdownContainer = styled.div<DropdownProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  transition: 0.2s ease;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 5px;
  background-color: var(--background);
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  z-index: ${props => (props.active ? 100 : 0)};
  transform: ${props => (props.active ? '' : 'translateY(-10%)')};
  opacity: ${props => (props.active ? 1 : 0)};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--inputs);
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--inputs);
  }
`

export const DropdownItem = styled.div<InputProps>`
  padding: 15px;
  color: var(--white);
  transition: 0.2s ease;
  font-size: 12px;

  cursor: pointer;
  &:hover {
    background-color: var(--primary-opacity);
  }
`

export const DropdownItem2 = styled.div<InputProps>`
  padding: 15px;
  color: var(--white);
  transition: 0.2s ease;
  font-size: 12px;

  cursor: pointer;
  &:hover {
    background-color: var(--primary-opacity);
  }
`

export const SearchContainer = styled.form`
  display: flex;
  position: relative;
  align-items: center;
  padding: 15px;
  > svg {
    left: 24px;
    position: absolute;
    cursor: pointer;
  }
`

export const SearchBar = styled.input`
  padding: 10px;
  padding-left: 36px;
  border-radius: 3px;
  display: flex;
  flex: 1;
  color: var(--white);
  &:focus {
    outline: none;
  }
  border-bottom: 1px solid rgba(251, 255, 252, 0.25);
`
export const LoadWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  max-height: 50px;
  padding: 10px 0;
  > svg {
    border: white;
    stroke: white;
    height: 30px;
    width: 30px;
  }
`
