import React, { useEffect, useRef, useState } from 'react'

import * as S from './styles'

interface TooltipProps {
  label: any
  containerRef: React.RefObject<HTMLDivElement | null>
  styles?: any
}

const TooltipEllipses: React.FC<TooltipProps> = ({ label, containerRef }) => {
  const titleRef = useRef<HTMLHeadingElement>(null)
  const [isOverflowingStore, setIsOverflowingStore] = useState(false)

  useEffect(() => {
    const titleElement = titleRef.current
    const containerElement = containerRef.current
    if (titleElement && containerElement) {
      if (titleElement.offsetWidth >= containerElement.offsetWidth) {
        setIsOverflowingStore(true)
        titleElement.style.textOverflow = 'ellipsis'
        titleElement.style.display = 'block'
        titleElement.style.padding = '0 4px'
      } else {
        setIsOverflowingStore(false)
      }
    }
  }, [titleRef.current, containerRef.current])

  return (
    <S.TooltipContainer isOverflowing={isOverflowingStore}>
      <S.SliderTitleMain ref={titleRef} hasMargin={isOverflowingStore}>
        {label}
      </S.SliderTitleMain>
      {isOverflowingStore && (
        <S.SliderTitleTooltip>{label}</S.SliderTitleTooltip>
      )}
    </S.TooltipContainer>
  )
}

export default TooltipEllipses
