import styled from 'styled-components'

interface TooltipContainerProps {
  isOverflowing?: boolean
}

export const TooltipContainer = styled.div<TooltipContainerProps>`
  position: relative;

  ${props =>
    props.isOverflowing &&
    `:hover{
        > span {
          opacity: 1;
        }
      }
    `}
`

export const SliderTitleTooltip = styled.span`
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 4px 8px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 100;
  font-size: 11px;
  word-break: break-word;
  text-align: center;
`

interface TitleProps {
  hasMargin?: boolean
}

export const SliderTitleMain = styled.h3<TitleProps>`
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  max-width: fit-content;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`
