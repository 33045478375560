import styled from 'styled-components'

export const Container = styled.div`
  grid-area: HD;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  color: var(--white);
  height: 70px;
  background-color: var(--primary);
  padding: 10px 32px;

  @media (max-width: 1024px) {
    min-height: 70px;
  }

  @media (max-width: 600px) {
    padding: 10px 16px;
    gap: 8px;
  }
`

interface LeftContentProps {
  isSearching?: boolean
}

export const LeftContent = styled.div`
  display: flex;
  width: 100%;
  gap: 64px;
  align-items: center;

  @media (max-width: 600px) {
    gap: 16px;
  }
`

export const SearchIcon = styled.div<LeftContentProps>`
  display: ${props => (props.isSearching ? 'none' : 'flex')};
  align-items: center;

  @media (min-width: 1024px) {
    display: none;
  }
`

export const RightContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  @media (max-width: 1023px) {
    width: fit-content;
  }
`

export const Content = styled.div`
  display: flex;
  padding: 8px 16px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  gap: 8px;
  width: 100%;
  align-items: center;
  max-width: 186px;
  cursor: pointer;

  > div {
    display: flex;
    justify-content: center;
    gap: 8px;

    > img {
      height: 35px;
      width: 35px;
      object-fit: cover;
      border-radius: 40px;
    }

    > span {
      font-size: 11px;
      max-width: 80px;
      font-weight: 500;
      line-height: 14.3px;
      color: white;

      > span {
        font-size: 13px;
        color: white;
        font-weight: 600;
        line-height: 12.1px;
      }
    }

    &:first-child {
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 1023px) {
    padding: 16px;
    border-radius: 0;
    width: fit-content;
    height: 100%;
    display: none;
  }
`

interface LogoContainerProps {
  opened?: boolean
}

export const LogoContainer = styled.button<LogoContainerProps>`
  display: flex;
  align-items: center;
  padding: 16px 32px;
  transition: 0.3s ease;

  > a > svg {
    height: 32px;
    width: auto;
  }

  > div {
    display: none;
  }

  @media (max-width: 1023px) {
    padding: 0;
  }

  @media (max-width: 600px) {
    > a > svg {
      height: 28px;
    }
  }
`

export const SearchContainer = styled.div<LeftContentProps>`
  display: flex;
  width: 100%;
  position: relative;

  > div:first-child > div {
    border-radius: 4px;
    height: 40px;
    background-color: white;

    > input {
      color: var(--white);
      padding: 0 8px 0 18px;
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--white);
        opacity: 1; /* Firefox */
        font-weight: 300;
        font-size: 12px;
        line-height: 14.63px;
      }
    }

    > div {
      bottom: 10px;
      right: 6px;
    }

    @media (max-width: 1023px) {
      opacity: ${props => (props.isSearching ? '1' : '0')};
      transition: 0.3s ease;
    }
  }

  > div:last-child {
    padding: 4px;

    > a {
      font-size: 14px;
    }
  }
`

export const ToggleButton = styled.button`
  display: flex;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  color: var(--white);

  @media (min-width: 1024px) {
    display: none;
  }
`
export const LoadWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  max-height: 50px;
  padding: 10px 0;
  > svg {
    border: white;
    stroke: white;
    height: 30px;
    width: 30px;
  }
`
