import React from 'react'

import * as S from './styles'

export interface TooltipProps {
  content?: any
}

const Tooltip: React.FC<TooltipProps> = ({ children, content }) => {
  return (
    <S.TooltipCard>
      <S.TooltipText>{children}</S.TooltipText>
      <S.TooltipBox>{content}</S.TooltipBox>
    </S.TooltipCard>
  )
}

export default Tooltip
