import styled from 'styled-components'

interface ContainerProps {
  bg?: string
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-image: ${props => `url("${props.bg}")`};
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  background-size: cover;
`

export const Card = styled.div`
  display: flex;
  width: 100%;
  max-width: 980px;
  height: 100%;
  max-height: 365px;
  box-shadow: var(--shadow);
  border-radius: 10px;

  @media (max-width: 722px) {
    width: 100%;
    flex-direction: column;
    max-height: 578px;
  }
`

interface LeftContentProps {
  bg?: string
  isStage3?: number
}

export const LeftContent = styled.div<LeftContentProps>`
  width: 100%;
  height: 100%;
  background-image: ${props => `url("${props.bg}")`};
  background-size: cover;
  display: ${props => (props.isStage3 === 2 ? 'none' : 'flex')};
  background-repeat: no-repeat;
  border-radius: 10px 0 0 10px;
  padding: 24px 26px;
  flex-direction: column;
  justify-content: space-between;
  background-position: -32px;
  width: 100%;

  > h4 {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    max-width: 256px;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: white;
    height: fit-content;

    > h3 {
      color: #033b73;
      display: flex;
      gap: 4px;

      > p {
        color: white;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;

        @media (max-width: 722px) {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }

    @media (max-width: 722px) {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }

  > img {
    height: 20px;
    object-fit: cover;
    width: 70px;

    @media (max-width: 722px) {
      display: none;
    }
  }

  @media (max-width: 790px) {
    width: 80%;
    background-position: center;
  }

  @media (max-width: 722px) {
    width: 100%;
    background-size: cover;
    border-radius: 10px 10px 0 0;
  }
`

export const RightContent = styled.div<LeftContentProps>`
  padding: 32px 45px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--background);
  border-radius: ${props => (props.isStage3 === 2 ? '10px' : ' 0 10px 10px 0')};
  gap: 32px;
  align-items: center;

  > button {
    width: 100%;
    max-width: 100% !important;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
  }

  @media (max-width: 1024px) {
    padding: 26px 24px;
    gap: 24px;
  }

  @media (max-width: 722px) {
    border-radius: 0 0 10px 10px;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  align-items: center;

  > h3 {
    font-weight: 500;
    font-size: 24px;
    color: var(--white);
    line-height: 29px;

    > b {
      color: var(--primary);
      font-weight: 700;
    }

    @media (max-width: 722px) {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
  }

  > h4 {
    font-weight: 500;
    font-size: 16px;
    color: var(--white);
    line-height: 19.5px;

    > b {
      color: var(--primary);
      font-weight: 700;
    }
    @media (max-width: 722px) {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
    }

    @media (max-width: 375px) {
      font-size: 12px;
    }
  }
`

export const KeepConnected = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 4px;

  > button {
    > a {
      color: var(--primary);
      font-size: 12px;
      font-weight: 300;
    }
  }

  > div {
    align-items: flex-end;

    > span {
      color: var(--white);
      font-weight: 300;

      @media (max-width: 300px) {
        font-size: 11px;
      }
    }
  }

  @media (max-width: 1024px) {
    align-items: flex-start;
    gap: 8px;
  }

  @media (max-width: 410px) {
    flex-direction: column;
    gap: 16px;
  }
`
