import styled from 'styled-components'

interface ContainerProps {
  disabled?: boolean
  seccondary?: boolean
  bigButton?: boolean
  bgColorSeccondary?: boolean
}

export const Container = styled.button<ContainerProps>`
  display: flex;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  justify-content: center;
  align-items: center;
  height: 30px;
  width: ${props => (props.bigButton ? '100%' : 'fit-content')};
  padding: ${props => (props.bigButton ? '25px 32px' : '0 32px')};
  border-radius: 4px;
  background: ${props => (props.seccondary ? 'transparent' : 'var(--primary)')};
  background: ${props => (props.bgColorSeccondary ? 'white' : '')};
  outline: ${props => (props.seccondary ? '1px solid var(--primary)' : 'none')};
  outline: ${props => (props.bgColorSeccondary ? 'none' : '')};
  transition: 0.2s ease;
  cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
  color: ${props =>
    props.bgColorSeccondary ? 'var(--primary)' : 'var(--background)'};
  color: ${props => (props.seccondary ? 'var(--primary)' : '')};
  font-weight: 600;
  font-size: 13px;
  text-decoration: none;

  :hover {
    background: ${props =>
      props.disabled ? 'var(--disabled)' : 'var(--primary)'};
    outline: ${props => (props.seccondary ? 'none' : '')};
    background: ${props =>
      props.seccondary ? 'var(--seccondary)' : 'var(--seccondary)'};
    color: ${props => (props.seccondary ? 'var(--white)' : '')};
    transition: 0.3s ease;
    color: ${props =>
      props.bgColorSeccondary ? 'var(--select) !important' : ''};

    > svg {
      fill: ${props =>
        props.seccondary ? 'var(--white)' : 'var(--background)'};
    }
  }

  > svg {
    margin-left: 8px;
    fill: ${props =>
      props.seccondary ? 'var(--primary)' : 'var(--background)'};
    fill: ${props => (props.bgColorSeccondary ? 'var(--primary)' : '')};
  }
`

export const ContainerLink = styled.section<ContainerProps>`
  display: flex;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  justify-content: center;
  align-items: center;
  width: ${props => (props.bigButton ? '100%' : 'fit-content')};
  border-radius: 4px;
  background: ${props => (props.seccondary ? 'transparent' : 'var(--primary)')};
  background: ${props => (props.bgColorSeccondary ? 'white' : '')};
  outline: ${props => (props.seccondary ? '1px solid var(--primary)' : 'none')};
  outline: ${props => (props.bgColorSeccondary ? 'none' : '')};
  transition: 0.2s ease;
  cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
  color: ${props => (props.bgColorSeccondary ? 'var(--primary)' : '')};
  color: var(--background);
  font-weight: 600;
  font-size: 13px;
  text-decoration: none;
  height: fit-content;

  > a {
    color: ${props =>
      props.seccondary ? 'var(--primary)' : 'var(--background)'};
    font-weight: 600;
    font-size: 13px;
    text-decoration: none;
    padding: ${props => (props.bigButton ? '25px 32px' : '0 32px')};
    height: 30px;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      color: var(--background);
      transition: 0.3s ease;
    }
  }

  :hover {
    background: ${props =>
      props.disabled ? 'var(--disabled)' : 'var(--primary)'};
    outline: ${props => (props.seccondary ? 'none' : '')};
    background: ${props =>
      props.seccondary ? 'var(--primary-hover)' : 'var(--primary-hover)'};
    color: ${props => (props.seccondary ? 'var(--white)' : '')};
    transition: 0.3s ease;
    color: ${props =>
      props.bgColorSeccondary ? 'var(--select) !important' : ''};
  }

  > svg {
    margin-left: 12px;
    fill: ${props =>
      props.seccondary ? 'var(--primary)' : 'var(--background)'};
  }
`
