import React from 'react'
import { UserProps } from '../../../../../contexts/UserContext'
import { emailRegEx } from '../../../../../lib/regEx'
import { SubmitData } from '..'

export type UserErrorProps = {
  // eslint-disable-next-line no-unused-vars
  [P in keyof UserProps]?: string
}

export type ChangeUserErrorProps = Partial<UserProps>

export const handleVerify = (
  data: Partial<UserProps>,
  error: UserErrorProps,
  setError: React.Dispatch<React.SetStateAction<UserErrorProps>>
): boolean => {
  const newErr = { ...error }

  if (!data.email) {
    setError({ ...newErr, email: 'E-mail obrigtório' })
    return false
  }
  newErr.email = undefined

  if (!emailRegEx.test(data.email)) {
    setError({ ...newErr, email: 'Insira um e-mail válido' })
    return false
  }
  newErr.email = undefined

  setError(newErr)

  return true
}

export interface ResetFormData {
  code: string
  password: string
  confirmPassword: string
}

export type ResetFormDataErrors = {
  // eslint-disable-next-line no-unused-vars
  [P in keyof ResetFormData]?: string
}

export type ChangeResetFormData = Partial<ResetFormData>

export const handleVerifyResetPasswordData = (
  data: Partial<SubmitData>,
  error: SubmitData,
  setError: React.Dispatch<React.SetStateAction<SubmitData>>
): boolean => {
  const newErr = { ...error }
  if (!data.email) {
    setError({ ...newErr, email: 'E-mail obrigatório' })
    return false
  }
  newErr.email = undefined

  if (!emailRegEx.test(data.email)) {
    setError({ ...newErr, email: 'Insira um e-mail válido' })
    return false
  }
  newErr.email = undefined

  if (!data.code) {
    setError({ ...newErr, code: 'Código é obrigatório' })
    return false
  }
  newErr.code = undefined

  if (!data.password) {
    setError({ ...newErr, password: 'Nova senha é obrigatória' })
    return false
  }
  newErr.password = undefined

  if (data?.password && data?.password.length < 6) {
    setError({ ...newErr, password: 'A senha deve ter 6 caracteres' })
    return false
  }
  newErr.password = undefined

  if (!data.confirmation) {
    setError({
      ...newErr,
      confirmation: 'Confirmação de senha é obrigatória'
    })
    return false
  }
  newErr.confirmation = undefined

  if (data.password !== data.confirmation) {
    setError({ ...newErr, confirmation: 'As senhas não conferem' })
    return false
  }
  newErr.confirmation = undefined

  setError(newErr)

  return true
}
