/* eslint-disable no-lone-blocks */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import * as S from './styles'
import Slider from 'react-slick'
// import { CourseMap } from '../statics'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { iEnrollment } from '../../../../lib/models'
import EmptySection from '../../../../components/EmptySection'
import { FaBookOpen } from 'react-icons/fa'
import Element from './element'

interface Props {
  enrollments: iEnrollment[]

  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
}

const MyContent: React.FC<Props> = ({
  enrollments,

  isLoading
}) => {
  const sliderSettings = {
    dots: false,
    arrows: false,
    speed: 500,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 666,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }
  const [sliderRef, setSliderRef] = useState<any>(null)

  return (
    <S.Card>
      <S.HeaderSection>
        <h1>Seus cursos</h1>
        <S.Pagination itemsCount={enrollments.length}>
          <MdChevronLeft onClick={sliderRef?.slickPrev} />
          <MdChevronRight onClick={sliderRef?.slickNext} />
        </S.Pagination>
      </S.HeaderSection>

      <S.SliderContainer itemsCount={enrollments.length}>
        {isLoading ? (
          <S.Loading height={362} />
        ) : enrollments && enrollments.length <= 0 ? (
          <EmptySection
            text="Parece que você ainda não tem nenhum curso disponível na sua área de membros. "
            title="Ainda não há cursos disponíveis"
            icon={<FaBookOpen size={28} color="var(--primary)" />}
            style={{ background: 'transparent', boxShadow: 'none' }}
          />
        ) : enrollments.length <= 5 ? (
          <>
            {enrollments.map((enrollment, i) => (
              <Element
                data={enrollment}
                key={i}
                isMinWidth
                itemsCount={enrollments.length}
              />
            ))}
          </>
        ) : (
          <Slider ref={setSliderRef} {...sliderSettings}>
            {enrollments.map((enrollment, i) => (
              <Element
                data={enrollment}
                key={i}
                itemsCount={enrollments.length}
              />
            ))}
          </Slider>
        )}
      </S.SliderContainer>
    </S.Card>
  )
}

export default MyContent
