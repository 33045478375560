/* eslint-disable indent */
import React from 'react'
import Background from '../../../../assets/images/MembersArea/backgroundMembersArea.png'
import { Container, RightContent } from '../Login/styles'
import Stage1 from './Stages/Stage1'
import Stage2 from './Stages/Stage2'
import Stage3 from './Stages/Stage3'
import CardBackground2 from '../../../../assets/images/MembersArea/cardBackground2.png'
import Keed from '../../../../assets/images/logo.svg'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ReactComponent as Arrow } from '../../../../assets/images/MembersArea/arrow.svg'
import * as S from './styles'
import { useGeneral } from '../../../../contexts/GeneralContext'
import { verifyCodePassword } from '../../../../lib/api'

export interface SubmitData {
  email?: string
  code?: string
  password?: string
  confirmation?: string
  type?: string
}

const ForgotPasswordMembersArea: React.FC = () => {
  const { stage, setStage, alertError, setIsLoading } = useGeneral()
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const [data, setData] = React.useState<SubmitData>({})

  const OnLoad = async () => {
    setIsLoading(true)
    const code = searchParams.get('code')
    const email = searchParams.get('email')

    if (code && email) {
      try {
        await verifyCodePassword({ code: code, email: email, type: 'STUDENT' })
      } catch (err: any) {
        alertError(err?.response?.data || err)
        setData({})
      }
    }

    if (code || email) {
      setData(prev => ({
        ...prev,
        // eslint-disable-next-line no-unneeded-ternary
        code: code ? code : '',
        // eslint-disable-next-line no-unneeded-ternary
        email: email ? email : ''
      }))
      setStage(1)
    }

    setIsLoading(false)
  }

  React.useEffect(() => {
    OnLoad()
  }, [])

  return (
    <Container bg={Background}>
      <S.Card height={stage} isStage3={stage}>
        <S.LeftContent bg={CardBackground2} isStage3={stage}>
          <h4>
            Redefinição
            <h3>
              <p style={{ paddingRight: 2 }}>de</p> senha
            </h3>
          </h4>
          <img src={Keed} />
        </S.LeftContent>
        <RightContent
          style={{ position: 'relative', justifyContent: 'center' }}
          isStage3={stage}
        >
          {stage === 0 && (
            <Stage1
              setStage={setStage}
              data={data}
              setData={setData}
              setSearchParams={setSearchParams}
            />
          )}
          {stage === 1 && (
            <Stage2 setStage={setStage} data={data} setData={setData} />
          )}
          {stage === 2 && <Stage3 />}
          {stage === 0 && (
            <S.BackButton
              onClick={() => {
                setStage((prev: any) => (prev > 0 ? prev - 1 : 0))
                navigate('/members-area/login')
              }}
            >
              <Arrow />
              Login
            </S.BackButton>
          )}
          {stage === 1 && (
            <S.BackButton
              onClick={() => {
                setStage((prev: any) => prev - 1)
              }}
            >
              <Arrow />
              Voltar
            </S.BackButton>
          )}
        </RightContent>
      </S.Card>
    </Container>
  )
}

export default ForgotPasswordMembersArea
