/* eslint-disable @typescript-eslint/no-empty-function */
import { CgClose } from 'react-icons/cg'
import * as S from './styles'
import React from 'react'
import { MiddleButton } from '../../Button'
import Input from '../../Input'

interface ModalProps {
  showModal: boolean
  setShowModal: any
}

const ModalDeleteAccount: React.FC<ModalProps> = props => {
  return (
    <>
      {props.showModal ? (
        <S.ModalContainer>
          <S.ModalWrapper>
            <S.CloseModal onClick={() => props.setShowModal(false)}>
              <CgClose size={10} color="var(--primary)" />
            </S.CloseModal>
            <S.Title>Confirmação</S.Title>
            <S.Row>
              <p>
                Ao encerrar sua conta KeedClass, você <b>perderá</b> o acesso a
                todos os seus cursos. <br /> Para encerrar sua conta, confirme
                sua senha abaixo:
              </p>
              <Input
                label={'Confirme sua senha'}
                placeholder="Digite sua senha"
                isPassword
              />
            </S.Row>
            <S.ButtonContainer style={{ paddingTop: 24 }}>
              <MiddleButton
                text="Cancelar"
                style={{ maxWidth: 125, width: '100%' }}
                onClick={() => props.setShowModal(false)}
                bgColorSeccondary
              />
              <MiddleButton
                text="Confirmar"
                style={{ maxWidth: 125, width: '100%' }}
                onClick={() => props.setShowModal(false)}
              />
            </S.ButtonContainer>
          </S.ModalWrapper>
        </S.ModalContainer>
      ) : null}
    </>
  )
}

export default ModalDeleteAccount
