import { ReactComponent as Content } from '../../../../assets/icons/ClientsMembersArea/content.svg'
import { ReactComponent as Profile } from '../../../../assets/icons/ClientsMembersArea/myProfile.svg'

export const TabsMap = [
  {
    label: 'Meu Conteúdo',
    icon: <Content />,
    id: 0
  },
  {
    label: 'Meu Perfil',
    icon: <Profile />,
    id: 1
  }
]
