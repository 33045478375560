import React, { useContext, useState, createContext } from 'react'
import { iClass, iMaterial } from '../lib/models'
import { getClass, getHolderName } from '../lib/api'
import { useGeneral } from './GeneralContext'
import { useParams } from 'react-router-dom'

interface ContextProps {
  classData: iClass | undefined
  setClassData: React.Dispatch<React.SetStateAction<iClass | undefined>>
  currentSectionIndex: number
  setCurrentSectionIndex: React.Dispatch<React.SetStateAction<number>>
  currentMaterialIndex: number
  setCurrentMaterialIndex: React.Dispatch<React.SetStateAction<number>>
  getCurrentMaterial: () => iMaterial | null
  handleGetClass: (setProgress?: boolean) => Promise<void>
  holderName: string | undefined
  setHolderName: React.Dispatch<React.SetStateAction<string | undefined>>
  courseProgress: number
  setCourseProgress: React.Dispatch<React.SetStateAction<number>>
}

export const CourseContext = createContext<ContextProps>({} as ContextProps)

export const CourseProvider: React.FC = ({ children }: any) => {
  const [classData, setClassData] = useState<iClass>()
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(-1)
  const [currentMaterialIndex, setCurrentMaterialIndex] = useState<number>(-1)
  const [holderName, setHolderName] = useState<string>()
  const [courseProgress, setCourseProgress] = useState<number>(0)
  const { alertError } = useGeneral()
  const { id } = useParams()

  const getCurrentMaterial = () => {
    if (
      classData === undefined ||
      currentSectionIndex === -1 ||
      currentMaterialIndex === -1
    ) {
      return null
    }
    return classData?.sections[currentSectionIndex]?.materials[
      currentMaterialIndex
    ]
  }

  const handleGetHolderName = async (id: string) => {
    try {
      const response = await getHolderName(id)
      setHolderName(response?.data?.body?.name)
    } catch (err) {}
  }

  const handleGetClass = async (setProgress?: boolean) => {
    if (id) {
      try {
        const response = await getClass(id)

        if (response?.progress) {
          setCourseProgress(response.progress)
        }

        if (
          currentMaterialIndex === -1 &&
          currentSectionIndex === -1 &&
          response.sections &&
          response.sections.length > 0 &&
          !setProgress
        ) {
          setCurrentSectionIndex(0)
          if (
            response.sections[0].materials &&
            response.sections[0].materials.length > 0
          ) {
            setCurrentMaterialIndex(0)
          }
        }

        if (!setProgress) {
          setClassData(response)
        }
        if (response?.course?.holder_id) {
          handleGetHolderName(response.course.holder_id)
        }
      } catch (err: any) {
        alertError(err?.response?.data || err?.data || err)
      }
    }
  }

  return (
    <CourseContext.Provider
      value={{
        classData,
        currentMaterialIndex,
        currentSectionIndex,
        setClassData,
        setCurrentMaterialIndex,
        setCurrentSectionIndex,
        getCurrentMaterial,
        handleGetClass,
        holderName,
        setHolderName,
        courseProgress,
        setCourseProgress
      }}
    >
      {children}
    </CourseContext.Provider>
  )
}

export const useCourse = (): ContextProps => useContext(CourseContext)
