import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media (max-width: 1024px) {
    max-width: 600px;
    align-items: center;
  }
`

export const Card = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
  flex-direction: column;
  padding: 24px 26px;
  background-color: var(--naval);
  border-radius: 10px;
  align-items: flex-end;

  > button {
    height: 40px;
    font-size: 14px;
    font-weight: 600;
  }

  @media (max-width: 1024px) {
    align-items: center;

    > button {
      max-width: 100% !important;
    }
  }
`

export const CardHeader = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;

  > div {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: var(--white);

    > h1 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }

    > span {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }

  > svg {
    margin-top: 5px;
    height: 19px;
    > path {
      stroke: var(--primary);
    }
  }
`

export const CardHeaderDeleteAccount = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: space-between;

  > div {
    display: flex;
    width: 100%;
    gap: 8px;

    > div {
      display: flex;
      width: 100%;
      flex-direction: column;
      color: var(--white);

      > h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
      }

      > span {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  > svg {
    margin-top: 5px;
    height: 19px;
    > path {
      stroke: var(--primary);
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 24px;

    > section {
      width: 100%;

      > button {
        max-width: 100%;
        width: 100%;
      }
    }
  }
`

export const Content = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`

export const UploadContainer = styled.section`
  width: 192px;
  > div {
    gap: 8px;
    justify-content: flex-end;
    > div {
      padding: 6px !important;
      height: 200px !important;
    }

    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      margin-bottom: 16px;

      > span {
        text-align: center;
      }
    }
  }
`

export const Row = styled.section`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;

  > div {
    max-width: calc((100% - 16px) / 2);
    height: fit-content;
  }

  > div > div > input {
    color: var(--white);
  }

  > div:nth-child(2) > div > input {
    color: var(--text);
  }

  > div > div:last-child > p {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    > div {
      max-width: 100%;
    }
  }
`

export const Column = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const ButtonContainer = styled.section`
  display: flex;

  > button {
    background-color: var(--red-alert);
    white-space: nowrap;
    height: 40px;
    max-width: 150px;
    font-size: 14px;
    font-weight: 600;

    @media (max-width: 600px) {
      width: 100%;
    }

    &:hover {
      background-color: var(--red-alert-hover);
    }
  }
`
