/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react'

import {
  Container,
  InputLabel,
  InputStyled,
  Box,
  ChipButton,
  ArrowSelect,
  ClearSelect,
  InputsContainer,
  DropdownContainer,
  DropdownItem,
  SearchContainer,
  SearchBar,
  InputStyledV2
} from './styles'

import { FiChevronDown, FiX } from 'react-icons/fi'
import { BiSearch, BiXCircle } from 'react-icons/bi'
import { Error } from '../Input/styles'

interface DropdownProps {
  id: number
  label?: any
  changeKey: string
  editable?: boolean
  changeFunction: (key: string, value: string) => void
  items: {
    value: any
    label: string
    selected?: boolean
  }[]
  isSearchable?: boolean
  isTitle?: boolean
  disabled?: boolean
  styles?: any
  stylesInput?: any
  phWidth?: number
  error?: string
  active?: boolean
  isActive?: number
  hasPlaceholder?: string
  changeFunctionV2: (key: string, value: any, label?: any, newArr?: any) => void
}

const Dropdown: React.FC<DropdownProps> = props => {
  const [value, setValue] = useState(0)
  const [active, setActive] = useState(false)
  const [items, setItems] = useState(props.items)

  const handleSearch = (e: string) => {
    const normalizer = (text: string) => {
      return text
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    }
    const newItems = props.items.filter(value => {
      return normalizer(value.label).includes(normalizer(e))
    })
    setItems(newItems)
  }

  const ref = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setActive(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    if (!value) {
      const index = props.items.findIndex(i => i.selected)
      if (index >= 0) {
        setValue(index)
        setActive(false)
      }
    }
  }, [props.items])

  return (
    <Container>
      {props.label ? (
        <InputLabel>{props.label}</InputLabel>
      ) : (
        <ArrowSelect
          style={{ right: 8 }}
          onClick={() => {
            setActive(true)
          }}
        >
          <FiChevronDown size={20} />
        </ArrowSelect>
      )}
      <InputsContainer ref={ref} isActive={active} style={props.styles}>
        <InputStyled
          style={props.stylesInput}
          spellCheck="false"
          placeholder="Selecione uma opção"
          value={props.items[value].label}
          disabled={props.disabled}
          type="text"
          onChange={() => null}
          //  Ao clicar, exibe o modal
          onFocus={() => {
            setActive(true)
          }}
        />
        <ArrowSelect
          // isTitle={props.isTitle}
          onClick={() => {
            setActive(true)
          }}
        >
          <FiChevronDown />
        </ArrowSelect>
        <DropdownContainer active={active}>
          <div>
            {props.isSearchable && (
              <SearchContainer
                autoComplete="off"
                style={items.length > 0 ? { paddingBottom: 0 } : {}}
              >
                <BiSearch color="var(--primary)" />
                <SearchBar
                  type="text"
                  autoComplete="false"
                  onChange={e => handleSearch(e.currentTarget.value)}
                />
              </SearchContainer>
            )}
            {items.map((item, i) => (
              <DropdownItem
                key={i}
                onClick={() => {
                  setValue(i)
                  setActive(false)
                  props.changeFunction &&
                    props.changeFunction(props.changeKey, item.value)
                }}
              >
                {item.label}
              </DropdownItem>
            ))}
          </div>
        </DropdownContainer>
      </InputsContainer>
      {props.error && typeof props.error !== 'number' && (
        <Error>{props.error}</Error>
      )}
    </Container>
  )
}

export default Dropdown

export const DropdownV2: React.FC<DropdownProps> = props => {
  const [value, setValue] = useState<number | undefined>()
  const [active, setActive] = useState(false)
  const [items, setItems] = useState(props.items)
  const [selectedItem, setSelectedItem] = useState<
    | {
        value: any
        label: string
        selected?: boolean
      }
    | undefined
  >(undefined)

  useEffect(() => {
    setItems(props.items)
  }, [props.items])

  const handleSearch = (e: string) => {
    const normalizer = (text: string) => {
      return text
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    }
    const newItems = props.items.filter(value => {
      return normalizer(value.label).includes(normalizer(e))
    })
    setItems(newItems)
  }

  const ref = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setActive(false)
    }
  }

  const firstLoad = useRef(true)

  const ref2 = useRef(null)

  useEffect(() => {
    firstLoad.current = true
  }, [ref2.current])

  useEffect(() => {
    if (!value) {
      const index = props.items.findIndex(i => i.selected)
      if (index >= 0) {
        setValue(index)
        setActive(false)
      }
    }
    if (
      selectedItem &&
      !firstLoad.current &&
      !items.find((el: any) => el?.selected)
    ) {
      setValue(undefined)
      // setActive(true)
      setSelectedItem(undefined)
    }

    firstLoad.current = false
  }, [props.items])

  useEffect(() => {
    items.forEach(item => {
      if (item.selected) {
        setSelectedItem(item)
      }
    })
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [items])

  return (
    <Container ref={ref}>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <InputsContainer ref={ref} isActive={active} style={props.styles}>
        <InputStyledV2
          style={props.stylesInput}
          // value={value ? props.items[value].label : 'Selecione uma opção'}
          disabled={props.disabled}
          onChange={() => null}
          //  Ao clicar, exibe o modal
          onFocus={() => {
            setActive(true)
          }}
          selected={selectedItem !== undefined}
          onClick={() => {
            if (!props.disabled) {
              setActive(!active)
            }
          }}
        >
          {selectedItem
            ? selectedItem.label
            : props.hasPlaceholder
            ? props.hasPlaceholder
            : 'Selecione uma opção'}
        </InputStyledV2>

        {!props.disabled && (
          <ArrowSelect
            // isTitle={props.isTitle}
            onClick={() => {
              setActive(!active)
            }}
          >
            <FiChevronDown />
          </ArrowSelect>
        )}
        <DropdownContainer active={active}>
          <div>
            {props.isSearchable && (
              <SearchContainer
                autoComplete="off"
                style={items.length > 0 ? { paddingBottom: 0 } : {}}
              >
                <BiSearch color="var(--primary)" />
                <SearchBar
                  type="text"
                  autoComplete="false"
                  onChange={e => {
                    e.preventDefault()
                    handleSearch(e.currentTarget.value)
                  }}
                  onKeyDown={e => {
                    if (e.code.includes('Enter')) {
                      e.preventDefault()
                    }
                  }}
                />
              </SearchContainer>
            )}
            {items.map((item, i) => (
              <DropdownItem
                key={i}
                onClick={() => {
                  setSelectedItem(item)
                  if (props.isSearchable) {
                    setValue(
                      props.items.findIndex(index => index.value === item.value)
                    )
                    setActive(false)
                    props.changeFunctionV2 &&
                      props.changeFunctionV2(
                        props.changeKey,
                        item.value,
                        item.label
                      )
                  } else {
                    setValue(i)
                    setActive(false)
                    props.changeFunctionV2 &&
                      props.changeFunctionV2(
                        props.changeKey,
                        item.value,
                        item.label
                      )
                  }
                }}
              >
                {item.label}
              </DropdownItem>
            ))}
          </div>
        </DropdownContainer>
      </InputsContainer>
      {props.error && typeof props.error !== 'number' && (
        <Error>{props.error}</Error>
      )}
    </Container>
  )
}

export const DropdownMultiple: React.FC<DropdownProps> = props => {
  const [active, setActive] = useState(false)
  const [items, setItems] = useState(props.items)
  const [arrayValue, setArrayValue] = useState<{ value: any; label: string }[]>(
    []
  )

  const handleTextValue = (arr: any[], item: any) => {
    const newArray = items.filter(
      value => value.label !== item.value && value.label !== item.label
    )
    setItems(newArray)
  }

  const handleClearSelect = () => {
    setArrayValue([])
    setItems(props.items)
  }

  const handleDeleteItem = (item: any, index: number) => {
    arrayValue.splice(index, 1)
    setItems([...items, item])
  }

  const handleSearch = (e: string) => {
    const normalizer = (text: string) => {
      return text
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    }
    const newItems = props.items.filter(value => {
      return normalizer(value.label).includes(normalizer(e))
    })
    setItems(newItems)
  }

  const ref = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setActive(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    const index = props.items.findIndex(i => i.selected)
    if (index >= 0) {
      setActive(false)
    }
  }, [props.items])

  return (
    <Container>
      {props.label ? (
        <InputLabel>{props.label}</InputLabel>
      ) : (
        <ArrowSelect
          style={{ right: 8 }}
          onClick={() => {
            setActive(true)
          }}
        >
          <FiChevronDown size={20} />
        </ArrowSelect>
      )}
      <InputsContainer ref={ref} isActive={active} style={props.styles}>
        <Box
          style={props.stylesInput}
          spellCheck="false"
          //  Ao clicar, exibe o modal
          onClick={() => {
            setActive(true)
          }}
        >
          {arrayValue.length
            ? arrayValue.map((item, i) => (
                <ChipButton key={i} onClick={() => handleDeleteItem(item, i)}>
                  {item.label}
                  <BiXCircle size={15} color={'var(--primary)'} />
                </ChipButton>
              ))
            : 'Selecione uma opção'}
        </Box>
        <ClearSelect onClick={() => handleClearSelect()}>
          <FiX />
        </ClearSelect>
        <ArrowSelect
          // isTitle={props.isTitle}
          onClick={() => {
            setActive(true)
          }}
        >
          <FiChevronDown />
        </ArrowSelect>
        <DropdownContainer active={active}>
          <div>
            {props.isSearchable && (
              <SearchContainer
                autoComplete="off"
                style={items.length > 0 ? { paddingBottom: 0 } : {}}
              >
                <BiSearch color="var(--primary)" />
                <SearchBar
                  type="text"
                  autoComplete="false"
                  onChange={e => handleSearch(e.currentTarget.value)}
                />
              </SearchContainer>
            )}
            {items.map((item, i) => (
              <DropdownItem
                key={i}
                onClick={() => {
                  setActive(false)
                  const newArr = [...arrayValue, item]
                  setArrayValue(newArr)
                  props.changeFunction &&
                    props.changeFunction(props.changeKey, item.value)
                  handleTextValue(newArr, item)
                }}
              >
                {item.label}
              </DropdownItem>
            ))}
          </div>
        </DropdownContainer>
      </InputsContainer>
    </Container>
  )
}
