import React, { useEffect, useState } from 'react'
import FrameMembersArea from '../Frame'
import MyContent from './MyContent'
import ProfilePage from './ProfilePage'
import * as S from './styles'
import Tabs from './Tabs'
import { useGeneral } from '../../../contexts/GeneralContext'
import { IUpdateProfile } from './ProfilePage/utils'
import { getEnrollments, getProfile } from '../../../lib/api'
import { formatDateV2, phoneFormatter } from '../../../lib/helpers'
import { useUser } from '../../../contexts/UserContext'
import { iEnrollment } from '../../../lib/models'

const HomeMembersArea: React.FC = () => {
  const { active, setActive, setIsLoading, showAlert, alertError } =
    useGeneral()
  const [userProfile, setUserProfile] = useState<IUpdateProfile>()
  const [preImage, setPreImage] = useState<string>(
    'https://t4.ftcdn.net/jpg/02/15/84/43/360_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg'
  )
  const { user } = useUser()
  const [initialUserProfile, setInitialUserProfile] = useState<IUpdateProfile>()
  const [enrollments, setEnrollments] = useState<iEnrollment[]>([])
  const [isLoadingEnrrolments, setIsLoadingEnrrolments] = useState(true)

  const handleGetData = async () => {
    setIsLoading(true)
    try {
      const response = await getProfile()
      setUserProfile(response as unknown as IUpdateProfile)
      response?.profile_img && setPreImage(response.profile_img)
      if (response?.birthdate) {
        setUserProfile(prevProfile => ({
          ...prevProfile,
          birthdate: formatDateV2(response.birthdate)
        }))
      }
    } catch (err) {
      showAlert(
        'error',
        'Falha ao carregar dados',
        'Verifique com administrador do serviço!'
      )
    }
    setIsLoading(false)
  }

  const allEnrollments = async () => {
    setIsLoadingEnrrolments(true)
    try {
      const response = await getEnrollments()
      setEnrollments(response)
    } catch (err: any) {
      alertError(err?.response?.data || err?.data || err)
    }
    setIsLoadingEnrrolments(false)
  }

  useEffect(() => {
    handleGetData()
    allEnrollments()

    if (user) {
      const obj = user
      if (obj?.document) {
        const doc = obj.document.replace(/[^0-9]+/g, '')
        obj.document = `${doc.substring(0, 3)}.${doc.substring(
          3,
          6
        )}.${doc.substring(6, 9)}-${doc.substring(9, 11)}`
      }
      if (obj?.phone) {
        obj.phone = phoneFormatter(obj.phone)
      }
      setUserProfile(obj)
      setInitialUserProfile(obj)
    }
  }, [])

  return (
    <FrameMembersArea setPage={setActive}>
      <S.Container>
        <Tabs active={active} setActive={setActive} user={user} />
        {active === 0 && (
          <MyContent
            enrollments={enrollments}
            isLoading={isLoadingEnrrolments}
            setIsLoading={setIsLoadingEnrrolments}
          />
        )}
        {active === 1 && (
          <ProfilePage
            getProfile={handleGetData}
            initialUserProfile={initialUserProfile}
            preImage={preImage}
            setPreImage={setPreImage}
            setUserProfile={setUserProfile}
            userProfile={userProfile}
          />
        )}
      </S.Container>
    </FrameMembersArea>
  )
}

export default HomeMembersArea
