import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  align-items: center;
  padding-bottom: 80px;

  @media (max-width: 768px) {
    gap: 16px;
  }
`
interface Props {
  primary_color?: string
  hex?: string
}
export const ClientContent = styled.div<Props>`
  display: flex;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  background: var(--naval);
  padding: 14px 16px;
  border-radius: 10px;
  box-shadow: var(--shadow);
  position: relative;
  max-width: 980px;
  margin-bottom: -49px;

  > section {
    display: flex;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 2;

    > button {
      display: flex;
      gap: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--white);
      align-items: center;
      cursor: pointer;

      > p {
        color: var(--white);
        font-weight: 600;
        font-size: 14px;
        line-height: 17.07px;
      }

      > svg {
        margin-right: 8px;
        * {
          stroke: ${p =>
            p?.primary_color ? p.primary_color : 'var(--primary)'};
        }
      }

      &:hover {
        transition: 0.3s ease;
        opacity: 0.8;
      }

      @media (max-width: 1063px) {
        font-size: 10px;

        > p {
          font-size: 10px;
        }
      }
    }

    @media (max-width: 735px) {
      display: none;
    }
  }

  @media (max-width: 600px) {
    margin-bottom: -103px;
  }
`

interface LoadImageProps {
  loaded?: boolean
}

export const LoadImage = styled.section<LoadImageProps>`
  position: absolute;
  top: 10px;
  bottom: 0;
  right: 0;
  left: 10px;
  background: var(--primary);
  transition: 0.5s ease;
  opacity: ${props => (props.loaded ? 0 : 1)};
  border-radius: 32px;
  pointer-events: none;
  height: 48px;
  width: 48px;

  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`

interface ContentProps {
  percentage: number
  primary_color?: string
}

export const Content = styled.div<ContentProps>`
  display: flex;
  width: 100%;
  flex: 3;
  gap: 16px;
  align-items: center;

  > section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 2;

    > svg {
      position: relative;
      width: 68px;
      height: 68px;
      transform: rotate(-90deg);

      > circle {
        width: 68px;
        height: 68px;
        stroke: ${props =>
          props.primary_color ? props.primary_color : 'var(--primary)'};
        stroke-width: 3px;
        stroke-dasharray: 201;
        stroke-dashoffset: calc(
          201 - (201 * ${props => props.percentage}) / 100
        );
        stroke-linecap: round;
        fill: none;
        transform: translate(2px, 2px);
        transition: 1s ease;

        &:first-child {
          stroke-dasharray: 0;
          stroke-dashoffset: 0;
          stroke: ${props =>
            props.primary_color ? props.primary_color : 'var(--primary)'};
          opacity: 0.15;
        }
      }
    }

    > img {
      position: absolute;
      height: 48px;
      width: 48px;
      object-fit: cover;
      margin: 10px 0 0px 10px;
      border-radius: 100px;
      inset: 0;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 2;
    justify-content: flex-end;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    width: 100%;
    color: var(--white);

    > span {
      display: flex;
      flex-direction: column;
      height: 100%;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;

      > h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        @media (max-width: 600px) {
          font-size: 16px;
        }
      }
    }

    > p > b {
      font-size: 14px;
      font-weight: 600;
      color: ${props =>
        props.primary_color ? props.primary_color : 'var(--primary)'};
    }

    > div {
      display: flex;
      width: 100%;
      align-items: center;
      > p {
        font-weight: 400;
        font-size: 14px;
        width: 86px;

        @media (max-width: 1059px) {
          white-space: nowrap;
          padding-right: 4px;
        }
        @media (max-width: 1023px) {
          white-space: normal;
          padding-right: 0;
        }
      }
      > section {
        width: 100%;
        max-width: 300px;
        > section {
          width: 100%;
          > section {
            width: 100%;

            > div {
              display: flex;
              justify-content: flex-start;
              @media (max-width: 600px) {
                display: flex;
                justify-content: center;
              }
            }
            > div > h3 {
              font-weight: 600;
              font-size: 14px;
              line-height: 17.07px;
              color: var(--primary);
              padding: 0 !important;

              @media (max-width: 400px) {
                font-size: 12px;
              }
            }
          }
        }
      }
      @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
      }

      @media (max-width: 400px) {
        font-size: 10px;
        align-items: center;
      }
    }

    @media (max-width: 697px) {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
  }
`

interface BannerProps {
  bg?: string
}

export const Banner = styled.div<BannerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-repeat: no-repeat !important;
  background-position: center;
  align-items: center;
  justify-content: flex-end;
  padding: 32px 32px 0 32px;
  height: 278px;
  position: relative;

  @media (max-width: 1325px) {
    padding: 0 24px;
  }

  @media (max-width: 600px) {
    padding: 0px 16px;
  }
`

interface VideoContainerProps {
  isPlayer?: boolean
  primary_color?: string
  visible?: boolean
  hex?: string
}

export const VideoContainer = styled.div<VideoContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  aspect-ratio: ${props => (props.isPlayer ? '' : '16/9')};
  box-shadow: var(--shadow);
  max-width: 980px;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--naval);
    opacity: 0.75;
    border-radius: 10px;
    display: ${props => (!props.visible ? 'none' : '')};
  }

  > button {
    opacity: 0;
    transform: translateY(32px);
    z-index: 1;
    position: absolute;
    right: 32px;
    bottom: 64px;
    display: flex;
    gap: 16px;
    padding: 16px 24px;
    width: fit-content;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--white);
    cursor: pointer;
    transition: 0.5s ease;
    background-color: var(--background);

    @media (max-width: 697px) {
      padding: 8px;
      gap: 16px;
      font-size: 12px;
      right: 16px;
      align-items: center;
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 18px;
      width: 18px;
      border-radius: 100px;
      padding: 2px;
      background-color: ${p =>
        p?.primary_color ? p.primary_color : 'var(--primary)'};

      > svg {
        > path {
          stroke: ${props => props.hex};
        }

        @media (max-width: 697px) {
          height: 12px;
        }
      }
    }

    &:hover {
      transition: 0.2s ease;
      background-color: var(--inputs);
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  &:hover {
    > button {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const PlayButton = styled.div<Props>`
  position: absolute;
  inset: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  > div {
    height: 81px;
    width: 81px;
    background-color: ${p =>
      p?.primary_color ? p.primary_color : 'var(--primary)'};
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    > svg {
      height: 32px;
      width: 27px;
      margin-left: 6px;

      * {
        fill: ${props => props.hex};
      }

      @media (max-width: 600px) {
        height: 27px;
        width: 22px;
      }
    }

    @media (max-width: 600px) {
      height: 60px;
      width: 60px;
    }
  }

  &:hover {
    > div {
      filter: brightness(80%);
      transition: 0.2s ease;
    }
  }
`

export const VideoPlayer = styled.video`
  width: 100%;
  border-radius: 10px;
  position: relative;

  max-height: 551.25px;

  @media (min-width: 601px) {
    height: 100%;
  }

  @media (max-width: 600px) {
    aspect-ratio: 9 / 16;
    max-height: 340px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media (max-width: 1325px) {
    padding: 0 24px;
  }

  @media (max-width: 600px) {
    padding: 0px 16px;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  padding: 24px 26px;
  border-radius: 10px;
  align-items: center;
  box-shadow: var(--shadow);
  background: var(--naval);

  max-width: 980px;

  @media (max-width: 600px) {
    padding: 24px 16px;
  }
`

export const CardHeader = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: calc((100% -32px) / 2);

  > div {
    display: flex;
    flex-direction: column;
    color: var(--white);

    > p {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
  }

  > button {
    display: flex;
    gap: 32px;
    padding: 20px 16px;
    background: var(--background);
    width: fit-content;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--white);
    cursor: pointer;
    transition: 0.5s ease;

    > svg * {
      stroke: ${p => (p?.primary_color ? p.primary_color : 'var(--primary)')};
    }

    &:hover {
      transition: 0.3s ease;
      background: var(--inputs);
    }

    @media (max-width: 697px) {
      width: 100%;
      gap: 16px;
      justify-content: space-between;
      font-size: 14px;
    }

    @media (max-width: 397px) {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 697px) {
    align-items: center;
  }
`

export const ButtonsContainer = styled.div<Props>`
  display: flex;
  width: 100%;
  max-width: 980px;
  padding: 0 16px;

  > button {
    display: flex;
    gap: 32px;
    padding: 20px 16px;
    background: var(--naval);
    width: fit-content;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--white);
    cursor: pointer;
    align-items: center;
    transition: 0.5s ease;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 18px;
      width: 18px;
      border-radius: 100px;
      padding: 2px;
      background-color: ${p =>
        p?.primary_color ? p.primary_color : 'var(--primary)'};

      > svg {
        > path {
          stroke: ${props => props.hex};
        }

        @media (max-width: 697px) {
          height: 12px;
        }
      }
    }

    &:hover {
      transition: 0.3s ease;
      background: var(--inputs);
    }

    @media (max-width: 697px) {
      width: 100%;
      gap: 16px;
      justify-content: space-between;
      font-size: 14px;
      padding: 16px;
    }
  }

  @media (min-width: 599px) {
    display: none;
  }
`

interface TitleProps {
  isCenter?: boolean
}

export const Title = styled.h3<TitleProps>`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--white);

  @media (max-width: 1024px) {
    text-align: ${props => (props.isCenter ? 'center' : 'start')};
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`

export const Divisor = styled.div`
  display: flex;
  max-width: 600px;
  width: 100%;

  > div {
    height: 2px;
    background: var(--inputs);
    border-radius: 2px;
    width: 100%;
  }
`

export const Attachments = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const AttachmentsContent = styled.div<Props>`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;

  > div {
    padding: 14px 16px;
    background: var(--background);
    border-radius: 10px;
    display: flex;
    gap: 16px;
    width: 100%;
    max-width: calc((100% - 32px) / 3);
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    > div {
      display: flex;
      justify-content: space-between;
      color: var(--white);
      align-items: center;
      width: 100%;

      > a {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-decoration: none;
        color: var(--white);
        display: flex;
        flex-direction: column;
        gap: 2px;

        @media (max-width: 397px) {
          font-size: 14px;
          max-width: 180px;
        }
        @media (max-width: 309px) {
          max-width: 120px;
        }

        > p {
          text-overflow: ellipsis;
          max-width: 90%;
          white-space: nowrap;
          overflow: hidden;
        }

        > span {
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${p =>
            p?.primary_color ? p.primary_color : 'var(--primary)'};
          padding: 2px 6px;
          border-radius: 2px;
          width: fit-content;
        }
      }
    }
    &:hover {
      transition: 0.3s ease;
      background: var(--inputs);
    }
    @media (max-width: 1237px) {
      max-width: calc((100% - 16px) / 2);
    }

    @media (max-width: 697px) {
      max-width: 100%;
    }

    @media (max-width: 397px) {
      flex-direction: column;
    }
  }

  > div > div > svg * {
    stroke: ${p => (p?.primary_color ? p.primary_color : 'var(--primary)')};
  }

  > div > div > p {
    color: ${p => (p?.primary_color ? p.primary_color : 'var(--primary)')};
  }
`

export const InputContainer = styled.div`
  display: flex;
  width: 100%;

  > div {
    > div {
      background: var(--background);

      > input {
        padding: 8px 12px;

        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          font-style: italic;
        }
      }

      > div {
        right: 8px;
        left: auto;
        cursor: pointer;
      }
    }
  }
`

export const ResponsiveCourseContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 16px;
  margin-top: 49px;
  margin-bottom: 16px;

  @media (min-width: 1023px) {
    display: none;
  }

  @media (max-width: 600px) {
    margin-top: 103px;
  }
`

export const ButtonContent = styled.div<BackButtonProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
  background-color: var(--naval);
  border-radius: 10px;
  padding: 8px 16px;
  box-shadow: var(--shadow);
  align-items: center;
  cursor: pointer;

  > div {
    display: flex;
    gap: 12px;
    width: 100%;
    align-items: center;

    > div {
      flex-direction: column;

      > span {
        font-weight: 500;
        font-size: 12px;
        color: var(--white);
      }

      > p {
        font-weight: 300;
        font-size: 10px;
        color: ${props => props.primary_color || 'var(--primary)'};
      }
    }

    > svg > path {
      stroke: ${props => props.primary_color || 'var(--primary)'};
    }
  }

  &:hover {
    background: var(--inputs);
    transition: 0.3s ease;
  }
`

interface ContentCoursesProps {
  opened?: boolean
}

export const ContentCourses = styled.div<ContentCoursesProps>`
  position: fixed;
  inset: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  opacity: ${props => (props.opened ? '1' : '0')};
  pointer-events: ${props => (props.opened ? 'initial' : 'none')};
  transition: 0.3s ease;

  > div {
    @media (max-width: 1023px) {
      width: 100% !important;
      top: 116px;
      transform: translateX(0);
    }

    > div > div > div > div > a {
      padding: 13px 16px 13px 32px;
    }

    > div > div > div {
      > div > a > div {
        > img {
          height: 100%;
          width: 84px;
        }

        > div {
          > span {
            font-weight: 400;
            font-size: 18px;
          }

          > p {
            font-weight: 300;
            font-size: 14px;
          }
        }
      }
      > a {
        > svg {
          height: 20px;
          width: 20px;
        }

        > section {
          > svg {
            height: 24px;
            width: 24px;
          }

          > div {
            > span {
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
            }

            > p {
              font-weight: 300;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1023px) {
    > div {
      margin-top: -2px;
    }
  }
`

interface BackButtonProps {
  primary_color?: string
}

export const BackButton = styled.section<BackButtonProps>`
  display: flex;
  gap: 8px;
  width: 100%;
  position: fixed;
  top: 70px;
  background-color: var(--background);
  align-items: center;
  padding: 14px 32px;
  cursor: pointer;

  > svg > path {
    stroke: ${props => props.primary_color || 'var(--primary)'};
  }

  > p {
    font-weight: 400;
    font-size: 14px;
    color: var(--white);
    display: flex;
    gap: 3px;

    > p {
      font-weight: 600;
      color: var(--white);
    }
  }
`
export const LoadWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  max-height: 50px;
  padding: 10px 0;
  > svg {
    border: white;
    stroke: white;
    height: 30px;
    width: 30px;
  }
`
export const TopCard = styled.div`
  display: flex;
  justify-content: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 32px;
  @media (max-width: 697px) {
    flex-direction: column;
  }
`
export const TimerContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: calc((100% -32px) / 2);
  max-width: fit-content;
  align-items: center;
  > p {
    font-weight: 400;
    font-size: 12px;
    color: var(--white);
  }
  > p:nth-child(2) {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
  }
`
export const CountDown = styled.div<Props>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 6px;

  > p {
    color: ${p => (p?.primary_color ? p.primary_color : 'var(--primary)')};
    font-size: 20px;
    font-weight: 600;
  }
`
export const certificateContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-shadow: var(--shadow);
  max-width: 980px;
  text-align: center;
  gap: 4px;
  padding: 24px 0;
  margin: 12px 0 36px 0;

  @media (max-width: 1080px) {
    max-width: 80%;
  }
  > a {
    text-decoration: none;
  }
`
export const Text = styled.p<Props>`
  color: var(--white);
  font-size: 20px;
  font-weight: 500;
  > span {
    color: var(--white);
    font-weight: 700;
  }
`

export const TitleCertificate = styled.p<Props>`
  color: var(--white);
  font-size: 24px;
  font-weight: 500;
  > span {
    color: var(--white);
    font-weight: 700;
  }
`

export const AnimationContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 80%;
  > div {
    height: 330px !important;
    > canvas {
      height: inherit !important;
      object-fit: cover;
      width: 100%;
      max-width: 80%;
    }
  }
  @media (max-width: 900px) {
    > div {
      max-height: 40vw;
      > canvas {
        height: 100%;
        aspect-ratio: 2/1;
        max-height: 40vw;
      }
    }
  }
`

export const BackButtonCertificate = styled.div<Props>`
  width: 100%;
  margin: 36px 0 0 24px;
  font-weight: 400;
  font-size: 14px;
  color: var(--white);
  display: flex;
  gap: 3px;
  max-width: 980px;
  cursor: pointer;
  > button {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    > p {
      font-weight: 400;
      font-size: 14px;
      color: var(--white);
      display: flex;
      gap: 3px;

      > span {
        font-weight: 600;
        color: var(--white);
      }
    }

    > svg > path {
      stroke: ${p => (p?.primary_color ? p.primary_color : 'var(--primary)')};
    }
  }
`

export const MarkWater = styled.div`
  position: absolute;
  opacity: 0.5;
  display: flex;
  top: 16px;
  left: 16px;

  > img {
    width: 120px;
    height: 60px;
    object-fit: cover;

    @media (max-width: 600px) {
      width: 80px;
      height: 40px;
    }
  }
`

export const Button = styled.button<Props>`
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  max-height: 34px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
  background-color: ${p =>
    p?.primary_color ? p.primary_color : 'var(--primary)'};
  gap: 8px;

  > svg > g > path {
    stroke: ${props => props.hex};
  }
`

export const DataContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 16px;
  max-width: 980px;
  margin-top: 49px;

  > div,
  section {
    display: flex;
    flex-direction: column;

    @media (max-width: 600px) {
      gap: 4px;
    }
  }

  > div {
    > p {
      font-size: 14px;
      color: var(--white);

      @media (max-width: 600px) {
        font-size: 13px;
      }
    }

    > span {
      font-size: 20px;
      font-weight: 600;
      color: var(--white);
      width: 100%;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }

  > section {
    > p {
      font-size: 14px;
      color: var(--white);

      > b {
        font-weight: 600;
      }

      @media (max-width: 600px) {
        font-size: 12px;
      }
    }

    > div > p {
      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 1023px) {
    margin: 0;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;

    > div,
    section > p,
    span,
    div {
      text-align: center;
      justify-content: center;
    }
  }
`
