import React, { useContext, useState, createContext, useEffect } from 'react'
import api, {
  apiInterceptor,
  changeRecoveryPassword,
  CreateUser,
  getProfile,
  LoginUser,
  logoutUser,
  newPasswordRecovery,
  ResetPasswordProps,
  signUpApi
  // verifyCodePassword
} from '../lib/api'
import { useNavigate } from 'react-router-dom'
import { useGeneral } from './GeneralContext'

interface ContextProps {
  user: any
  setUser: any
  login: (data: LoginUser, isRemember: boolean) => Promise<void>
  logout: () => void
  getMyUser: () => Promise<void>
  recoveryPassword: (email: string) => Promise<void>
  changePassword: (data: any) => Promise<void>
  isReady: boolean
}

export interface UserProps {
  id: string
  name: string
  email: string
  birthdate: Date | null
  phone: string | null
  gender: 'MALE' | 'FEMALE' | null
  document: string | null
  document_type: 'CPF' | 'CPNJ'
  profile_img: string | null
  created_at: Date
  updated_at: Date
}

export type UserErrorProps = Partial<CreateUser>

export const UserContext = createContext<ContextProps>({} as ContextProps)

export const UserProvider: React.FC = ({ children }: any) => {
  const [user, setUser] = useState<UserProps>()
  const { setIsLoading, alertError, stage, setStage, setEmail, showAlert } =
    useGeneral()
  const [isReady, setIsReady] = useState(false)

  const navigate = useNavigate()

  async function getMyUser() {
    setIsLoading(true)
    try {
      const response = await getProfile()
      setUser(response as unknown as UserProps)
    } catch (err) {
      const token = localStorage.getItem('refreshToken')
      if (!token) {
        alertError({ message: 'Sessão expirada' })
      } else {
        alertError(err)
      }
    }
    setIsLoading(false)
  }

  async function logout() {
    setIsLoading(true)
    try {
      const refreshToken = localStorage.getItem('refreshToken')
      if (refreshToken) {
        localStorage.removeItem('refreshToken')
        await logoutUser(refreshToken)
      }
      localStorage.removeItem('accessToken')
      navigate('/members-area/login')
      setUser(undefined)
    } catch (err: any) {
      alertError(err)
    }
    setIsLoading(false)
  }

  async function login(data: LoginUser, isRemember: boolean) {
    setIsLoading(true)
    try {
      const result = await signUpApi(data)
      const accessToken = await result?.data?.body?.accessToken
      const refreshToken = await result?.data?.body?.refreshToken
      if (isRemember) {
        localStorage.setItem('refreshToken', refreshToken)
      }
      localStorage.setItem('accessToken', accessToken)
      api.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      await getMyUser()
      navigate('/')
    } catch (err: any) {
      if (err.message === 'Could not found refreshToken') {
        showAlert('error', 'Falha no Login', 'Credenciais inválidas.')
      } else if (err.response.status === 401) {
        showAlert('error', 'Falha no Login', 'Credenciais inválidas.')
      } else {
        showAlert(
          'error',
          'Falha no Login',
          'Houve algum erro, contate o administrador'
        )
      }
    }
    setIsLoading(false)
  }

  async function recoveryPassword(email: string) {
    try {
      await newPasswordRecovery(email)
      setEmail(email)
      setStage(stage + 1)
    } catch (err: any) {
      alertError(err)
      return Promise.reject(err)
    }
  }

  // for fuck's sake
  // async function verifyCode(data: any) {
  //   try {
  //     const result = await verifyCodePassword(data)
  //     setStage(3)
  //     setCode(data.code)
  //     return result
  //   } catch (err: any) {
  //     alertError(err)
  //   }
  // }

  async function changePassword(data: ResetPasswordProps) {
    try {
      if (data.email) {
        await changeRecoveryPassword({
          email: data.email,
          code: data.code,
          password: data.password
        })
        setStage((prev: any) => prev + 1)
      }
    } catch (err: any) {
      alertError(err)
      return Promise.reject(err)
    }
  }
  useEffect(() => {
    async function loadUserFromCookies() {
      setIsLoading(true)
      const token = localStorage.getItem('accessToken')

      if (token) {
        api.defaults.headers.common.Authorization = `Bearer ${token}`
        await getMyUser()
      }
      setIsLoading(false)
      setIsReady(true)
    }
    apiInterceptor()
    loadUserFromCookies()
  }, [])

  return (
    <UserContext.Provider
      value={{
        logout,
        user,
        setUser,
        getMyUser,
        changePassword,
        recoveryPassword,
        login,
        isReady
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = (): ContextProps => useContext(UserContext)
