import React from 'react'
import { LoginUser, LoginUserErrors } from '../../../../lib/api'
import { emailRegEx } from '../../../../lib/regEx'

export const handleVerify = (
  data: LoginUser,
  error: LoginUserErrors,
  setError: React.Dispatch<React.SetStateAction<LoginUserErrors>>
): boolean => {
  const newErr = { ...error }

  if (!data.email) {
    setError({ ...newErr, email: 'E-mail obrigatório' })
    return false
  }
  newErr.email = undefined

  data.email = data.email?.trim()
  if (!emailRegEx.test(data.email)) {
    setError({ ...newErr, email: 'Insira um e-mail válido' })
    return false
  }
  newErr.email = undefined

  if (!data.password) {
    setError({
      ...newErr,
      password: 'Senha obrigatória'
    })
    return false
  }
  newErr.password = undefined

  // if (!passwordRegEx.test(data.password)) {
  //   setError({
  //     ...newErr,
  //     password: 'Insira uma senha válida'
  //   })
  //   return false
  // }
  // newErr.password = undefined

  setError(newErr)

  return true
}
