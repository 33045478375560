import styled from 'styled-components'

interface ContainerProps {
  background?: string
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  background: ${props =>
    props.background ? props.background : 'var(--background)'};
  border-radius: 8px;
  padding: 26px 24px;
  justify-content: center;
  align-items: center;
  box-shadow: var(--shadow);

  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    max-width: 980px;
    width: 100%;

    > button {
      padding: 8px 32px;
      background: var(--primary);
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      font-size: 14px;
      font-weight: 600;
      color: var(--background);
      cursor: pointer;

      &:hover {
        background: var(--primary-hover);
        transition: 0.3s ease;
      }
    }
  }
`

interface TitleProps {
  primary_color?: string
}

export const Title = styled.h4<TitleProps>`
  font-size: 20px;
  font-weight: 600;
  color: ${p => (p.primary_color ? p.primary_color : 'var(--primary)')};
  text-align: center;
`

export const Text = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
  text-align: center;
  width: 100%;
`
