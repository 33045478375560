import React, { useState } from 'react'
import { MdOutlineMailOutline } from 'react-icons/md'
import Input from '../../../../../components/Input'
import * as S from './styles'
import { useUser } from '../../../../../contexts/UserContext'
// import { UserProps, useUser } from '../../../../../contexts/UserContext'
// import { useGeneral } from '../../../../../contexts/GeneralContext'
import { UserErrorProps, handleVerify } from './utils'
import { MiddleButton } from '../../../../../components/Button'
import { useGeneral } from '../../../../../contexts/GeneralContext'
import { SubmitData } from '..'

interface StageProps {
  setStage: React.Dispatch<React.SetStateAction<number>>
  data: SubmitData
  setData: React.Dispatch<React.SetStateAction<SubmitData>>
  setSearchParams: React.Dispatch<React.SetStateAction<URLSearchParams>>
}

const Stage1: React.FC<StageProps> = ({ data, setData, setSearchParams }) => {
  const { recoveryPassword } = useUser()
  const { showAlert, alertError, setIsLoading } = useGeneral()
  const [error, setError] = useState<UserErrorProps>({} as UserErrorProps)

  const generateCode = async () => {
    setIsLoading(true)
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await recoveryPassword(data.email!)
      showAlert(
        '',
        'Email enviado com sucesso!',
        'Verifique sua caixa de entrada'
      )
      setSearchParams(prev => ({ ...prev, email: data.email }))
      // setStage(prev => prev + 1)
    } catch (err: any) {
      if (
        err?.response?.data?.body ===
        'Reset password e-mail sended a few minutes ago'
      ) {
        showAlert(
          'error',
          'Email de mudança de senha enviado há poucos minutos.',
          'Tente novamente mais tarde'
        )
      } else {
        alertError(err)
      }
    }
    setIsLoading(false)
  }

  const verify = () => {
    if (handleVerify({ email: data?.email }, error, setError)) {
      generateCode()
    }
  }

  return (
    <>
      <S.ColumnStage1>
        <h3>
          Esqueceu sua <b>senha</b>?
        </h3>
        <h4>
          Sem problemas, vamos <b>criar uma novinha</b>!
        </h4>
      </S.ColumnStage1>
      <S.ColumnStage3 style={{ gap: 24 }}>
        <p>Por favor, insira seu e-mail, enviaremos um código!</p>

        <Input
          placeholder="E-mail"
          icon={<MdOutlineMailOutline />}
          value={data?.email}
          error={error.email}
          onChange={(e: any) => {
            setData(prev => ({ ...prev, email: e.target.value }))
          }}
          onKeyDown={(e: any) => {
            if (e.key.includes('Enter')) {
              verify()
            }
          }}
        />
      </S.ColumnStage3>
      <MiddleButton
        onClick={() => {
          verify()
        }}
        text="Enviar código"
        style={{ maxWidth: 170, width: '100%' }}
      />
    </>
  )
}

export default Stage1
