import React, { useEffect, useRef, useState } from 'react'
import { HiMenuAlt1 } from 'react-icons/hi'
import { MdKeyboardArrowDown, MdSearch } from 'react-icons/md'
import { ReactComponent as Logo } from '../../../assets/logo/logo-blue.svg'
import Input from '../../../components/Input'
// import { normalizer } from '../../../lib/helpers'
import * as S from '../styles'
import ProfileMembersArea from './Profile'
import ResponsiveHeader from './responsive-header'
// import { courses } from './statics'
import FaqQuestions from './faqQuestions'
import { QuestionsSearch, SearchResult } from './styles'
import { useUser } from '../../../contexts/UserContext'
import { useGeneral } from '../../../contexts/GeneralContext'
import { getEnrollments, getEnrollmentsByClassName } from '../../../lib/api'
import { iEnrollment } from '../../../lib/models'
import { Link, useNavigate } from 'react-router-dom'

const HeaderMembersArea: React.FC<{
  profileImg?: string
  setPage: React.Dispatch<React.SetStateAction<number>>
}> = ({ profileImg, setPage }) => {
  const [openProfile, setOpenProfile] = useState(false)
  const [opened, setOpened] = useState(false)
  const [active, setActive] = useState(false)
  const [valueInput, setValueInput] = useState<string>('')
  const [enrollments, setEnrollments] = useState<iEnrollment[]>([])
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const { user } = useUser()
  const { alertError } = useGeneral()
  const navigate = useNavigate()

  const getUserEnrollments = async () => {
    try {
      const response = await getEnrollments()
      setEnrollments(response)
    } catch (err: any) {
      alertError(err?.response?.data || err?.data || err)
    }
  }

  const handleSearch = async (e: string) => {
    setValueInput(e)
    try {
      const newItems = await getEnrollmentsByClassName({
        course_name: e !== '' ? e : undefined
      })
      setEnrollments(newItems)
    } catch (err: any) {
      alertError(err?.response?.data || err?.data || err)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    setValueInput(inputValue)

    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    const newTimer = setTimeout(() => {
      handleSearch(inputValue)
    }, 300)
    timerRef.current = newTimer
  }

  const ref = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current)
        setValueInput('')
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    getUserEnrollments()
  }, [])

  const [width, setWidth] = useState(0)

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth

      setWidth(newWidth)
    }
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  return (
    <S.Container>
      <S.LeftContent>
        <S.LogoContainer>
          <Link to="/" onClick={() => navigate('/')} state={{ type: 0 }}>
            <Logo />
          </Link>
        </S.LogoContainer>
        <S.SearchContainer isSearching={active} ref={ref}>
          <Input
            isSearch={true}
            placeholder={width <= 600 ? '' : 'Pesquisar seus cursos... '}
            type="text"
            value={valueInput}
            onChange={handleChange}
          />

          <SearchResult active={valueInput} style={{ zIndex: 3 }}>
            {enrollments.map((item, i) => (
              <FaqQuestions
                label={item.course.name}
                key={i}
                valueInput={valueInput}
                onClick={() =>
                  navigate(`/members-area/clients/course/${item.class.id}`, {
                    replace: true
                  })
                }
              />
            ))}
            {enrollments.length === 0 && (
              <QuestionsSearch>Não encontramos seu curso</QuestionsSearch>
            )}
          </SearchResult>
        </S.SearchContainer>
      </S.LeftContent>
      {/* Profile */}
      <S.RightContent>
        <S.Content
          onClick={(e: any) => {
            e.stopPropagation()
            setOpenProfile(!openProfile)
          }}
        >
          <div>
            <img
              src={
                profileImg && profileImg !== ''
                  ? profileImg
                  : 'https://t4.ftcdn.net/jpg/02/15/84/43/360_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg'
              }
            />
            <span>
              Olá,{' '}
              <span>
                {' '}
                {user?.name !== undefined
                  ? user?.name.split(' ').length > 1
                    ? `${user?.name.split(' ')[0]} ${user?.name
                        .split(' ')
                        .slice(-1)}`
                    : ` ${user?.name}`
                  : ''}
              </span>
            </span>
            <MdKeyboardArrowDown size={24} color="white" />
          </div>
        </S.Content>
        <ProfileMembersArea
          isActive={openProfile}
          name={user.name}
          setPage={setPage}
          setIsActive={setOpenProfile}
        />
      </S.RightContent>
      <S.SearchIcon isSearching={active}>
        <MdSearch size={24} color="white" onClick={() => setActive(!active)} />
      </S.SearchIcon>
      <S.ToggleButton onClick={() => setOpened(!opened)}>
        <HiMenuAlt1 size={24} color="white" />
      </S.ToggleButton>
      <ResponsiveHeader
        opened={opened}
        name={user.name}
        setOpened={setOpened}
      />
    </S.Container>
  )
}

export default HeaderMembersArea
