import React, { useRef, useState } from 'react'

import * as S from './styles'
import { iEnrollment } from '../../../../lib/models'
import { useNavigate } from 'react-router-dom'
import TooltipEllipses from '../../../../components/TooltipEllipses'

interface ElementProps {
  data: iEnrollment
  withoutEllipses?: boolean
  isMinWidth?: boolean
  itemsCount?: number
}

const Element: React.FC<ElementProps> = ({
  data,
  withoutEllipses,
  isMinWidth,
  itemsCount
}) => {
  const navigate = useNavigate()
  const containerRef = useRef<HTMLDivElement>(null)
  const [loadedImage, setLoadedImage] = useState(false)

  return (
    <S.CoursesContent
      onClick={() => navigate(`/members-area/clients/course/${data.class.id}`)}
      isMinWidth={isMinWidth}
      itemsCount={itemsCount}
    >
      <S.CourseImage itemsCount={itemsCount}>
        <img
          src={data.course.cover}
          onLoad={() => {
            setLoadedImage(true)
          }}
        />
        <S.LoadImage loaded={loadedImage} />
      </S.CourseImage>
      <S.ProgressBarContent>
        <S.Progress percentage={data.progress} itemsCount={itemsCount} />
      </S.ProgressBarContent>
      {withoutEllipses ? (
        <h3>{data.course.name}</h3>
      ) : (
        <section>
          <section>
            <section ref={containerRef}>
              <TooltipEllipses
                containerRef={containerRef}
                label={data.course.name}
              />
            </section>
          </section>
        </section>
      )}
    </S.CoursesContent>
  )
}

export default Element
