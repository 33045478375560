import styled from 'styled-components'

export const Card = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
  flex-direction: column;
  padding: 24px 26px;
  background-color: var(--naval);
  border-radius: 10px;

  @media (max-width: 1024px) {
    max-width: 600px;
    align-items: center;
  }
`

export const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  > h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: var(--white);
  }

  @media (max-width: 400px) {
    flex-direction: column;
    gap: 8px;

    > h1 {
      text-align: center;
    }
  }
`

interface PaginationProps {
  itemsCount?: number
}

export const Pagination = styled.div<PaginationProps>`
  display: ${props =>
    props.itemsCount && props.itemsCount >= 6 ? 'flex' : 'none'};
  gap: 18px;

  > svg {
    color: var(--primary);
    font-size: 30px;
    cursor: pointer;
  }

  @media (max-width: 821px) {
    display: ${props =>
      props.itemsCount && props.itemsCount >= 6 ? 'flex' : 'none'};
  }
`

export const SliderContainer = styled.div<PaginationProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 954px;
  gap: 4px;

  > div {
    width: 100%;
    overflow: hidden;

    > div {
      > div {
        display: flex;
        justify-content: flex-start;

        @media (max-width: 1024px) {
          gap: 8px;
          margin-left: -24px;
        }

        @media (max-width: 666px) {
          margin-left: 0;
          gap: 8px;
        }

        @media (max-width: 430px) {
          margin-left: -8px;
        }

        @media (max-width: 480px) {
          gap: 0;
        }

        > div {
          @media (max-width: 480px) {
            display: flex;
          }
          @media (max-width: 430px) {
            justify-content: center;
          }
          @media (max-width: 300px) {
            width: 146px !important;
          }
        }
      }

      @media (max-width: 821px) {
        margin-right: 0;
      }

      @media (max-width: 1024px) {
        max-width: 100%;
      }
    }
  }

  /* .slick-slide.slick-active {
    @media (max-width: 66px) {
      width: fit-content !important;
    }
  }

  .slick-slide {
    @media (max-width: 66px) {
      width: fit-content !important;
    }
  } */

  @media (max-width: 1024px) {
    max-width: 100%;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    gap: ${props => (props.itemsCount && props.itemsCount >= 6 ? '' : '24px')};
  }
`

interface Props {
  isMinWidth?: boolean
  itemsCount?: number
}

export const CoursesContent = styled.div<Props>`
  display: flex !important;
  width: ${props =>
    props.isMinWidth ? 'fit-content !important' : 'fit-content'};
  gap: 8px;
  flex-direction: column;
  background-color: var(--naval);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  > section {
    max-width: 180px;
    width: 100%;
    > section {
      > section {
        width: 100%;

        > div {
          display: flex;
          justify-content: center;
        }

        > div > span {
          bottom: 24px;
        }

        > div > h3 {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: var(--white);
        }
      }
    }
    /* @media (max-width: 600px) {
      max-width: 100%;
    } */
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--white);
  }

  @media (max-width: 480px) {
    margin-left: 12px;
  }
  @media (max-width: 300px) {
    margin-left: 8px;
  }

  @media (max-width: 600px) {
    width: ${props =>
      props.itemsCount && props.itemsCount >= 6 ? '' : '100% !important'};
  }
`

export const CourseImage = styled.div<PaginationProps>`
  display: flex;
  position: relative;
  height: 321px;
  aspect-ratio: 9 / 16;

  > img {
    border-radius: 5px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  /* ::after {
    content: '';
    position: absolute;
    pointer-events: none;
    inset: 0;
    border-radius: 5px;
    background: linear-gradient(
      180deg,
      rgba(33, 37, 41, 0) 0%,
      #212529 calc(100% + 10px)
    );
  } */

  @media (max-width: 666px) {
    aspect-ratio: ${props =>
      props.itemsCount && props.itemsCount >= 6 ? 'inherit' : ''};
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const ProgressBarContent = styled.div`
  display: flex;
  position: relative;
  height: 5px;
  width: 100%;
  border-radius: 5px;
  background: var(--inputs);
`

interface ProgressProps {
  percentage?: string
  itemsCount?: number
}

export const Progress = styled.div<ProgressProps>`
  display: flex;
  position: absolute;
  inset: 0;
  height: 5px;
  width: ${props => props.percentage}%;
  border-radius: 5px;
  background: var(--primary);
  @media (max-width: 430px) {
    margin-left: ${props =>
      props.itemsCount && props.itemsCount >= 6 ? '' : '0 !important'};
  }
`

interface LoadingProps {
  height?: number
}

export const Loading = styled.section<LoadingProps>`
  display: flex;
  width: 100%;
  height: ${props => props.height}px;
`

interface LoadImageProps {
  loaded?: boolean
}

export const LoadImage = styled.section<LoadImageProps>`
  position: absolute;
  inset: 0;
  background: var(--primary);
  transition: 0.5s ease;
  opacity: ${props => (props.loaded ? 0 : 1)};
  border-radius: 8px;
  pointer-events: none;
`
