import styled from 'styled-components'

interface GridProps {
  hasMenu?: boolean
}

export const Grid = styled.div<GridProps>`
  display: ${props => (props.hasMenu ? 'grid' : 'flex')};
  flex-direction: ${props => (props.hasMenu ? '' : 'column')};
  height: 100vh;
  width: 100vw;
  background: var(--background);

  grid-template-columns: 275px auto;
  grid-template-rows: 70px auto;

  grid-template-areas:
    'HD HD'
    'LM CC';

  @media (max-width: 1023px) {
    grid-template-columns: 0px auto;
  }
`

interface Props {
  isCourse?: boolean
}

export const Content = styled.div<Props>`
  grid-area: CC;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: ${props => (props.isCourse ? '0' : '32px')};
  overflow-x: auto;

  @media (max-width: 480px) {
    width: ${props => (props.isCourse ? '100vw' : '100%')};
  }
`

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  max-width: ${props => (props.isCourse ? '100%' : '980px')};
`
interface ContainerProps {
  opened?: boolean
}

export const ContainerResponsive = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  background: var(--naval);
  gap: 16px;
  position: fixed;
  top: 70px;
  width: 275px;
  right: 0;
  bottom: 0;
  transform: translateX(${props => (props.opened ? 0 : 275)}px);
  transition: 0.3s ease;
  z-index: 3;
  padding: 8px 12px 24px 12px;
  height: 100%;

  @media (min-width: 1024px) {
    display: none;
  }
`

interface SearchProps {
  active?: any
}

export const SearchResult = styled.div<SearchProps>`
  display: flex;
  flex-direction: column;
  max-width: 822px;
  width: 100%;
  position: absolute;
  border-radius: 10px;
  background: white;
  padding: 12px;
  height: fit-content;
  top: 50px;
  bottom: 0;
  overflow-x: auto;
  max-height: 341px;
  opacity: ${props => (props.active !== '' ? '1' : '0')};
  pointer-events: ${props => (props.active !== '' ? 'visible' : 'none')};

  ::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
    border: 8px solid var(--white);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--primary-hover);
  }
`

interface QuestionsProps {
  isSearching?: string
}

export const QuestionsSearch = styled.a<QuestionsProps>`
  padding: 12px 12px;
  border-radius: 10px;
  cursor: pointer;
  color: var(--white);
  font-weight: 400;
  font-size: 18px;
  text-align: start;
  text-decoration: none;

  > p {
    color: var(--primary);
    font-weight: 700;
    display: inline;
  }

  &:hover {
    transition: 0.3s ease;
    background: #272b2f1a;
  }
`
