import React from 'react'
import { IProfile } from '../../../../lib/models'
import { checkEmail } from '../../../../lib/helpers'
import { iUpdatePassword } from '../../../../lib/api'

export type IUpdateProfile = Partial<
  Omit<IProfile, 'birthdate'> & {
    birthdate: string
  }
>
export type UpdateProfileErrors = {
  // eslint-disable-next-line no-unused-vars
  [P in keyof IProfile]?: string
}

export const formatDate = (date: string) => {
  const newDate = new Date(date)

  const day =
    `${newDate.getDate()}`.length === 1
      ? `0${newDate.getDate()}`
      : newDate.getDate()

  const month =
    `${newDate.getMonth() + 1}`.length === 1
      ? `0${newDate.getMonth() + 1}`
      : newDate.getMonth() + 1

  return `${day}/${month}/${newDate.getFullYear()}`
}

export const handleVerify = (
  data: IUpdateProfile,
  error: UpdateProfileErrors,
  setError: React.Dispatch<React.SetStateAction<UpdateProfileErrors>>
): boolean => {
  const newErr = { ...error }

  if (!data.name) {
    setError({ ...newErr, name: 'Nome obrigatório' })
    return false
  }
  newErr.name = undefined

  if (!data.email) {
    setError({ ...newErr, email: 'Email obrigatório' })
    return false
  }
  newErr.email = undefined

  if (data.email && !checkEmail(data.email!)) {
    setError({ ...newErr, email: 'Insira um e-mail válido' })
    return false
  }
  newErr.email = undefined

  if (!data.gender) {
    setError({ ...newErr, gender: 'Insira um gênero válido' })
    return false
  }
  newErr.gender = undefined

  if (!data.birthdate) {
    setError({ ...newErr, birthdate: 'Data de nascimento obrigatória' })
    return false
  }
  newErr.birthdate = undefined

  if (!data.profile_img) {
    setError({ ...newErr, profile_img: 'Insira uma imagem válida' })
    return false
  }
  newErr.profile_img = undefined

  if (!data.document) {
    setError({ ...newErr, document: 'CPF Obrigatório' })
    return false
  }
  newErr.document = undefined

  if (!data.phone) {
    setError({ ...newErr, phone: 'Telefone Obrigatório' })
    return false
  }
  newErr.phone = undefined

  setError(newErr)

  return true
}

export type ChangePassword = Partial<iUpdatePassword>
export type UpdatePasswordErrors = {
  // eslint-disable-next-line no-unused-vars
  [P in keyof iUpdatePassword]?: string
}

export const handleVerifyPassword = (
  setError: React.Dispatch<React.SetStateAction<UpdatePasswordErrors>>,
  data?: ChangePassword
): boolean => {
  if (!data) {
    setError(prev => ({
      ...prev,
      password: 'Campos obrigatórios',
      new_password: 'Campos obrigatórios',
      conf_new_password: 'Campos obrigatórios'
    }))
    return false
  }
  if (!data.password || data?.password === '') {
    setError(prev => ({ ...prev, password: 'Senha antiga obrigatória' }))
    return false
  }
  setError(prev => ({ ...prev, password: undefined }))

  if (!data.new_password || data?.new_password === '') {
    setError(prev => ({ ...prev, new_password: 'Senha antiga obrigatória' }))
    return false
  }
  setError(prev => ({ ...prev, new_password: undefined }))

  if (!data.conf_new_password || data?.conf_new_password === '') {
    setError(prev => ({
      ...prev,
      conf_new_password: 'Senha antiga obrigatória'
    }))
    return false
  }
  setError(prev => ({ ...prev, conf_new_password: undefined }))

  if (data.new_password !== data.conf_new_password) {
    setError(prev => ({
      ...prev,
      conf_new_password: 'As senhas não conferem'
    }))
    return false
  }
  setError(prev => ({ ...prev, conf_new_password: undefined }))

  return true
}
