import styled from 'styled-components'

interface Props {
  primary_color?: string
  colorSvg?: string
}

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  padding: 24px 26px 8px 26px;
  border-radius: 10px;
  align-items: center;
  box-shadow: var(--shadow);
  background: var(--naval);
  max-width: 980px;

  @media (max-width: 600px) {
    padding: 24px 16px;
  }

  @media (max-width: 340px) {
    padding: 12px;
  }
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    color: var(--white);

    > p {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;

      @media (max-width: 697px) {
        font-size: 11px;
      }
    }
  }
`

export const InputContainer = styled.div<Props>`
  display: flex;
  width: 100%;

  > div {
    > div {
      > input {
        padding: 8px 12px;

        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          font-style: italic;
        }
      }

      > div {
        right: 8px;
        left: auto;
        top: 7px;
        cursor: pointer;
        background-color: ${p =>
          p?.primary_color ? p.primary_color : 'var(--primary)'};
        border-radius: 100px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;

        > svg {
          margin-left: 2px;
          height: 11px;
          width: 13px;
          margin-bottom: 1px;

          > path {
            fill: ${props => props.colorSvg};
          }
        }
      }
    }
  }
`

export const CardComments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-top: -16px;
`

export const Content = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  outline: 1px solid var(--background);
  border-radius: 4px;
  width: 100%;
  padding: 24px 16px;

  > div:last-child {
    margin-top: 8px;

    > div {
      outline: 1px solid var(--background);
      border-radius: 2px;

      > input {
        padding: 8px 12px;

        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          font-style: italic;
        }
      }

      > div {
        right: 8px;
        left: auto;
        cursor: pointer;
        background-color: ${p =>
          p?.primary_color ? p.primary_color : 'var(--primary)'};
        border-radius: 100px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;

        > svg {
          margin-left: 2px;
          height: 11px;
          width: 13px;
          margin-bottom: 1px;

          > path {
            fill: ${props => props.colorSvg};
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    padding: 24px 8px;
  }
`

export const CommentContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  > img {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    object-fit: cover;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    color: var(--white);

    > span {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }

    > p {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;

      @media (max-width: 697px) {
        width: 100%;
        line-break: anywhere;
      }
    }
  }
`

export const AnswersContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 0 20px;

  > img {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    object-fit: cover;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    color: var(--white);

    > span {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
    }

    > p {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;

      @media (max-width: 697px) {
        width: 100%;
        line-break: anywhere;
      }
    }
  }
`
