import styled from 'styled-components'

interface ContainerProps {
  isActive?: boolean
  isResponsive?: boolean
}

export const Container = styled.section<ContainerProps>`
  display: flex;
  position: ${props => (props.isResponsive ? 'initial' : 'absolute')};
  top: calc(100% + 18px);
  background-color: var(--naval);
  border-radius: 8px;
  right: 0px;
  width: ${props => (props.isResponsive ? '100%' : '350px')};
  transform: ${props => (props.isActive ? 'inherit' : 'translateY(-5px)')};
  opacity: ${props => (props.isActive ? 1 : 0)};
  visibility: ${props => (props.isActive ? 'visible' : 'hidden')};
  transition: ${props => (props.isResponsive ? '0.25s ease-out' : '0.4s ease')};
  flex-direction: column;
  box-shadow: ${props => (props.isResponsive ? 'none' : 'var(--shadow)')};
  z-index: 99;
  cursor: auto;
  gap: ${props => (props.isResponsive ? '12px' : '24px')};

  @media (max-width: 710px) {
    right: 0;
  }
`

export const ChipRadius = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 8px solid var(--naval);
  position: absolute;
  top: -8px;
  right: 64px;
`

export const Header = styled.div<ContainerProps>`
  display: flex;
  gap: 16px;
  flex-direction: column;
  width: 100%;
  padding: ${props =>
    props.isResponsive ? '16px 12px 0 12px' : '32px 26px 0 26px'};
`

export const Title = styled.h3`
  color: var(--white);
  font-size: 20px;
  font-weight: 400;

  > span {
    font-weight: 600;
    color: var(--primary);
  }
`

export const Data = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  > div {
    color: var(--white);
    display: flex;
  }

  > div:first-child {
    font-size: 12px;
    font-weight: 400;
    gap: 3px;

    > p {
      color: var(--primary);
      font-weight: 600;
    }
  }

  > div:last-child {
    font-size: 12px;
    justify-content: flex-end;
  }
`

export const DivisorContainer = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  padding: ${props => (props.isResponsive ? '0' : '0 26px')};
  margin: ${props => (props.isResponsive ? '-2px 0' : '0')};
`

export const Divisor = styled.div`
  display: flex;
  width: 100%;
  border-radius: 1px;
  background: #d9d9d9;
  opacity: 0.25;
  height: 1px;
`

export const Buttons = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: ${props => (props.isResponsive ? '0' : '-12px 0')};

  > a {
    display: flex;
    gap: 12px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--white);
    cursor: pointer;
    padding: ${props => (props.isResponsive ? '12px' : '12px 24px')};
    text-decoration: none;
    align-items: center;
    text-decoration: none;

    > svg {
      height: 20px;
      width: 19.36px;

      > circle {
        stroke: var(--primary);
      }
      > path {
        stroke: var(--primary);
      }
    }

    &:hover {
      transition: 0.3s ease;
      background: var(--inputs);
    }
  }
`

export const ButtonContainer = styled.div<ContainerProps>`
  padding-bottom: 20px;

  > button {
    display: flex;
    width: 100%;
    cursor: pointer;
    gap: 12px;
    padding: ${props => (props.isResponsive ? '12px' : '12px 24px')};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--white);
    margin-top: ${props => (props.isResponsive ? '0' : '-12px')};

    &:hover {
      transition: 0.3s ease;
      background: var(--inputs);
    }
  }
`
