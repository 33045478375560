/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { AiFillDelete } from 'react-icons/ai'
import { MdOutlineCloudUpload } from 'react-icons/md'
import { MiddleButton } from '../../Button'
import * as S from './styles'
// import EmptyImagePlaceholder from '../../../assets/images/sem-imagem.jpg'

interface UploadProps {
  seccondary?: boolean
  secondaryDeletion?: boolean
  label?: string
  disabledSubTitle?: boolean
  subTitle?: string
  id?: string
  setFunction?: any
  value?: any
  preImage?: string
  error?: string
  isVideo?: boolean
  deleteFile?: () => void
  setFunctionOnDelete?: boolean
  acceptPdf?: boolean
  disable?: boolean
  seccondaryErrorPosition?: boolean
  blockDelete?: boolean
  maxSize?: number
}

const baseStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  padding: 10,
  borderRadius: 8,
  backgroundColor: '#F2F2F2',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '100%'
}

const focusedStyle = {
  borderColor: 'var(--primary-1)'
}

const acceptStyle = {
  borderColor: 'var(--primary-1)'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

const Upload: React.FC<UploadProps> = ({
  seccondary,
  secondaryDeletion,
  label,
  disabledSubTitle,
  subTitle,
  setFunction,
  preImage,
  value,
  error,
  isVideo,
  deleteFile,
  setFunctionOnDelete,
  acceptPdf,
  disable,
  seccondaryErrorPosition,
  blockDelete,
  maxSize
}) => {
  const [file, setFile] = useState<(File & { preview: string }) | undefined>(
    value
  )
  const initialImage = React.useRef<string | undefined>()
  const [preImages, setPreImages] = useState<string[]>([])

  const statesInitialImage = React.useRef(0)

  const [style, setStyle] = useState<any>()
  const [loadedImage, setLoadedImage] = useState(false)
  const [errorState, setError] = useState(error)
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    isVideo
      ? useDropzone({
          accept: {
            'video/*': ['.mp4', '.mkv', '.avi']
          },
          maxSize: maxSize !== undefined ? maxSize : 150000000,
          onDrop: (acceptedFiles, rejectedFiles) => {
            acceptedFiles.map((newFile: any) => {
              const obj = Object.assign(newFile, {
                preview: URL.createObjectURL(newFile)
              })
              setFile(obj)
              setFunction(obj)
              setError(undefined)
            })
            rejectedFiles?.map(() => {
              setError('O arquivo é inválido ou maior que 150MB')
            })
          }
        })
      : useDropzone({
          accept: acceptPdf
            ? {
                'application/pdf': ['.pdf'],
                'image/png': ['.png'],
                'image/jpg': ['.jpg'],
                'image/jpeg': ['.jpeg'],
                'image/gif': ['.gif']
              }
            : {
                'image/png': ['.png'],
                'image/jpg': ['.jpg'],
                'image/jpeg': ['.jpeg'],
                'image/gif': ['.gif']
              },
          maxSize: acceptPdf ? 5 * 1024 * 1024 : 4 * 1024 * 1024,
          onDrop: (acceptedFiles, rejectedFiles) => {
            acceptedFiles.map((newFile: any) => {
              const obj = Object.assign(newFile, {
                preview: URL.createObjectURL(newFile)
              })
              setFile(obj)
              setFunction(obj)
              setError(undefined)
            })
            rejectedFiles?.map(() => {
              acceptPdf
                ? setError('O arquivo é inválido ou maior que 5MB')
                : setError('O arquivo é inválido ou maior que 4MB')
            })
          }
        })

  const handlerResetFile = () => {
    setFile(undefined)

    if (preImages.length > 1) {
      setFunction(preImages[0])
      setPreImages([])
    } else {
      setFunction(initialImage.current)
    }

    setError(undefined)
  }

  useEffect(() => {
    setStyle({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    })
  }, [isFocused, isDragAccept, isDragReject])

  useEffect(
    // function para proibição de deleção da imagem e voltar a imagem inicial pelo user
    function verifyInitialImage() {
      // na inicialização do componente, guarda o valor inicial da imagem
      // caso deletar a imagem seja bloqueada. Assim habilitando o botão 'Cancelar'
      if (
        // verifica se dado foi passado
        preImage &&
        // bloqueia blob
        typeof preImage === 'string' &&
        // evita atualização do valor inicial (preImage) caso o DEV reutilize a mesma
        // variável para valor recebido da API e valor atualizado no blob
        statesInitialImage.current < 2 &&
        // chamado apenas se for necessário bloquear a delação da imagem
        blockDelete
      ) {
        initialImage.current = preImage
        statesInitialImage.current++

        if (!preImages.find(item => item === preImage)) {
          const newImages: string[] = []

          if (
            preImages.at(-1) !== undefined &&
            preImages.at(-1) !== preImage &&
            typeof preImages.at(-1) === 'string'
          ) {
            newImages.push(preImages.at(-1)!)
          }

          newImages.push(preImage)

          setPreImages(newImages)
        }
      }

      if (preImage === undefined) {
        statesInitialImage.current++
      }

      // caso tenha patch/post e o valor da imagem (preImage), sem mudança de página,
      // for alterado ele cai no de cima e automaticamente volta ao
      // estado original do processo (remove button cancelar)
      if (statesInitialImage.current > 1) {
        setFile(undefined)
        statesInitialImage.current = 0
      }
    },
    [preImage]
  )

  return (
    <S.Container disable={disable}>
      <span>{label || 'Imagem'}</span>
      {seccondary ? (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <S.Content>
            {file !== undefined ? (
              <>
                <FilePreview
                  file={file}
                  setFile={setFile}
                  deleteFile={deleteFile!}
                  isVideo={isVideo}
                  setFunction={setFunction}
                  setFunctionOnDelete={setFunctionOnDelete}
                  blockDelete={blockDelete}
                />
                <S.OverlayButton>
                  <MiddleButton text="Alterar" />
                  {blockDelete && file && (
                    <>
                      {initialImage.current !== undefined &&
                        statesInitialImage.current < 2 && (
                          <MiddleButton
                            text="Cancelar"
                            bgColorSeccondary
                            onClick={(
                              e: React.ChangeEvent<HTMLButtonElement>
                            ) => {
                              e.preventDefault()
                              e.stopPropagation()
                              handlerResetFile()
                            }}
                          />
                        )}
                    </>
                  )}
                </S.OverlayButton>
              </>
            ) : (
              <S.LastImage>
                {preImage && (
                  <>
                    {isVideo ? (
                      <video
                        controls
                        src={preImage}
                        onLoadStart={() => {
                          preImage !== undefined && setLoadedImage(true)
                        }}
                      />
                    ) : (
                      <img
                        src={preImage}
                        onLoad={() => {
                          setLoadedImage(true)
                        }}
                      />
                    )}
                  </>
                )}
                <S.LoadImage loaded={loadedImage} />
                {secondaryDeletion && preImage && (
                  <S.DeleteButton
                    onClick={(e: any) => {
                      e.stopPropagation()
                      setFile(undefined)
                      deleteFile?.()
                      setFunction(null)
                    }}
                  >
                    <AiFillDelete size={14} />
                  </S.DeleteButton>
                )}
                <MiddleButton text="Alterar" />
              </S.LastImage>
            )}
          </S.Content>
        </div>
      ) : (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />

          {file !== undefined ? (
            <FilePreview
              file={file}
              setFile={setFile}
              isVideo={isVideo}
              setFunction={setFunction}
              setFunctionOnDelete={setFunctionOnDelete}
              blockDelete={blockDelete}
            />
          ) : (
            <MdOutlineCloudUpload size={90} color="rgba(251, 255, 252, 0.1)" />
          )}
          {file !== undefined ? null : (
            <>
              <S.Subtitle>
                {acceptPdf
                  ? 'Tamanho máx: 5MB'
                  : disabledSubTitle
                  ? ''
                  : 'Resolução: 512x512 pixels. Tamanho máx: 4MB'}
                {subTitle || ''}
              </S.Subtitle>
            </>
          )}
        </div>
      )}
      {errorState && typeof errorState !== 'number' && (
        <S.Error seccondary={seccondaryErrorPosition}>{errorState}</S.Error>
      )}
    </S.Container>
  )
}

interface FilePreviewInterface {
  file: (File & { preview: string }) | undefined
  setFile: React.Dispatch<
    React.SetStateAction<(File & { preview: string }) | undefined>
  >
  deleteFile?: () => void
  isVideo?: boolean
  setFunction: any
  setFunctionOnDelete?: boolean
  blockDelete?: boolean
}

const FilePreview: React.FC<FilePreviewInterface> = ({
  file,
  setFile,
  deleteFile,
  isVideo,
  setFunction,
  setFunctionOnDelete,
  blockDelete
}) => {
  const [loadedImage, setLoadedImage] = useState(false)

  return (
    <S.FilePreviewContainer>
      <section>
        <section>
          <div>
            {isVideo ? (
              <video
                controls
                src={file?.preview}
                onLoadStart={() => {
                  file?.preview !== undefined && setLoadedImage(true)
                }}
              />
            ) : (
              <>
                <img
                  src={file?.preview}
                  onLoad={() => {
                    setLoadedImage(true)
                  }}
                />
              </>
            )}
            <S.LoadImage loaded={loadedImage} />
            {!blockDelete && (
              <S.DeleteButton
                onClick={(e: any) => {
                  e.stopPropagation()
                  setFile(undefined)
                  deleteFile?.()
                  setFunctionOnDelete && setFunction(undefined)
                }}
              >
                <AiFillDelete size={14} />
              </S.DeleteButton>
            )}
          </div>
        </section>
      </section>
    </S.FilePreviewContainer>
  )
}

export default Upload
