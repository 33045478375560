import styled from 'styled-components'

interface CheckBoxContainerProps {
  seccondary?: boolean
}

export const CheckBoxContainer = styled.div<CheckBoxContainerProps>`
  display: flex;
  gap: 8px;
  background-color: ${props =>
    props.seccondary ? 'var(--inputs)' : 'transparent'};
  border-radius: 4px;
  width: ${props => (props.seccondary ? '100%' : 'inherit')};
  height: ${props => (props.seccondary ? '34px' : '')};
  align-items: ${props => (props.seccondary ? 'center' : '')};
  padding: ${props => (props.seccondary ? '0 20px' : '')};
`

interface TextProps {
  seccondary?: boolean
  withOutLabel?: boolean
}

export const Text = styled.span<TextProps>`
  font-size: 14px;
  color: ${props => (props.seccondary ? 'var(--white)' : 'var(--text)')};
  display: ${props => (props.withOutLabel ? 'none' : 'flex')};
  gap: 3px;

  > a {
    text-decoration: none;
    color: var(--primary);
  }
`

interface CheckBoxProps {
  active?: boolean
  isCircle?: boolean
}

export const CheckBoxContent = styled.button<CheckBoxProps>`
  border-radius: ${props => (props.isCircle ? '100px' : '3px')};
  border: 1px solid var(--primary);
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: 0.2s ease;

  :after {
    content: '';
    transition: 0.2s ease;
    transform: scale(${props => (props.active ? 1 : 0)});
    width: 10px;
    height: 10px;
    border-radius: ${props => (props.isCircle ? '100px' : '2px')};
    background: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
      width: 8px;
      height: 8px;
    }
  }

  @media (max-width: 600px) {
    width: 14px;
    height: 14px;
  }
`
