import React from 'react'
import ProfileMembersArea from './Profile'

import * as S from './styles'
import { useGeneral } from '../../../contexts/GeneralContext'

interface ResponsiveHeaderProps {
  opened?: boolean
  name?: string
  setOpened?: React.Dispatch<React.SetStateAction<boolean>>
}

const ResponsiveHeader: React.FC<ResponsiveHeaderProps> = ({
  opened,
  name,
  setOpened
}) => {
  const { setActive } = useGeneral()

  return (
    <S.ContainerResponsive opened={opened}>
      <ProfileMembersArea
        setOpened={setOpened}
        isResponsive
        isActive
        setPage={setActive}
        name={name}
      />
    </S.ContainerResponsive>
  )
}

export default ResponsiveHeader
