import React from 'react'
import { Link } from 'react-router-dom'
import * as S from './styles'
import { ReactComponent as BarCode } from '../../assets/icons/ProductSettings/SalesStrategies/barCode.svg'
import { ReactComponent as CreditCard } from '../../assets/icons/ProductSettings/SalesStrategies/creditCard.svg'

interface CheckBoxProps {
  action?: any
  text?: string
  active: boolean
  isCircle?: boolean
  isTermsOfUse?: boolean
  textLink?: string
  seccondary?: boolean
  isCreateStrategie?: number
  withOutLabel?: boolean
  style?: any
}

const CheckBox: React.FC<CheckBoxProps> = props => {
  return (
    <S.CheckBoxContainer seccondary={props.seccondary} style={props.style}>
      <S.CheckBoxContent
        onClick={props.action}
        active={props.active}
        isCircle={props.isCircle}
      />
      <S.Text withOutLabel={props.withOutLabel} seccondary={props.seccondary}>
        {props.text}
        {props.isTermsOfUse && <Link to={'/'}>{props.textLink}</Link>}
      </S.Text>
      {props.isCreateStrategie === 1 && <CreditCard />}
      {props.isCreateStrategie === 2 && <BarCode />}
    </S.CheckBoxContainer>
  )
}

export default CheckBox
