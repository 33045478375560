import React, { useEffect, useRef, useState } from 'react'
import { ButtonsMap } from './statics'
import { ReactComponent as Logout } from '../../../../assets/icons/logout.svg'
import * as S from './styles'
import { Link } from 'react-router-dom'
import { useUser } from '../../../../contexts/UserContext'

interface ProfileProps {
  isActive?: boolean
  setIsActive?: React.Dispatch<React.SetStateAction<boolean>>
  isResponsive?: boolean
  name?: string
  setPage: React.Dispatch<React.SetStateAction<number>>
  setOpened?: React.Dispatch<React.SetStateAction<boolean>>
}

const ProfileMembersArea: React.FC<ProfileProps> = ({
  isActive,
  isResponsive,
  name,
  setPage,
  setIsActive,
  setOpened
}) => {
  const { logout } = useUser()

  const UserRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (e: any) => {
    if (UserRef.current && !UserRef.current.contains(e.target)) {
      setIsActive?.(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const [width, setWidth] = useState(0)

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth

      setWidth(newWidth)
    }
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  return (
    <S.Container isActive={isActive} isResponsive={isResponsive} ref={UserRef}>
      <S.ChipRadius />
      <S.Header isResponsive={isResponsive}>
        <S.Title>
          Olá, <span>{name}</span>!
        </S.Title>
      </S.Header>
      <S.Buttons isResponsive={isResponsive}>
        {ButtonsMap.map((button, i) => (
          <Link
            key={i}
            to="/"
            onClick={() => {
              setPage(button.id)
              if (width < 1024 && setOpened) {
                setOpened(false)
              }
            }}
            state={{ type: button.id }}
          >
            {button.icon}
            {button.label}
          </Link>
        ))}
      </S.Buttons>
      <S.DivisorContainer isResponsive={isResponsive}>
        <S.Divisor />
      </S.DivisorContainer>
      <S.ButtonContainer isResponsive={isResponsive}>
        <button onClick={() => logout()}>
          <Logout />
          Sair
        </button>
      </S.ButtonContainer>
    </S.Container>
  )
}

export default ProfileMembersArea
