import styled from 'styled-components'

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
  flex: 1;

  @media (max-width: 768px) {
    padding: 0 24px;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
`

export const ModalWrapper = styled.div`
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: var(--naval);
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 500px;
  position: relative;
  padding: 24px 26px;
  gap: 8px;

  -webkit-box-shadow: var(--shadow);
  box-shadow: var(--shadow);

  @media (max-width: 768px) {
    width: 472px;
    overflow: hidden;
  }

  @media (max-width: 480px) {
    width: 100% !important;
    overflow: hidden;
  }
`

export const CloseModal = styled.button`
  position: absolute;
  top: 24px;
  right: 26px;
  cursor: pointer;
  padding: 3px;
  border-radius: 100px;
  border: 1px solid var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled.h3`
  font-size: 24px;
  color: var(--white);
  font-weight: 600;
  display: flex;
  width: 100%;
  justify-content: center;
`

export const Row = styled.div`
  color: var(--white);
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;

  > p,
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    > strong {
      color: var(--primary);
      width: fit-content;
      font-weight: 600;
    }
    > span {
      margin-left: -2px;
    }
  }
`

export const AnimationContainer = styled.div`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    > canvas {
      object-fit: cover;
      height: inherit !important;
      max-height: 250px;
      width: 100%;
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 16px;
`
