import React, { useState } from 'react'
import { ReactComponent as CourseSVG } from '../../../assets/icons/ClientsMembersArea/course.svg'
import { MdOutlineChevronRight } from 'react-icons/md'
import * as S from './styles'
import LeftMenuMembersArea from '../Frame/LeftMenu'
import { ReactComponent as Back } from '../../../assets/icons/ProductSettings/back.svg'
import { useCourse } from '../../../contexts/CourseContext'

interface ResponsiveProps {
  setCertificatePage: React.Dispatch<React.SetStateAction<boolean>>
}

const ResponsiveCourseContent: React.FC<ResponsiveProps> = ({
  setCertificatePage
}) => {
  const [opened, setOpened] = useState(false)
  const { classData } = useCourse()

  return (
    <S.ResponsiveCourseContent>
      <S.ContentCourses opened={opened}>
        <S.BackButton
          onClick={() => setOpened(false)}
          primary_color={classData?.course.primary_color}
        >
          <Back />
          <p>
            Voltar ao <p>Conteúdo</p>
          </p>
        </S.BackButton>
        <LeftMenuMembersArea
          responsiveOpened={opened}
          isCourseResponsive
          setCertificatePage={setCertificatePage}
        />
      </S.ContentCourses>
      <S.ButtonContent
        onClick={() => setOpened(!opened)}
        primary_color={classData?.course.primary_color}
      >
        <div>
          <CourseSVG />
          <div>
            <span>Escolha o que assistir</span>
            <p>
              {classData?.sections && classData?.sections.length} seç
              {classData?.sections && classData?.sections?.length > 1
                ? 'ões'
                : 'ão'}{' '}
            </p>
          </div>
        </div>
        <MdOutlineChevronRight
          size={14}
          color={classData?.course.primary_color || 'var(--primary)'}
        />
      </S.ButtonContent>
    </S.ResponsiveCourseContent>
  )
}

export default ResponsiveCourseContent
