import React from 'react'
import { Link } from 'react-router-dom'

import { Container, ContainerLink } from './styles'

interface ButtonProps {
  ref?: any
  text: string
  router?: any
  onClick?: any
  icon?: any
  disabled?: boolean
  style?: any
  seccondary?: boolean
  bigButton?: boolean
  bgColorSeccondary?: boolean
}

export const MiddleButton: React.FC<ButtonProps> = props => {
  return (
    <Container
      ref={props.ref}
      style={props.style}
      disabled={props.disabled}
      onClick={!props.disabled ? props.onClick : undefined}
      seccondary={props.seccondary}
      bigButton={props.bigButton}
      bgColorSeccondary={props.bgColorSeccondary}
    >
      {props.text}
      {props.icon}
    </Container>
  )
}

export const BigButton: React.FC<ButtonProps> = props => {
  return (
    <Container
      ref={props.ref}
      style={props.style}
      disabled={props.disabled}
      seccondary={props.seccondary}
      onClick={!props.disabled ? props.onClick : undefined}
      bigButton={props.bigButton}
      bgColorSeccondary={props.bgColorSeccondary}
    >
      <Link to={!props.disabled ? props.router : undefined} style={props.style}>
        {props.text}
        {props.icon}
      </Link>
    </Container>
  )
}

export const ButtonLink: React.FC<ButtonProps> = props => {
  return (
    <ContainerLink
      ref={props.ref}
      style={props.style}
      disabled={props.disabled}
      seccondary={props.seccondary}
      onClick={!props.disabled ? props.onClick : undefined}
      bigButton={props.bigButton}
      bgColorSeccondary={props.bgColorSeccondary}
    >
      <Link to={!props.disabled ? props.router : undefined} style={props.style}>
        {props.text}
        {props.icon}
      </Link>
    </ContainerLink>
  )
}
