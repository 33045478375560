/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import { useGeneral } from '../../../../contexts/GeneralContext'
import * as S from './styles'
import {
  iUpdatePassword,
  updatePassword,
  updateProfile,
  uploadFiles
} from '../../../../lib/api'
import {
  ChangePassword,
  IUpdateProfile,
  UpdatePasswordErrors,
  handleVerifyPassword
} from './utils'
import Input from '../../../../components/Input'
import { MiddleButton } from '../../../../components/Button'
import { DropdownV2 } from '../../../../components/Dropdown'
import ModalPasswordSuccess from '../../../../components/ModalClientsMembersArea/ModalPasswordSucess'
import Upload from '../../../../components/Upload/SingleUpload'
import {
  convertToFormData,
  cpfFormatter,
  dateFormatter,
  phoneFormatter
} from '../../../../lib/helpers'
import { ReactComponent as Settings } from '../../../../assets/icons/ProductSettings/setting.svg'
import { ReactComponent as Password } from '../../../../assets/icons/ClientsMembersArea/password.svg'
import { ReactComponent as Trash } from '../../../../assets/icons/trash.svg'
import ModalDeleteAccount from '../../../../components/ModalClientsMembersArea/ModalDeleteAccount'
import { IProfile } from '../../../../lib/models'
import { useUser } from '../../../../contexts/UserContext'

interface Props {
  getProfile: () => Promise<void>
  setUserProfile: React.Dispatch<
    React.SetStateAction<
      | Partial<
          Omit<IProfile, 'birthdate'> & {
            birthdate: string
          }
        >
      | undefined
    >
  >
  userProfile:
    | Partial<
        Omit<IProfile, 'birthdate'> & {
          birthdate: string
        }
      >
    | undefined
  setPreImage: React.Dispatch<React.SetStateAction<string>>
  preImage: string
  initialUserProfile:
    | Partial<
        Omit<IProfile, 'birthdate'> & {
          birthdate: string
        }
      >
    | undefined
}

const ProfilePage: React.FC<Props> = ({
  getProfile,
  setUserProfile,
  userProfile,
  initialUserProfile,
  preImage,
  setPreImage
}) => {
  // general instances
  const { alertError, showAlert, setIsLoading } = useGeneral()
  const [openModal, setOpenModal] = useState(false)
  const [openModalDeleteAccount, setOpenModalDeleteAccount] = useState(false)
  const { getMyUser } = useUser()

  // profile instances

  // password variables
  const [passwordData, setPasswordData] = useState<iUpdatePassword>(
    {} as iUpdatePassword
  )

  const [passwordErrors, setPasswordErrors] = useState<UpdatePasswordErrors>({})

  // input functions
  const onChangeInputProfile = (prop: keyof IUpdateProfile, value: any) => {
    const obj = { ...userProfile } as IUpdateProfile
    obj[prop] = value
    setUserProfile(obj as IUpdateProfile)
  }

  const onChangeInputPassword = (prop: keyof ChangePassword, value: any) => {
    const obj = { ...passwordData }
    obj[prop] = value
    setPasswordData(obj as iUpdatePassword)
  }

  const handleCreateUserProfile = async () => {
    setIsLoading(true)
    try {
      const obj = { ...userProfile }
      const image = obj.profile_img
      const response = await uploadFiles(convertToFormData([image]))
      obj.profile_img = response.data.body[0].url
      return obj.profile_img as string
    } catch (err: any) {
      showAlert(
        'error',
        'Falha ao carregar dados',
        'Verifique com administrador do serviço'
      )
    }
    setIsLoading(false)
  }

  const handleUpdateProfile = async () => {
    setIsLoading(true)
    try {
      const obj = { ...userProfile }
      if (
        obj?.profile_img !== null &&
        obj?.profile_img !== undefined &&
        obj?.profile_img !== initialUserProfile?.profile_img
      ) {
        obj.profile_img = await handleCreateUserProfile()
      }
      for (const key in obj) {
        if (
          (obj[key] == null || obj[key] === undefined) &&
          key !== 'profile_img'
        ) {
          delete obj[key]
        }
      }

      await updateProfile(obj)
      showAlert(
        '',
        'Dados atualizados com sucesso',
        'Os dados foram atualizados'
      )
      getProfile()
      getMyUser()
    } catch (err: any) {
      showAlert(
        'error',
        'Falha ao carregar dados',
        'Verifique com administrador do serviço'
      )
    }
    setIsLoading(false)
  }

  const handleUpdatePassword = async () => {
    setIsLoading(true)
    try {
      const obj = { ...passwordData }

      if (
        obj.new_password &&
        obj.new_password !== undefined &&
        obj.new_password === obj.conf_new_password
      ) {
        await updatePassword({
          password: passwordData.password || '',
          new_password: passwordData.new_password || ''
        })
        setPasswordData({
          ...passwordData,
          new_password: '',
          conf_new_password: '',
          password: ''
        })
        showAlert(
          '',
          'Dados atualizados com sucesso',
          'Os dados foram atualizados'
        )
      }
      setOpenModal(true)
    } catch (err: any) {
      alertError(err?.response?.data || err?.data || err)
    }
    await getProfile()
    setIsLoading(false)
  }

  return (
    <S.Container>
      <S.Card>
        <S.CardHeader>
          <Settings />
          <div>
            <h1>Dados gerais</h1>
            <span>Nos conte mais sobre você!</span>
          </div>
        </S.CardHeader>
        <S.Content>
          <S.Content style={{ flex: 1 }}>
            <S.UploadContainer>
              <Upload
                label="Imagem de Perfil"
                preImage={preImage}
                setFunctionOnDelete
                secondaryDeletion
                seccondary={preImage !== null && preImage !== undefined}
                setFunction={(e: any) => {
                  onChangeInputProfile('profile_img', e)
                  if (e === null) {
                    setPreImage(
                      'https://t4.ftcdn.net/jpg/02/15/84/43/360_F_215844325_ttX9YiIIyeaR7Ne6EaLLjMAmy4GvPC69.jpg'
                    )
                  }
                }}
              />
            </S.UploadContainer>
          </S.Content>
          <S.Row style={{ flex: 4 }}>
            <Input
              placeholder="Nome do usuário"
              label="Nome"
              value={userProfile?.name}
              onChange={(e: any) =>
                onChangeInputProfile('name', e.target.value)
              }
            />
            <Input
              placeholder="email@testeemail.com"
              label="E-mail"
              disable={true}
              value={userProfile?.email}
              onChange={(e: any) =>
                onChangeInputProfile('email', e.target.value)
              }
            />
            <Input
              label={'Data de Nascimento'}
              placeholder="DD/MM/YYYY"
              onChange={(v: any) => {
                const value = v.target.value
                const dateFormat = dateFormatter(value)
                if (value === '') {
                  onChangeInputProfile('birthdate', null)
                } else {
                  onChangeInputProfile('birthdate', dateFormat)
                }
              }}
              maxLength={10}
              value={dateFormatter(userProfile?.birthdate || '')}
            />
            <DropdownV2
              label="Gênero"
              id={0}
              changeFunction={() => {}}
              changeFunctionV2={(e, value) =>
                onChangeInputProfile('gender', value)
              }
              changeKey="ada"
              items={[
                {
                  value: 'MALE',
                  label: 'Masculino',
                  selected: userProfile?.gender === 'MALE'
                },
                {
                  value: 'FEMALE',
                  label: 'Feminino',
                  selected: userProfile?.gender === 'FEMALE'
                },
                {
                  value: null,
                  label: 'Outro / Prefiro não informar',
                  selected:
                    userProfile?.gender === null ||
                    userProfile?.gender === undefined
                }
              ]}
            />
            <Input
              placeholder="XX X XXXX-XXXX"
              label="Telefone"
              value={userProfile?.phone || ''}
              onChange={(e: any) => {
                const value = e.target.value
                const newValue = phoneFormatter(value)
                if (value === '') {
                  onChangeInputProfile('phone', null)
                } else {
                  onChangeInputProfile('phone', newValue)
                }
              }}
              maxLength={16}
            />
            <Input
              placeholder="XXX.XXX.XXX-XX"
              label="CPF"
              autocomplete="off"
              value={cpfFormatter(userProfile?.document) || ''}
              onChange={(e: any) => {
                const value = e.target.value
                const newValue = cpfFormatter(value)
                if (value === '') {
                  onChangeInputProfile('document', null)
                } else {
                  onChangeInputProfile('document', newValue)
                }
              }}
              maxLength={17}
            />
          </S.Row>
        </S.Content>
        <MiddleButton
          text="Salvar"
          style={{ maxWidth: 150, width: '100%' }}
          onClick={() => {
            handleUpdateProfile()
          }}
        />
      </S.Card>
      <S.Card>
        <S.CardHeader>
          <Password />
          <div>
            <h1>Alterar senha</h1>
            <span>Mantenha-se seguro!</span>
          </div>
        </S.CardHeader>
        <S.Content>
          <S.Column autoComplete="off">
            <Input
              label="Sua senha atual"
              placeholder="Senha atual"
              type="password"
              value={passwordData?.password}
              onChange={(e: any) =>
                onChangeInputPassword('password', e.target.value)
              }
              error={passwordErrors.password}
            />
            <Input
              label="Sua nova senha"
              placeholder="Nova senha"
              type="password"
              value={passwordData?.new_password}
              onChange={(e: any) =>
                onChangeInputPassword('new_password', e.target.value)
              }
              error={passwordErrors.new_password}
            />
            <Input
              label="Confirme sua nova senha"
              placeholder="Confirme sua nova senha"
              type="password"
              value={passwordData?.conf_new_password}
              onChange={(e: any) =>
                onChangeInputPassword('conf_new_password', e.target.value)
              }
              error={passwordErrors.conf_new_password}
            />
          </S.Column>
        </S.Content>
        <MiddleButton
          text="Alterar"
          style={{ maxWidth: 150, width: '100%' }}
          onClick={() => {
            if (handleVerifyPassword(setPasswordErrors, passwordData)) {
              handleUpdatePassword()
            }
          }}
        />
      </S.Card>
      <S.Card>
        <S.CardHeaderDeleteAccount>
          <div>
            <Trash />
            <div>
              <h1>Encerrar conta no KeedClass</h1>
              <span>Exclua permanentemente a sua conta.</span>
            </div>
          </div>
          <S.ButtonContainer>
            <MiddleButton
              text="Encerrar conta"
              onClick={() => setOpenModalDeleteAccount(true)}
            />
          </S.ButtonContainer>
        </S.CardHeaderDeleteAccount>
      </S.Card>
      <ModalPasswordSuccess showModal={openModal} setShowModal={setOpenModal} />
      <ModalDeleteAccount
        showModal={openModalDeleteAccount}
        setShowModal={setOpenModalDeleteAccount}
      />
    </S.Container>
  )
}
export default ProfilePage
