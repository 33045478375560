/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react'
import HeaderMembersArea from './header'
import LeftMenuMembersArea from './LeftMenu'

import * as S from './styles'
import { useUser } from '../../../contexts/UserContext'
import { useGeneral } from '../../../contexts/GeneralContext'

interface FrameProps {
  children?: React.ReactNode
  isCourse?: boolean
  hasMenu?: boolean
  setPage: React.Dispatch<React.SetStateAction<number>>
  setCertificatePage?: React.Dispatch<React.SetStateAction<boolean>>
  courseProgress?: number
}

const FrameMembersArea: React.FC<FrameProps> = ({
  children,
  isCourse,
  hasMenu,
  setPage,
  setCertificatePage
}) => {
  // eslint-disable-next-line no-unused-vars
  const [opened] = useState(false)
  const { user } = useUser()
  const { courseRef } = useGeneral()

  return (
    <S.Grid hasMenu={hasMenu}>
      <HeaderMembersArea profileImg={user?.profile_img} setPage={setPage} />
      {hasMenu && (
        <LeftMenuMembersArea
          responsiveOpened={opened}
          setCertificatePage={setCertificatePage}
          contentRef={courseRef}
        />
      )}
      <S.Content
        isCourse={isCourse}
        ref={courseRef as unknown as React.RefObject<HTMLDivElement>}
      >
        <S.Wrapper isCourse={isCourse}>{children}</S.Wrapper>
      </S.Content>
    </S.Grid>
  )
}

export default FrameMembersArea
