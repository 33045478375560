/* eslint-disable @typescript-eslint/no-empty-function */
import { CgClose } from 'react-icons/cg'
import * as S from './styles'
import React from 'react'
import Lottie from 'react-lottie'
import { MiddleButton } from '../../Button'

interface ModalProps {
  showModal: boolean
  setShowModal: any
}

const ModalPasswordSuccess: React.FC<ModalProps> = props => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('../../../assets/animations/robo2.json'),
    renderer: 'canvas',
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
    resizeMode: 'cover'
  }

  return (
    <>
      {props.showModal ? (
        <S.ModalContainer>
          <S.ModalWrapper>
            <S.CloseModal onClick={() => props.setShowModal(false)}>
              <CgClose size={10} color="var(--primary)" />
            </S.CloseModal>
            <S.Title>Sucesso!</S.Title>
            <S.AnimationContainer>
              <Lottie options={defaultOptions} height={200} width={200} />
            </S.AnimationContainer>
            <S.Row>
              <p>Sua senha foi alterada! </p>
              <p>
                Você já pode realizar login com suas novas{' '}
                <strong>credenciais</strong>.
              </p>
            </S.Row>
            <S.ButtonContainer style={{ paddingTop: 24 }}>
              <MiddleButton
                text="Voltar"
                style={{ maxWidth: 125, width: '100%' }}
                onClick={() => props.setShowModal(false)}
              />
            </S.ButtonContainer>
          </S.ModalWrapper>
        </S.ModalContainer>
      ) : null}
    </>
  )
}

export default ModalPasswordSuccess
